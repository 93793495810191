@import url(//fonts.googleapis.com/earlyaccess/notosanskr.css);
.notosanskr * {
  font-family: "Noto Sans KR", sans-serif;
}

@font-face {
  font-family: "SCDream-Bold";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_six@1.2/S-CoreDream-6Bold.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "SCDream-Medium";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_six@1.2/S-CoreDream-5Medium.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "SCDream-Regular";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_six@1.2/S-CoreDream-4Regular.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
}

/* common */
* {
  box-sizing: border-box;
}

body,
th,
td,
input,
select,
textarea,
button {
  font-size: 20px;
  line-height: 1.5;
  letter-spacing: -0.015em;
  font-family: "Noto Sans KR", "Malgun Gothic", "맑은 고딕", sans-serif;
  color: #323232;
}

div,
header,
section,
nav,
input,
ul,
li {
  box-sizing: border-box;
}

body,
th,
td,
p,
li,
span,
h1,
h2,
h3,
h4,
h5 {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}

body,
a,
button,
input,
label,
select,
div,
li {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a,
p,
li,
span,
div,
h1,
h2,
h3,
h4,
h5,
input,
select,
textarea,
button,
dl,
dd,
dt {
  word-break: keep-all;
}

a {
  color: #0b0405;
}

dl {
  color: inherit; /* Inherit text color of parent element. */
  text-decoration: none; /* Remove underline. */
  /* Additional css `propery: value;` pairs here */
}

img {
  width: auto;
  max-width: 100%;
}

body::-webkit-scrollbar {
  display: none;
}

body {
  -ms-overflow-style: none;
}
html,
body,
.wrap {
  position: relative;
}
html.open,
body.open,
.wrap.open {
  overflow: hidden;
}

.w100 {
  width: 100% !important;
}
.w95 {
  width: 95% !important;
}
.w90 {
  width: 90% !important;
}
.w85 {
  width: 85% !important;
}
.w80 {
  width: 80% !important;
}
.w75 {
  width: 75% !important;
}
.w70 {
  width: 70% !important;
}
.w65 {
  width: 65% !important;
}
.w60 {
  width: 60% !important;
}
.w55 {
  width: 55% !important;
}
.w50 {
  width: 50% !important;
}
.w45 {
  width: 45% !important;
}
.w40 {
  width: 40% !important;
}
.w35 {
  width: 35% !important;
}
.w30 {
  width: 30% !important;
}
.w25 {
  width: 25% !important;
}
.w20 {
  width: 20% !important;
}
.w15 {
  width: 15% !important;
}
.w10 {
  width: 10% !important;
}
.w5 {
  width: 5% !important;
}

.jalnan {
  font-family: "yg-jalnan", sans-serif;
}
.hgnr {
  font-family: "HangeulNuri-Light", sans-serif;
}
.hgnrB {
  font-family: "HangeulNuri-Bold", sans-serif;
}

.wrap {
  position: relative;
  display: block;
  overflow-x: hidden;
  overflow-y: auto;
  min-height: 100vh;
}

.header {
  position: absolute;
  width: 100%;
  z-index: 10;
  background: rgb(228, 130, 155);
  background: -moz-linear-gradient(
    111deg,
    rgba(228, 130, 155, 1) 0%,
    rgba(130, 126, 187, 1) 100%
  );
  background: -webkit-linear-gradient(
    111deg,
    rgba(228, 130, 155, 1) 0%,
    rgba(130, 126, 187, 1) 100%
  );
  background: linear-gradient(
    111deg,
    rgba(228, 130, 155, 1) 0%,
    rgba(130, 126, 187, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#e4829b",endColorstr="#827ebb",GradientType=1);
}
.header.scr {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}
.header.show {
  background-color: #02a5bb;
}

.header .header_wrap {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 0 2em;
  width: 100%;
  max-width: 64em;
  height: 5em;
}
.header.show .header_wrap {
  height: 6em;
}

.header .header_wrap .logo {
  position: relative;
  display: table;
  width: 10.4em;
  font-size: 1em;
  z-index: 30;
}
.header .header_wrap .logo img {
  width: auto;
  max-width: 10.4em;
}

.header .ab_right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: auto;
}
.header .ab_right > .gnb {
  width: 100%;
}
.header .ab_right .snb {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: rgb(28, 146, 235);
  background: -moz-linear-gradient(
    90deg,
    rgba(28, 146, 235, 1) 0%,
    rgba(28, 185, 188, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(28, 146, 235, 1) 0%,
    rgba(28, 185, 188, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(28, 146, 235, 1) 0%,
    rgba(28, 185, 188, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1c92eb",endColorstr="#1cb9bc",GradientType=1);
}
.header .ab_right .snb ul {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0 auto;
  padding: 0 0.5em;
  width: 100%;
  height: 2em;
}
.header .ab_right .snb ul li a {
  display: block;
  padding: 0.5em 1em;
  color: #fff;
  font-size: 0.8em;
}

/* navigation */

nav.menu .nav-category > ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
nav.menu .nav-category > ul > li {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 1em;
  height: 6em;
}
nav.menu .nav-category > ul > li .wait {
  position: absolute;
  display: none;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: 0.8rem;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 50;
}
nav.menu .nav-category > ul > li:hover > .wait {
  display: flex;
}
nav.menu .nav-category > ul > li:first-child {
  margin-left: 0;
}
nav.menu .nav-category > ul > li a {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.25em 1em;
  text-align: center;
  font-size: 1.1rem;
}
nav.menu .nav-category > ul > li a img {
  margin-right: 0.5em;
}
nav.menu .nav-category > ul > li.evaluate a {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 5em;
}

nav.menu .nav-category > ul > li.regist a {
  padding: 0.25em 2em;
  border-radius: 5em;
  border: 1px solid rgba(255, 255, 255, 0.5);
}

nav.menu .nav-category > ul > li a span {
  color: #fff;
  word-break: keep-all;
}
nav.menu .nav-category > ul > li > ul {
  position: absolute;
  display: none;
  top: calc(100% - 1em);
  left: 50%;
  padding: 0;
  width: 8em;
  height: auto;
  background-color: #fff;
  transform: translateX(-50%);
  z-index: 15;
  /* height: 0; */

  transition: all 0.5s ease-in-out;
}
nav.menu .nav-category > ul > li > ul.active {
  padding: 1em 0;
  z-index: 15;
}
nav.menu .nav-category > ul > li > ul li {
  position: relative;
}

nav.menu .nav-category > ul > li > ul li > .wait {
  left: 50%;
  width: 90%;
  transform: translateX(-50%);
}
nav.menu .nav-category > ul > li > ul li:hover > .wait {
  display: flex;
}
nav.menu .nav-category > ul > li > ul li a {
  position: relative;
  font-size: 0.9rem;
  line-height: 2em;
}
nav.menu .nav-category > ul > li > ul li:hover a {
  color: #051e48;
}

/* hamberger menu icon */
.menuBtn {
  display: none;
  margin-left: 1em;
  width: 1em;
  height: 0.75em;
  z-index: 21;
  /*transition:0.5s;*/
}

/* board paging area */
.paging_area {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2em;
  padding: 0.5em 0;
}
.paging_area a {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0.25em;
  width: 2em;
  height: 2em;
  font-size: 0.8rem;
  transition: 0.3s;
}
.paging_area a:hover {
  font-weight: 600;
}
.paging_area a.active {
  color: #fff;
  background-color: #1c92eb;
}

/* follow link */
.followLink {
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 6em;
  z-index: 5;
}

.followLink.end {
  position: relative;
}

.followLink a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 100%;
  color: #fff;
  font-size: 1.35rem;
}

.followLink a:first-child {
  background-color: #29407c;
}

.followLink a:last-child {
  background-color: #0b0405;
}

.followLink a img {
  margin-right: 0.75em;
  width: 1.1484em;
}

/* footer */
.footer {
  position: relative;
  padding: 2em 0;
  width: 100%;
  background-color: #f0f0f0;
  z-index: 11;
}
.footer .footer_wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  margin: 0 auto;
  padding: 0 2em;
  width: 100%;
  max-width: 64em;
}
.footer .footer_wrap .link_area {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1em;
  width: 100%;
}
.footer .footer_wrap .link_area a {
  position: relative;
  display: block;
  margin-right: 1em;
  padding-right: 1.2em;
  font-size: 0.9rem;
}
.footer .footer_wrap .link_area a:after {
  position: absolute;
  right: 0;
  content: "I";
  font-weight: 300;
  color: rgba(0, 0, 0, 0.3);
}
.footer .footer_wrap .link_area a:last-child:after {
  display: none;
}
.footer .footer_wrap .copy_area * {
  display: block;
}
.footer .footer_wrap .copy_area span {
  margin-bottom: 0.5em;
  font-weight: 600;
}
.footer .footer_wrap .copy_area p {
  font-size: 0.9rem;
  color: #646464;
}
.footer .footer_wrap .copy_area p:last-child {
  margin-top: 0.5em;
}

.footer .footer_wrap .client_area {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
}
.footer .footer_wrap .client_area > div {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin-right: 1em;
}
.footer .footer_wrap .client_area > div > span {
  display: block;
  margin-bottom: 0.5em;
  width: 100%;
  font-weight: 600;
  color: #989898;
}
.footer .footer_wrap .client_area > .client_info {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}
.footer .footer_wrap .client_area > .client_info:last-child {
  justify-content: flex-end;
}
.footer .footer_wrap .client_area > .client_info > span {
  width: unset !important;
}
.footer .footer_wrap .client_area a {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0.5em;
}
.footer .footer_wrap .client_area a > span {
  margin-right: 0.5em;
  padding: 0.25em 0.5em;
  color: white;
  font-size: 0.8em;
  background-color: #707070;
  border-radius: 5em;
}
.footer .footer_wrap .client_area a > img {
  margin-left: 1em;
}
.footer .footer_wrap .client_area a:last-child {
  margin: 0;
}

/* popup */
.popup {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  z-index: 12;
}
.popup.meta {
  display: flex;
  z-index: 99;
}
.popup .modal-bg,
.popup .overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 3;
}
.popup .modal-cont {
  position: relative;
  margin: 0 auto;
  padding: 1em;
  width: calc(100% - 2em);
  max-width: 800px;
  height: 70vh;
  background-color: #fff;
  z-index: 4;
}
.popup.meta .modal-cont {
  padding: 0;
  width: 100%;
  max-width: 31.25em;
  height: auto;
}
.popup.videoPop .modal-cont {
  margin: 0 auto;
  padding: 40% 0 0;
  width: 100%;
  max-width: 80vw;
  height: 0;
  background-color: #323232;
}
.popup.videoPop .modal-cont .video_area {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.popup.videoPop .modal-cont .video_area iframe {
  width: 100%;
  height: 100%;
}
.popup .modal-cont h2 {
  display: block;
  font-size: 1.2rem;
  margin-bottom: 0.3em;
  color: #011627;
}
.popup .modal-cont .tableArea {
  display: none;
  margin: 0 auto 0.75em;
  width: 100%;
  border: 0.1em solid #dcdcdc;
  border-left: none;
  border-right: none;
}
.popup .modal-cont .tableArea.popView {
  display: block;
  height: calc(100% - 3em);
  font-size: 1rem;
  overflow-y: auto;
}
.popup.meta .modal-cont .tableArea.popView {
  margin: 0;
  border: none;
}
.popup.meta .modal-cont .tableArea.popView > img {
  height: auto;
}
.popup .modal-cont .tableArea.popView > p {
  margin-bottom: 0.5em;
}
.popup .modal-cont .tableArea.popView > ul > li {
  margin-bottom: 0.5em;
}
.popup .modal-cont .tableArea.popView > ul > li > ul {
  font-size: 0.8rem;
}
.popup .modal-cont .tableArea ul li > span {
  display: block;
  padding: 0.375em;
  width: 7.5em;
  color: #24205c;
  text-align: center;
  background-color: #e5e6eb;
  border-bottom: 0.1em solid #fff;
  box-sizing: border-box;
}
.popup .modal-cont .tableArea ul li > span b {
  color: #fd0200;
}
.popup .modal-cont .tableArea ul li .input_area {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.375em;
  width: 19.5em;
  border-bottom: 0.1em solid #e5e6eb;
}
.popup .modal-cont .tableArea ul li:last-child > span,
.popup .modal-cont .tableArea ul li:last-child > .input_area {
  border-bottom: none;
}
.popup .modal-cont .tableArea ul li .input_area input[type="text"],
.popup .modal-cont .tableArea ul li .input_area input[type="password"] {
  padding: 0.025em 0.25em;
  width: 100%;
  border: 0.1em solid #e5e6eb;
}

.popup .modal-cont .tableArea dl {
  margin: 1em 0;
}
.popup .modal-cont .tableArea dl dd {
  margin: 0.25em 0;
  font-size: 0.8rem;
}

.popup .modal-cont .btn_area {
  margin: 0 auto;
  width: 100%;
}
.popup .modal-cont .btn_area button {
  display: block;
  width: 100%;
  line-height: 3em;
  color: #fff;
  background-color: #24205c;
  border: 0.1em solid #24205c;
}
.popup .modal-cont .close {
  position: absolute;
  top: 1.4em;
  right: 1em;
  z-index: 10;
}
.popup.videoPop .modal-cont .close {
  top: 1em;
}
.popup.videoPop .modal-cont .close img {
  filter: brightness(0) invert(1);
}
.popup .modal-cont .close img {
  width: 1em;
}
.popup .modal-cont .footer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5em;
}
.popup .modal-cont .footer * {
  display: block;
}
.popup .modal-cont .footer label,
.popup .modal-cont .footer a {
  font-size: 0.9em;
}
.popup .modal-cont .footer input {
  margin: 0 1em 0 0.25em;
}
.popup .modal-cont .footer a {
  padding: 0.25em 0.75em;
  color: #fff;
  background-color: #646464;
}

.popup .img-show {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 100vh;
  z-index: 5;
}
.popup .img-show span.popup_close {
  position: absolute;
  top: 1em;
  right: 1em;
  color: #fff;
}

/* custom radio, checkbox */
.chk_list {
  font-size: 0.85rem;
}
.customChk {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.customChk label {
  position: relative;
  display: block;
  padding-left: 1.5em;
  width: calc(100% - 8em);
  color: #323232;
  font-size: 0.9rem;
}
.customChk label:before {
  position: absolute;
  content: "";
  top: 50%;
  left: 0;
  width: 1em;
  height: 1em;
  border: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateY(-50%);
}
.customChk label:after {
  position: absolute;
  content: "";
  opacity: 0;
  top: 50%;
  left: calc(0.2em + 1px);
  width: 0.6em;
  height: 0.6em;
  background-color: #263050;
  transform: translateY(-50%);
  transition: 0.3s;
}
.customChk input[type="radio"],
.customChk input[type="checkbox"] {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 0;
  height: 0;
  opacity: 0;
}
.customChk input[type="radio"] + label:before,
.customChk input[type="radio"] + label:after {
  border-radius: 50%;
}
.customChk input[type="radio"]:checked + label:after,
.customChk input[type="checkbox"]:checked + label:after {
  opacity: 1;
  transition: 0.3s;
}

.customChk > a.btn_terms {
  display: block;
  width: 7em;
  font-size: 1rem;
  line-height: 1em;
}
.customChk > a.btn_terms span {
  display: block;
  padding: 0.2em 0.5em;
  font-size: 0.9rem;
  text-align: center;
}

.icon-search-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 1em;
  width: 1em;
  position: relative;
  /*transition: width 0.2s ease-out;*/
  backface-visibility: hidden;
}
.icon-search-container.active {
  width: 9.6em;
  /*transition: width 0.2s ease-out;*/
}
.icon-search-container.active .fa-times-circle {
  opacity: 1;
}
.icon-search-container .fa-search {
  color: #fff;
  cursor: pointer;
}
.icon-search-container .fa-times-circle {
  opacity: 0;
  color: #d9d9d9;
  transition: opacity 0.2s ease-out;
  cursor: pointer;
}
.icon-search-container .search-input {
  cursor: default;
  margin: 0;
  padding: 0;
  width: 0;
  border: none;
  outline: none;
  font-size: 0.75rem;
  background-color: rgba(255, 255, 255, 0.7);
  /*transition: width 0.2s ease-out;*/
  box-sizing: border-box;
  opacity: 0;
}
.icon-search-container.active .search-input {
  margin: 0 0.5em;
  padding: 5px;
  width: 200px;
  opacity: 1;
  /*transition: width 0.2s ease-out;*/
}

/* 200514 약관 팝업 추가 */
[data-ml-modal] {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  z-index: 999;
  width: 0;
  height: 0;
  opacity: 0;
}
.popWrap {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  z-index: 999;
  display: none;
}
[data-ml-modal]:target {
  width: 100%;
  height: 100vh;
  opacity: 1;
  -webkit-transition: opacity 1s ease;
  transition: opacity 1s ease;
}
[data-ml-modal]:target .modal-overlay,
.popWrap .modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  cursor: pointer;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1;
}
[data-ml-modal] .modal-dialog,
.popWrap .modal-dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
  width: 90vw;
  max-width: 660px;
  max-height: 70vh;
  overflow: hidden;
  z-index: 2;
  transform: translate(-50%, -50%);
}
.modal-dialog-lg {
  max-width: 820px !important;
}

[data-ml-modal] .modal-dialog > h3,
.popWrap .modal-dialog > h3 {
  background-color: #051e48;
  color: #fff;
  font-size: 1.2rem;
  font-weight: 400;
  margin: 0;
  padding: 0.8em 56px 0.8em 27px;
}
[data-ml-modal] .modal-dialog > h3.center,
.popWrap .modal-dialog > h3.cener {
  text-align: center;
}
[data-ml-modal] .modal-content,
.popWrap .modal-content {
  background: #fff;
  padding: 23px 27px;
  height: calc(70vh - 76px);
  font-size: 0.75em;
  overflow-y: auto;
}
.popWrap .modal-content {
  height: auto;
}
[data-ml-modal] .modal-content.apply .caution {
  display: block;
  margin: 1em auto;
  font-size: 1.75rem;
  text-align: center;
  font-weight: 600;
}
[data-ml-modal] .modal-content.apply .caution small {
  display: block;
}
[data-ml-modal] .modal-content.apply .caution small b {
  color: #051e48;
}
[data-ml-modal] .modal-content .section .box p {
  display: block;
  margin-bottom: 0.5em;
}
[data-ml-modal] .modal-content .section .input_area.terms {
  margin-top: 1em;
  padding: 1em;
  border-top: 1px solid #969696;
  border-bottom: 1px solid #969696;
}
[data-ml-modal] .modal-content .section .btn_area,
.popWrap .modal-content .section .btn_area {
  margin-top: 1em;
}
[data-ml-modal] .modal-content .section .btn_area a,
.popWrap .modal-content .section .btn_area a,
.popWrap .modal-content .section .btn_area button {
  display: table;
  margin: 0 0.5em;
  padding: 0.5em 1.5em;
  border: 1px solid #051e48;
  border-radius: 5em;
}
[data-ml-modal] .modal-content .section .btn_area a.apply,
.popWrap .modal-content .section .btn_area button.btn_submit {
  color: #fff;
  background-color: #051e48;
}
[data-ml-modal] .modal-close,
.popWrap .modal-close {
  position: absolute;
  top: 0.5em;
  right: 0.65em;
  color: #051e48;
  background-color: #fff;
  border-radius: 50%;
  height: 1.5em;
  width: 1.5em;
  font-size: 1.5rem;
  line-height: 1.35em;
  text-align: center;
}
[data-ml-modal] .modal-close:hover,
.popWrap .modal-close:hover {
  background-color: #051e48;
  color: #fff;
  border: 1px solid #fff;
  cursor: pointer;
}
[data-ml-modal] p:first-child,
[data-ml-modal] p:last-child {
  margin: 0;
}

@media (max-width: 768px) {
  [data-ml-modal] .modal-dialog {
  }
}
/* End Modals ------------------------------*/

.landing_area {
  position: relative;
  background-color: #080808;
}
.landing_area img {
  display: block;
  margin: 0 auto;
  width: 100%;
  max-width: 67.5em;
}

@media screen and (max-width: 1260px) {
  nav.menu .nav-category > ul > li {
    margin-left: 1.5em;
  }
  nav.menu .nav-category > ul > li a {
    font-size: 0.85rem;
  }
}

@media screen and (max-width: 1024px) {
  body {
    font-size: 14px;
  }
  .menuBtn {
    display: block;
  }

  .menuBtn .menu-btn {
    position: relative;
    width: 100%;
    height: 100%;
    float: right;
    cursor: pointer;
  }
  .menuBtn.open {
    position: fixed;
    top: 2em;
    right: 0.5em;
  }
  .menuBtn .menu-btn .line {
    background: #fff;
    display: block;
    width: 100%;
    height: 2px;
    float: left;
    clear: right;
    margin: 0.25em 0;
  }
  .menuBtn .menu-btn .hid {
    transform: rotateZ(0deg);
    /*transition:all .2s ease;*/
    position: absolute;
    background: rgba(255, 255, 255, 0);
    margin: 0.25em 0 0 0;
  }
  .menuBtn .menu-btn.gnbClose .hid {
    transform: rotateZ(135deg);
    background: #fff;
  }

  .menuBtn .menu-btn .second {
    transform: rotateZ(0deg);
    /*transition:all .5s ease;*/
    background: #fff;
  }
  .menuBtn .menu-btn .second.animate {
    transform: rotateZ(225deg);
    /*transition:all .5s ease;*/
  }
  .menuBtn .menu-btn .first {
    margin: 0;
  }
  .menuBtn .menu-btn .first,
  .menuBtn .menu-btn .third {
    /*transition:all .5s ease-out;*/
  }
  .menuBtn .menu-btn .third {
    left: 0;
    float: left;
    margin: 0;
  }
  .menuBtn .menu-btn .first.animate,
  .menuBtn .menu-btn .third.animate {
    width: 0; /*transition:all .3s ease-out;*/
  }

  /* hamberger menu icon */

  .gnb {
    position: fixed;
    display: flex;
    justify-content: flex-end;
    align-items: stretch;
    top: 0;
    left: 0;
    margin: 0;
    width: 100vw;
    height: 0;
    /* background-color: rgba(0, 0, 0, 0.75); */
    z-index: 20;
    overflow: hidden;
    transition: 0.3s;
  }

  .gnb.active {
    height: 100vh;
    transition: 0.3s;
  }

  .gnbOpen {
    display: block;
    line-height: 0;
  }

  .header .ab_right .snb {
    z-index: 2;
    top: 6em;
    background: #fff;
    box-shadow: 0 0.3em 0.5em rgba(0, 0, 0, 0.1);
  }
  .header .ab_right .snb ul {
    height: 6em;
    border-top: 1px solid #dcdcdc;
  }
  .header .ab_right .snb ul li {
    margin-right: 0.5em;
    width: calc((100% - 0.5em) / 2);
    border: 1px solid #969696;
  }
  .header .ab_right .snb ul li:last-child {
    margin: 0;
  }
  .header .ab_right .snb ul li a {
    font-size: 1.2em;
    color: #323232;
    text-align: center;
  }

  .gnb > nav {
    position: relative;
    display: block;
    width: 100%;
    min-width: 375px;
  }

  .gnb > nav .logoutArea {
    position: absolute;
    top: 0.9em;
    left: 50%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    max-width: 49.5em;
    transform: translateX(-50%);
  }
  .gnb > nav .btn_logout {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1.5em;
    padding: 0.25em 0.5em;
    border: 1px solid rgba(255, 255, 255, 0.3);
  }

  .gnb > nav .btn_logout span {
    font-size: 0.65em;
    color: #fff;
  }

  .gnb > nav .gnbTop {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    padding: 1.5em 1.5em 2em 2em;
    height: 10.5em;
    background: rgb(62, 54, 86); /* Old browsers */
    background: -moz-linear-gradient(
      45deg,
      rgba(62, 54, 86, 1) 0%,
      rgba(84, 76, 110, 1) 80%,
      rgba(84, 76, 110, 1) 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      45deg,
      rgba(62, 54, 86, 1) 0%,
      rgba(84, 76, 110, 1) 80%,
      rgba(84, 76, 110, 1) 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      45deg,
      rgba(62, 54, 86, 1) 0%,
      rgba(84, 76, 110, 1) 80%,
      rgba(84, 76, 110, 1) 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3e3656', endColorstr='#544c6e',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
  }

  .gnb > nav .gnbTop .loginInfo a {
    display: block;
    padding: 0.5em 0 0 3.25em;
    color: #fff;
    line-height: 1;
  }

  .gnb > nav .gnbTop .loginInfo a.userInfo {
    display: none;

    background-size: 2em;
  }

  .gnb > nav .gnbTop .loginInfo.login a.userInfo {
    display: block;
  }

  .gnb > nav .gnbTop .loginInfo a span.userName {
    font-size: 1.8em;
    font-weight: 500;
  }

  .gnb > nav .gnbTop .loginInfo a span.memberType {
    margin-top: 0.75em;
    font-size: 1.125em;
    font-weight: 500;
    color: #aeaeae;
  }

  .gnb > nav .gnbTop .loginInfo a.goLogin {
    display: block;

    background-size: 2em;
  }

  .gnb > nav .gnbTop .loginInfo.login a.goLogin {
    display: none;
  }

  .gnb > nav .gnbTop .loginInfo a span {
    display: block;
  }

  .gnb > nav .gnbTop span.person {
  }

  .gnb > nav > .nav-category {
    margin: 0 auto;
    padding-top: 5em;
    width: 100%;
    max-width: 64em;
    height: calc(100vh - 5em);
    background-color: rgba(0, 0, 0, 0.8);
    /* background-color:#fff; */
  }

  nav.menu .nav-category > ul {
    position: relative;
    display: block;
    /* border-top: 1px solid rgba(255, 255, 255, 0.25); */
  }
  .gnb ul.gnbBox {
    margin: 0 auto;
    padding: 0;
    width: 100%;
  }
  nav.menu .nav-category > ul > li {
    position: static;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    margin: 0;
    height: auto;
  }
  .gnbBox > li {
    position: relative;
  }
  nav.menu .nav-category > ul > li:hover:after {
    display: none;
  }
  .gnbBox > li:last-child {
    border: none;
  }
  .gnbBox > li > a {
    position: relative;
    display: block;
  }
  .gnbBox > li.subNav > a:before {
    position: absolute;
    content: "";
    top: 50%;
    right: calc(1em - 1px);
    width: 1px;
    height: 0.6em;
    transform: translate(-50%, -50%);
    transition: all 0.2s ease-out;
  }
  .gnbBox > li.subNav > a:after {
    position: absolute;
    content: "";
    top: calc(50% - 1.25em);
    left: 1em;
    width: calc(100% - 3em);
    height: 2.5em;

    background-size: contain;
    opacity: 0;
    transform: translateX(1em);
    transition: all ease-in-out 0.3s;
    z-index: 2;
  }
  .gnbBox > li.subNav.active > a:after {
    opacity: 1;
    transform: none;
  }
  nav.menu .nav-category > ul > li > a,
  .gnbBox > li > a {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1.75em;
    width: 40%;
    color: #323232;
    font-size: 1.1em;
    letter-spacing: -0.03em;
    background-color: #8c7eb8;
  }
  nav.menu .nav-category > ul > li.evaluate a {
    border: none;
    border-radius: 0;
  }
  .gnbBox > li > a {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }
  .gnbBox > li:last-child > a {
    border-bottom: none;
  }
  nav.menu .nav-category > ul > li > ul li a {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1.75em;
    color: #323232;
    font-size: 1.1em;
    letter-spacing: -0.03em;
    line-height: 1.5em;
  }
  nav.menu .nav-category > ul > li > ul li:hover a {
    color: #fff;
  }
  .gnbBox > li > a span {
    position: relative;
    z-index: 1;
  }
  .gnbBox > li:hover > a span:after {
    right: -12px;
    opacity: 1;
    transition: 0.3s;
  }

  .gnbBox > li > a > span {
    font-size: 1em;
    font-weight: 500;
    z-index: 3;
    transition: all ease-in-out 0.3s;
  }
  .gnbBox > li.active > a > span {
    color: #323232;
  }

  nav.menu .nav-category > ul > li > ul {
    position: absolute;
    top: 0;
    left: 40%;
    display: none;
    padding: 0;
    width: 60%;
    height: 100%;
    transform: translate(0) scaleY(1);
    background-color: #fff;
    z-index: 1;
  }
  nav.menu .nav-category > ul > li.active > ul {
    display: block;
    transform: translate(0) scaleY(1);
  }

  .gnbBox > li > ul > li {
    display: block;
    padding: 0;
    width: 100%;
    box-sizing: border-box;
    transition: 0.3s;
  }

  .gnbBox > li > ul > li > a {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0.25em 1em 0.25em;
    color: #323232;
    font-size: 1em;
    font-weight: 300;
    text-align: left;
    transition: 0.3s;
  }
  .gnbBox > li > ul > li > a:before {
    position: absolute;
    content: "";
    top: 50%;
    left: 0;
    width: 0.5em;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.5);
    transform: translateY(-50%);
  }
  .gnbBox > li > ul > li {
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  }
  .gnbBox > li > ul:hover > li {
    opacity: 0.4;
    transition: 0.3s;
  }

  .gnbBox > li > ul:hover > li:hover {
    opacity: 1;
    transition: 0.3s;
  }
  .gnbBox > li > ul:hover > li:hover > a:before {
    background-color: rgba(255, 255, 255, 0.7);
  }

  .gnbBox > li > ul > li > a span {
    display: block;
    font-size: 0.7em;
    font-weight: 300;
    z-index: 1;
  }

  .gnbBox > li > a img.navIco {
    margin-right: 1.5em;
    width: 2.2em;
  }

  .gnbBox > li:first-child > a img.navIco,
  .gnbBox > li:nth-child(2) > a img.navIco {
    margin-top: -0.2em;
  }

  .gnbBox > li > a img.navArrow {
    margin-left: auto;
    width: 0.675em;
    line-height: 0;
    opacity: 0.4;
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  .gnbBox > li.active > a img.navArrow {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    opacity: 1;
  }

  .gnbBox > li > ul > li > a img.navArrow {
    width: 0.675em;
    opacity: 0.15;
  }

  .gnb > nav .gnbBottom {
    position: absolute;
    left: 0;
    bottom: 2.25em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1.75em;
    width: 100vw;
  }

  .gnb > nav .gnbBottom span.asCenter {
    font-size: 1.2em;
    font-weight: 500;
  }

  .gnb > nav .gnbBottom span.asCenter span {
    font-weight: 600;
  }

  .gnb > nav .gnbBottom a.logOut {
    padding-right: 1.5em;
    font-size: 1.2em;
    font-weight: 500;

    background-size: 1em;
  }

  nav.menu .nav-category > ul > li > .wait {
    width: 40%;
    z-index: 99;
  }
  nav.menu .nav-category > ul > li > ul li > .wait {
    width: 100%;
  }

  .footer .footer_wrap .link_area {
    justify-content: center;
  }
  .footer .footer_wrap .link_area a:last-child {
    margin: 0;
    padding: 0;
  }

  .footer .footer_wrap .copy_area {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .footer .footer_wrap .copy_area p {
    text-align: center;
  }
  .footer .footer_wrap .client_area {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 1em;
    padding-top: 1em;
    width: 100%;
    border-top: 1px solid #969696;
  }
  .footer .footer_wrap .client_area a {
    margin: 0 1em 0 0;
  }
}

@media screen and (max-width: 575px) {
  .footer .footer_wrap .client_area {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 1em;
    padding-top: 1em;
    width: 100%;
    border-top: 1px solid #969696;
  }
  .footer .footer_wrap .client_area > .client_info > span {
    margin-bottom: 0;
    font-size: 1.1rem;
  }
  .footer .footer_wrap .client_area > .client_info:first-child {
    margin-bottom: 2em;
  }
}
