/* common */
@font-face {
  font-family: "yg-jalnan", sans-serif;
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_four@1.2/JalnanOTF00.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
}

body,
th,
td,
input,
select,
textarea,
button {
  font-size: 20px;
}

.container.main {
  padding: 0;
}
section {
  position: relative;
}

/* floating area */
.floating_area {
  position: fixed;
  right: 2em;
  bottom: 2em;
  z-index: 99;
}

.floating_area span,
.floating_area p {
  display: block;
  text-align: center;
  color: #fff;
}
.floating_area span {
  font-family: "SCDream-Bold";
  font-size: 1.25rem;
}

.floating_area .timer {
  display: block;
  padding: 0.5em 1.25em;
  font-family: "SCDream-Bold";
  font-size: 1.15rem;
  text-align: center;
  color: #fff;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 5em;
}
.floating_area .timer span {
  position: relative;
  margin-bottom: 0.4em;
  padding-bottom: 0.35em;
  font-family: "SCDream-Medium";
  font-size: 1.1rem;
}
.floating_area .timer span:after {
  position: absolute;
  content: "";
  bottom: 0;
  left: calc(50% - 0.5em);
  width: 1em;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.5);
}
.floating_area .timer small {
  font-family: "SCDream-Regular";
}

.floating_area .ico_area {
  display: table;
  margin: 2em auto 1.5em;
  width: auto;
}

@keyframes top-clip {
  0% {
  }
  50% {
    transform: translateY(21px);
  }
  100% {
    transform: translateY(21px);
  }
}

@keyframes bottom-sand-path {
  0% {
  }
  50% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes bottom-sand-g {
  0% {
  }
  85% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-9px);
  }
}

@keyframes hourglass-rotation {
  50% {
    transform: rotateZ(0);
  }
  100% {
    transform: rotateZ(180deg);
  }
}

.floating_area #top-sand #top-clip-path rect,
.floating_area #bottom-sand path,
.floating_area #bottom-sand g,
.floating_area svg {
  animation-duration: 5s;
  animation-delay: 1s;
  animation-iteration-count: infinite;
}

.floating_area #top-sand #top-clip-path rect {
  animation-name: top-clip;
}

.floating_area #bottom-sand path {
  transform: translateY(21px);
  animation-name: bottom-sand-path;
}

.floating_area #bottom-sand g {
  animation-name: bottom-sand-g;
}

.floating_area svg {
  animation-name: hourglass-rotation;
}

.section1 {
  position: relative;
}
.section1 .main_visual {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  overflow: hidden;
}
.section1 .main_visual .dDay {
  font-size: 0.75em;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 3em;
  right: 15.3em;
  z-index: 1;
  padding: 0.75em 1.5em;
  border-radius: 5em;
}
.section1 .main_visual .dDay * {
  display: table;
  color: #001650;
}
.section1 .main_visual .dDay span {
  font-family: "SCDream-Bold";
  font-size: 1.5em !important;
  color: white !important;
  text-shadow: 0 0 0.35em rgba(255, 255, 255, 0.5);
}
.section1 .main_visual .dDay p {
  font-family: "SCDream-Medium";
  font-size: 1.5em !important;
  text-shadow: 0 0 0.35em rgba(255, 255, 255, 0.5);
}

.section1 .main_visual .img_area img {
  display: block;
  width: 100%;
}
.section1 .main_visual img.visual_img {
  width: 100%;
  min-width: 100vw;
  height: 100vh;
  object-fit: cover;
}
.section1 .main_visual .slick-list {
  width: 100%;
}
.section1 .main_visual .slick-list .slick-track {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.section1 .main_visual .slick-list .slick-track .slick-slide {
  width: 100% !important;
  max-width: 100vw;
}
.section1 .main_visual .slideList {
  position: relative;
  display: block !important;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.section1 .main_visual .slick-dots {
  bottom: 2em;
  left: 50%;
  justify-content: center;
  padding: 0 0.5em;
  width: 100%;
  max-width: 60em;
  transform: translateX(-50%);
}

.section1 .main_visual .slick-dots li button:before,
.section1 .main_visual .slick-dots li.slick-active button:before {
  width: 0.5em;
  height: 0.5em;
  background-color: #fff;
  border-radius: 50%;
  opacity: 1;
}
.section1 .main_visual .slick-dots li.slick-active button:before {
  background-color: #3fe4fa;
}
.section1 .main_visual .slick-dots li button {
  font-size: 1em;
}
.section1 .main_visual .slick-dots li button,
.section1 .main_visual .slick-dots li {
  width: 0.5em;
  height: 0.5em;
}
.section1 .main_visual .slick-dots li {
  margin: 0 0.5em;
}

.section1 .main_visual .slideList {
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.section1 .main_visual .slideList {
  background: url(/src/asset/img/main/bg_mainB.png) no-repeat center;
  background-size: cover;
}
.section1 .main_visual .slideList.slide02 {
  /* background: url(/src/asset/img/main/bg_mb02.jpg) no-repeat center; */
  background-size: cover;
}
.section1 .main_visual .slideList .text_area {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding: 0 0.75em;
  width: 100%;
  max-width: 64em;
  height: 100vh;
  z-index: 5;
}
.section1 .main_visual .slideList .text_area img.slogan {
  width: 100%;
  max-width: 27.5em;
}
.section1 .main_visual .slideList .text_area img.slogan.pc {
  display: block;
}
.section1 .main_visual .slideList .text_area img.slogan.mobile {
  display: none;
}
.section1 .main_visual .slideList .text_area .text {
  margin: 2em 0 1.5em;
}
.section1 .main_visual .slideList .text_area .link_area {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 2em;
}
.section1 .main_visual .slideList .text_area .link_area a {
  display: block;
  margin-left: 1em;
}
.section1 .main_visual .slideList .text_area .link_area p {
  font-family: "SCDream-Medium";
  align-self: flex-end;
  font-size: 1.5em;
}
.section1 .main_visual .slideList .img_area {
  position: absolute;
  top: 50%;
  left: 50%;
  width: calc(100% - 4em);
  max-width: 64em;
  height: 100%;
  transform: translate(-50%, -50%);
}

.section1 .main_visual .slideList .img_area img {
  position: absolute;
}

.section1 .main_visual .slideList .img_area .mImg1 {
  width: 10.1em;
  top: 15%;
  left: 5%;
}
.section1 .main_visual .slideList .img_area .mImg2 {
  width: 15.75em;
  bottom: 20%;
  left: 13%;
}
.section1 .main_visual .slideList .img_area .mImg3 {
  width: 15.15em;
  bottom: 5%;
  left: 35%;
}
.section1 .main_visual .slideList .img_area .mImg4 {
  width: 6.65em;
  bottom: 1%;
  right: 5%;
}

.section1 .main_visual .text_area span,
.section1 .main_visual .text_area p {
  display: table;
  color: #fff;
  text-align: left;
  letter-spacing: -0.03em;
}
.section1 .main_visual .text_area span {
  font-size: 2.5em;
  font-weight: 600;
}
.section1 .main_visual .text_area p {
  display: block;
  width: 100%;
  font-size: 1.1em;
  font-weight: 400;
  text-align: right;
  color: #021750;
}

/* 행사소개영상 */
.section02 {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5em;
  width: 100%;
  background: #fff;
}
.univ_slider .swiper-wrapper .swiper-slide {
  margin-right: 2em;
  width: 4em;
}

/* 페스티벌 소개 */
.section03 {
  padding: 8em 0;
  background-color: #f5f5f5;
}
.section03 .intro_area {
  margin: 0 auto;
  padding: 0 0.75em;
  width: 100%;
  max-width: 64em;
}
.section03 .intro_area > span {
  font-family: "SCDream-Bold";
  font-size: 1.5em;
  color: #001650;
  letter-spacing: -0.03em;
}
.section03 .intro_area ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  margin-top: 2em;
}
.section03 .intro_area ul li {
  margin: 0 0.75em 0 0;
  padding: 0.5em 0.5em 1.5em;
  width: calc((100% - 2.25em) / 4);
  background-color: #fff;
  border-radius: 1em;
  box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.05);
}
.section03 .intro_area ul li:last-child {
  margin-right: 0;
}
.section03 .intro_area ul li .img_area {
  margin: 1em 0;
  width: 100%;
}
.section03 .intro_area ul li .img_area img {
  display: block;
  margin: 0 auto;
  width: 100%;
  max-width: 13.5em;
}
.section03 .intro_area ul li .text_area {
  padding: 0 1em;
}
.section03 .intro_area ul li .text_area * {
  display: block;
  text-align: left;
}
.section03 .intro_area ul li .text_area span {
  font-size: 1.25em;
  font-weight: 600;
}
.section03 .intro_area ul li .text_area p {
  margin-top: 0.75em;
  font-size: 0.8em;
  line-height: 1.5em;
  word-wrap: break-word;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 3em;
}
.section03 .intro_area ul li .text_area a {
  position: relative;
  display: table;
  margin: 2.5em 0 0;
  padding-right: 1.5em;
  width: auto;
  font-size: 0.75em;
  color: #707070;
}
.section03 .intro_area ul li .text_area a::after {
  content: "";
  position: absolute;
  top: calc(50% - 0.35em);
  right: 0;
  width: 0.8em;
  height: 0.8em;
  background: url(/src/asset/img/main/arrow_mlink.png) no-repeat center;
  background-size: contain;
}

/* video_area */
.section04 {
  padding: 5em 0;
  background: url(/src/asset/img/main/bg_mVideo.png) no-repeat center;
  background-size: cover;
}

.section04 .video_area {
  margin: 0 auto;
  padding: 0 0.75em;
  width: 100%;
  max-width: 64em;
  overflow: hidden;
}

/* board area */
.section05 {
  padding: 8em 0;
}
.section05 .board_area {
  position: relative;
  margin: 0 auto;
  padding: 0 0.75em;
  width: 100%;
  max-width: 64em;
}
.section05 .board_area > span {
  position: relative;
  display: block;
  font-family: "SCDream-Bold";
  font-size: 1.5em;
  color: #001650;
  letter-spacing: -0.03em;
  line-height: 1.2em;
}
.section05 .board_area > span small {
  font-family: "SCDream-Regular";
  font-size: 0.6em;
  color: #646464;
}
.section05 .board_area .more_btn {
  position: absolute;
  top: 0.75em;
  right: 0.75em;
}
.section05 .board_area > ul {
  margin-top: 2em;
}
.section05 .board_area > ul li a {
  padding: 0 1em;
}
.section05 .board_area > ul li a span {
  width: calc(100% - 6em);
}
.section05 .board_area > ul li a p {
  display: block;
  width: 6em;
  text-align: right;
  color: #969696;
}

.section06 {
  padding: 8em 0;
  background-color: #f5f5f5;
}
.section06 .client_area {
  position: relative;
  margin: 0 auto;
  padding: 0 0.75em;
  width: 100%;
  max-width: 64em;
}
.section06 .client_area > span {
  position: relative;
  display: block;
  font-family: "SCDream-Bold";
  font-size: 1.5em;
  color: #001650;
  letter-spacing: -0.03em;
  line-height: 1.2em;
}
.section06 .client_area > span small {
  font-family: "SCDream-Regular";
  font-size: 0.6em;
  color: #646464;
}

.section06 .client_area .client {
  margin-top: 1em;
}
.section06 .client_area .client li {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0.5em;
}
.section06 .client_area .client li:last-child {
  margin-bottom: 0;
}
.section06 .client_area .client li * {
  display: table;
}
.section06 .client_area .client li span {
  margin-right: 0.5em;
  padding: 0.25em 1em;
  font-family: "SCDream-Bold";
  color: #fff;
  background-color: #001650;
  border-radius: 5em;
}

.section06 .part_list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin: 2em auto 0;
  width: 100%;
  max-width: 64em;
}
.section06 .part_list li {
  margin: 1em 1em 0 0;
  width: calc((100% - 4em) / 5);
}
.section06 .part_list li:nth-child(5n) {
  margin-right: 0;
}
.section06 .part_list li a {
  display: block;
  background-color: #fff;
}
.section06 .part_list li a img {
  margin: 0 auto;
}

section .title_area {
  position: relative;
  margin-bottom: 2em;
}
section .title_area span,
section .title_area p {
  display: table;
  margin: 0 auto;
  text-align: center;
}
section .title_area span {
  font-size: 1.25em;
  font-weight: 600;
}
section .title_area p {
  font-size: 0.8em;
}

.section2 .bg {
  position: absolute;
  z-index: 1;
  width: 41.4em;
}
.section2 .bg.spin1 {
  top: -20.7em;
  right: -5em;
  animation: spin1 30s linear infinite;
}
.section2 .bg.spin2 {
  top: 19em;
  right: 3em;
  animation: spin2 30s linear infinite;
}

@keyframes spin1 {
  0% {
    transform: rotate(0);
  }

  50% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes spin2 {
  0% {
    transform: rotate(0);
  }

  50% {
    transform: rotate(-180deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}

section .machine_list {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  margin: 0 auto;
  padding: 0 0.5em;
  width: 100%;
  max-width: 60em;
  z-index: 2;
}

section .machine_list .list {
  margin: 0 1em 1em 0;
  padding: 1em;
  width: calc((100% - 1em) / 2);
  border: 1px solid #dcdcdc;
  background-color: #fff;
  transition: all ease-in-out 0.3s;
}
section .machine_list .list:hover {
  transform: translate(-0.5em, -0.5em);
  border-radius: 0 1em 0 1em;
  box-shadow: 0.5em 0.5em 0 #e5e5e5;
}
section .machine_list .list:nth-child(2n) {
  margin-right: 0;
}
section .machine_list .list span.ml_tit {
  display: block;
  font-weight: 600;
  border-bottom: 1px solid #969696;
}
.ml_list .slick-list {
  overflow: hidden;
}
.ml_list .ml_slide {
  display: flex !important;
  justify-content: flex-start;
  align-items: stretch;
  padding: 1.5em 0 0.5em;
}
.ml_list .ml_slide .ml_img {
  margin-right: 1em;
  width: 12em;
}
.ml_list .ml_slide .ml_img img {
  width: 100%;
}
.ml_list .ml_slide .ml_img .prod_name {
  display: block;
  padding: 0.5em;
  font-size: 0.8em;
  text-align: center;
  color: #fff;
  background-color: #646464;
}
.ml_list .ml_slide .text_area {
  width: calc(100% - 13em);
}
.ml_list .ml_slide .text_area ul li {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
}
.ml_list .ml_slide .text_area ul li span,
.ml_list .ml_slide .text_area ul li p {
  display: table;
  font-size: 0.8em;
}
.ml_list .ml_slide .text_area ul li span {
  width: 5em;
  font-weight: 600;
}
.ml_list .ml_slide .text_area ul li .text_list {
  width: calc(100% - 5em);
}
.ml_list .slick-prev,
.ml_list .slick-next {
  width: 17px;
  height: 29px;
}
.ml_list .slick-prev {
  left: 0;
  transform: translate(-50%, -50%);
}
.ml_list .slick-next {
  right: 0;
  transform: translate(50%, -50%);
}

.ml_list .slick-prev:before,
.ml_list .slick-next:before {
  display: block;
  width: 17px;
  height: 29px;
}
.ml_list .slick-prev:before {
  /* content: url("/src/asset/img/main/btn_mlPrev.png"); */
}
.ml_list .slick-next:before {
  /* content: url("/src/asset/img/main/btn_mlNext.png"); */
}

/* SW인재페스티벌 2022 뉴스 */
.section3 {
  position: relative;
  margin: 0 auto;
  padding: 5em 0.5em;
  width: 100%;
  /* background: url("/src/asset/img/main/bg_section03.jpg") no-repeat center; */
  background-size: cover;
}
.section3 .title_area span,
.section3 .title_area p {
  color: #fff;
}

.mBox_area {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  margin: 0 auto;
  padding: 0 0.5em;
  width: 100%;
  max-width: 60em;
}
.mBox_area .board {
  position: relative;
  margin-right: 1em;
  padding: 1.5em 1em;
  width: calc((100% - 2em) / 3);
  background-color: #fff;
  border-radius: 0 1em 0 1em;
}
.mBox_area .board .box_tit {
  display: block;
  margin-bottom: 0.5em;
  font-weight: 600;
  color: #1c92eb;
}
.mBox_area .board a.btn.more {
  position: absolute;
  display: block;
  top: 1em;
  right: 1em;
  width: 1.5em;
  height: 1.5em;
}
.mBox_area .board a.btn.more span.line {
  position: relative;
  display: block;
  top: 50%;
  width: 1.5em;
  height: 1px;
  background-color: #969696;
}
.mBox_area .board a.btn.more span.line.horizontal {
  transform: rotate(90deg) translateY(-50%);
}
.mBox_area .board .brd_content {
  display: block;
}
.mBox_area .board .brd_content span,
.mBox_area .board .brd_content p {
  display: block;
}
.mBox_area .board .brd_content .brd_name {
  margin-bottom: 0.5em;
  font-size: 1.1em;
  font-weight: 600;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.mBox_area .board .brd_content p.text {
  font-size: 0.8em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  line-height: 1.5em;
  height: 4.5em;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.mBox_area .board .brd_content p.date {
  margin-top: 1em;
  font-size: 0.8em;
}
.mBox_area .file_area a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc((100% - 1em) / 2);
  border-radius: 0 1em 0 1em;
  border: 1px solid transparent;
  transition: all ease-in-out 0.3s;
}
.mBox_area .file_area {
  width: calc((100% - 2em) / 3);
}
.mBox_area .file_area a.down01 {
  margin-bottom: 1em;
  background: rgb(28, 146, 235);
}
.mBox_area .file_area a.down02 {
  background: rgb(28, 185, 188);
}
.mBox_area .file_area a span {
  margin-right: 1em;
  font-size: 1.2em;
  font-weight: 500;
  color: #fff;
  transition: all ease-in-out 0.3s;
}
.mBox_area .file_area a.down01:hover {
  background: #fff;
  border-color: rgb(28, 146, 235);
  transform: translate(-0.5em, -0.5em);
  box-shadow: 0.5em 0.5em 0 rgb(28, 146, 235);
}
.mBox_area .file_area a.down01:hover span {
  color: rgb(28, 146, 235);
}
.mBox_area .file_area a.down02:hover {
  background: #fff;
  border-color: rgb(28, 185, 188);
  transform: translate(-0.5em, -0.5em);
  box-shadow: 0.5em 0.5em 0 rgb(28, 185, 188);
}
.mBox_area .file_area a.down02:hover span {
  color: rgb(28, 185, 188);
}
.mBox_area .file_area a img {
  filter: brightness(0) invert(1);
  transition: all ease-in-out 0.3s;
}
.mBox_area .file_area a:hover img {
  filter: none;
}

/* 21.06.17 client영역 추가 */
.section4 {
  padding: 2em 0;
}
.section4 ul.client_list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 0 0.5em;
  width: 100%;
  max-width: 60em;
}
.section4 ul.client_list li {
  margin-right: 1em;
}
.section4 ul.client_list li:last-child {
  margin-right: 0;
}

/* main popup */
.bannerPop.popup.popupDiv20 {
  display: block;
  top: 50%;
  left: 50%;
  width: auto;
  max-width: 30em;
  height: auto;
  background-color: #fff;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.bannerPop.popup .popCont {
  padding: 0.83em 2em;
}
.bannerPop.popup .popCont .notice_area span {
  display: block;
  margin: 1em auto;
  font-size: 1.1em;
  text-align: center;
  font-weight: 600;
  color: #176742;
}
.bannerPop.popup .popCont .notice_area p {
  font-size: 0.7em;
  line-height: 1.25em;
}
.bannerPop.popup .popCont .notice_area p span {
  display: block;
  margin: 0.5em 0 1.5em;
  font-size: 1.35em;
  color: #9b2f00;
  text-align: left;
}
.bannerPop.popup .closeArea .displayPeriod {
  width: 50%;
}
.bannerPop.popup .closeArea .displayPeriod.today {
  border-right: 1px solid #fff;
}
.bannerPop.popup .closeArea .displayPeriod a {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5em 0.25em;
  font-size: 0.75em;
  color: #fff;
  background-color: #646464;
}

.sian img {
  width: 100%;
}

.floating_btn {
  position: fixed;
  display: block;
  right: 2em;
  bottom: 2em;
  padding: 0.5em 2em;
  color: #fff;
  border: 1px solid rgba(255, 255, 255, 0.75);
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.15);
  border-radius: 5em;
}

@media screen and (max-width: 1260px) {
  .section1 .main_visual .slideList.slide02 .img_area {
    display: none;
  }
}

@media screen and (max-width: 1024px) {
  body {
    font-size: 14px;
  }
  .section1 .main_visual .slideList .text_area .link_area {
    justify-content: center;
  }
  .section1 .main_visual .slideList.slide01 .img_area {
    display: none;
  }
  .section1 .main_visual .dDay {
    bottom: 16em;
    right: 27.75em;
  }

  .section03 .intro_area .text_area > * {
    display: block;
    text-align: center;
  }
  .section03 .intro_area .text_area > p {
    margin-bottom: 1.5em;
  }
  .section03 .intro_area .text_area > p br {
    display: none;
  }
  .section03 .intro_area .text_area a {
    margin: 0 auto;
  }
  .section03 .intro_area .img_area,
  .section03 .intro_area .img_area img {
    width: 100%;
  }
  .section04 .counter_list {
    padding-bottom: 4em;
  }
  .section04 .counter_list li {
    margin: 0 2em 2em 0;
    width: calc((100% - 2em) / 2);
  }
  .section04 .counter_list li:nth-child(2n) {
    margin-right: 0;
  }
  .section06 .part_list li {
    width: calc((100% - 2em) / 3);
  }
  .section06 .part_list li:nth-child(5n) {
    margin-right: 1em;
  }
  .section06 .part_list li:nth-child(3n) {
    margin-right: 0;
  }
}

@media screen and (max-width: 768px) {
  body {
    font-size: 12px;
  }
  .section1 .main_visual .dDay {
    bottom: 2em;
    right: 2em;
  }

  .section1 .main_visual .slideList {
    padding: 11em 0.75em 1em;
    height: auto;
  }
  .section1 .main_visual .slideList .img_area {
    display: none;
  }
  .section1 .main_visual .slideList .text_area {
    height: auto;
    padding-bottom: 8em;
  }
  .section1 .main_visual .slideList .text_area img.slogan.pc {
    display: none;
  }
  .section1 .main_visual .slideList .text_area img.slogan.mobile {
    display: block;
    max-width: 54em;
  }
  .section1 .main_visual .slideList .text_area .link_area {
    margin-top: 4em;
  }
  .section1 .main_visual .slideList .text_area {
    align-items: center;
  }
  .section1 .main_visual .text_area p {
    text-align: center;
    white-space: nowrap;
  }
  .section1 .main_visual .slideList .img_area .mImg2 {
    top: auto;
    left: 1em;
    bottom: 3em;
    transform: none;
  }

  .section02 {
    height: auto;
    min-height: auto;
  }
  .section03 .intro_area .text_area a {
    width: 16em;
  }
  .section03 .intro_area .text_area a img {
    width: 1.875em;
  }
  .section1 .main_visual .slideList .text_area ul li {
    margin-bottom: 0.75em;
  }
  .section1 .main_visual .slideList .text_area ul li span {
    margin: 0;
    padding: 0;
    width: 100%;
  }
  .section1 .main_visual .slideList .text_area ul li span:after {
    display: none;
  }
  .section1 .main_visual .slideList .text_area ul li p {
    width: 100%;
  }

  .section02 .video_area {
    padding: 4em 2em;
  }

  .section03,
  .section05,
  .section06 {
    padding: 4em 0;
  }
  .section03 .intro_area ul li {
    margin: 0 0.75em 0.75em 0;
    width: calc((100% - 0.75em) / 2);
  }
  .section03 .intro_area ul li:nth-child(2n) {
    margin-right: 0;
  }
  .section03 .intro_area ul li .text_area * {
    text-align: center;
  }
  .section03 .intro_area ul li .text_area a {
    margin: 1.5em auto 0;
  }
  .section04 .counter_list {
    padding: 4em 2em 2em;
  }
  .section06 > span {
    font-size: 2em;
  }
  .section06 .part_list {
    padding: 2em 2em 4em;
  }
  .section06 .part_list li {
    width: calc((100% - 1em) / 2);
  }
  .section06 .part_list li:nth-child(3n) {
    margin-right: 1em;
  }
  .section06 .part_list li:nth-child(2n) {
    margin-right: 0;
  }
}

@media screen and (max-width: 425px) {
  .section1 .main_visual .slideList .text_area .link_area {
    margin-top: 2em;
  }
  .section1 .main_visual .slideList .text_area ul li p {
    font-size: 0.8rem;
  }
  .section03 .intro_area ul li {
    margin: 0 0 0.75em;
    width: 100%;
  }
  .section04 .counter_list li span.count {
    font-size: 3em;
  }
}
