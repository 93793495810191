/* font */
@font-face {
  font-family: "SCDream-Bold";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_six@1.2/S-CoreDream-6Bold.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "SCDream-Medium";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_six@1.2/S-CoreDream-5Medium.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "SCDream-Regular";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_six@1.2/S-CoreDream-4Regular.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
}

/* common */
body,
th,
td,
input,
select,
textarea,
button {
  font-size: 16px;
}

body {
  position: relative;
  overflow: visible;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
body::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera*/
}
body.open {
  overflow: hidden;
}

.wrap {
  position: relative;
  margin: 0;
  width: 100%;
}

.btn_area {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: stretch;
}
.btn_area.evaluate {
  margin-top: 2em;
}

.btn_area .btn {
  display: table;
  margin: 0 0.5em;
  padding: 0.5em 3em;
  font-size: 1.25rem;
  text-align: center;
  font-weight: 600;
  color: #fff;
  border-radius: 5em;
  background-color: #8c7eb8;
}
.btn_area .btn.save {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 2rem;
  bottom: 2rem;
  width: 5em;
  height: 5em;
  background-color: #0758ac;
  border-radius: 0.2rem;
}
.btn_area .btn.save.end {
  position: static;
  width: auto;
  height: auto;
  border-radius: 5em;
}
.btn_area .btn.youtube,
.btn_area .btn.naver {
  padding: 0;
}

.subVisual {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  padding: 5em 0 0;
  width: 100%;
  height: 22em;
  background: url("/src/asset/img/sub/bg_subV.png") no-repeat center bottom;
  background-size: cover;
}
.subVisual .img_area {
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 5em;
  left: 50%;
  padding: 0 0.75em;
  width: 100%;
  max-width: 64em;
  height: 17em;
  z-index: 1;
  transform: translateX(-50%);
}
.subVisual .img_area img {
  display: block;
}
.subVisual .img_area img.img {
  width: calc(100% - 20em);
}
.subVisual .img_area img.title {
  width: 15em;
}
.subVisual > .text_area {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  margin: 0 auto;
  padding: 0 17em 2.75em 0.75em;
  width: 100%;
  max-width: 64em;
  height: 17em;
  z-index: 2;
}
.subVisual > .text_area p {
  display: block;
  width: 100%;
  font-family: "SCDream-Medium";
  text-align: right;
  color: #021750;
}
.subVisual > .text_area p.small {
  color: rgba(255, 255, 255, 0.75);
}
.subVisual > .text_area .pNav {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 0.5em;
}
.subVisual > .text_area .pNav p {
  position: relative;
  display: table;
  margin-left: 1em;
  padding-left: 1.5em;
  color: #fff;
}
.subVisual > .text_area .pNav p:before {
  position: absolute;
  content: "";
  top: 50%;
  left: 0;
  width: 0.6em;
  height: 0.6em;
  background: url(/src/asset/img/sub/ico_pArrow.png) no-repeat center;
  background-size: contain;
  transform: translateY(-40%);
}

.subVisual > .text_area .sText {
  position: relative;
  display: table;
  margin-top: 2em;
  padding: 1em 1.5em;
  background-color: rgba(0, 0, 0, 0.35);
  border: 1px solid rgba(255, 255, 255, 0.3);
}
.subVisual > .text_area .sText * {
  color: rgba(255, 255, 255, 0.9);
}
.subVisual > .text_area .sText span {
  margin-bottom: 0.25em;
  font-family: "Noto Sans KR", "Malgun Gothic", "맑은 고딕", sans-serif;
  font-size: 1em;
}
.subVisual > .text_area .sText p {
  position: relative;
}
.subVisual > .text_area .sText p.asterisk {
  padding-left: 1.25em;
}
.subVisual > .text_area .sText p.asterisk::before {
  position: absolute;
  content: "※";
  top: 0;
  left: 0;
}

.container {
  position: relative;
  padding: 0;
  z-index: 2;
}

.container .page_title {
  position: relative;
  display: table;
  margin: 3em auto 0.5em;
  font-family: "SCDream-Bold";
  font-size: 1.5em;
  font-weight: 600;
  text-align: center;
  color: #001650;
}
.container .page_title small {
  font-family: "SCDream-Regular";
  font-size: 0.7em;
  color: #646464;
}

.sub_nav {
  position: relative;
  margin: 0 auto;
  width: 100%;
  z-index: 1;
  background-color: #8c7eb8;
}
.sub_nav > ul {
  display: flex;
  justify-content: flex-start;
  /* justify-content: stretch; */
  align-items: stretch;
  /* flex-wrap: wrap; */
  margin: 0 auto;
  padding: 0 0.75em;
  width: 100%;
  max-width: 64em;
  height: 3em;
}
.sub_nav.depth5 > ul {
  height: 3em;
}
.sub_nav > ul > li {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc((100% - 4em) / 4);
}
.sub_nav.depth2 > ul > li {
  width: 50%;
}
.sub_nav.depth3 > ul > li {
  width: calc(100% / 3);
}
.sub_nav.depth4 > ul > li {
  width: calc(100% / 4);
}
.sub_nav.depth5 > ul > li {
  width: calc(100% / 5);
}
.sub_nav > ul > li.active {
  background-color: #7565a5;
}

.sub_nav > ul > li a {
  display: block;
  padding: 0 1em;
  width: 100%;
  color: #fff;
  text-align: center;
}

/* common */
.title_area {
  margin: 2em auto;
  padding: 0 0.5em;
  width: 100%;
  max-width: 90em;
}
.title_area span,
.title_area p {
  display: block;
  text-align: center;
}
.title_area span {
  font-size: 1.25em;
  font-weight: 600;
}
.title_area p {
  font-size: 0.8em;
}

.sub_wrap {
  position: relative;
  padding: 0 0.75em 5em;
  margin: 0 auto;
  width: 100%;
  max-width: 64em;
}

.sub_wrap > .sub_title {
  display: table;
  margin: 3em 0 0.5em;
  font-family: "SCDream-Bold";
  font-size: 1.5em;
  color: #001650;
}

/* login */
.sub_wrap.login > span {
  display: block;
  margin: 0 auto;
  font-size: 2em;
  font-family: "SCDream-Bold";
  text-align: center;
}
.sub_wrap.login div.infoArea {
  margin: 1em auto 0;
  max-width: 50em;
  padding: 1em 2em;
  border: 1px solid #8c7eb8;
  border-radius: 2em;
  /* margin: 1em 1em; */
}
.sub_wrap.login div.infoArea p {
  font-size: 1rem;
}
.sub_wrap.login .login_form {
  margin: 2em auto 0;
  padding: 2em;
  width: 100%;
  max-width: 50em;
  border: 1px solid #969696;
}
.sub_wrap.login .login_form {
  margin: 2em auto 0;
  padding: 2em;
  width: 100%;
  max-width: 50em;
  border: 1px solid #969696;
}

.sub_wrap.login .login_form input,
.sub_wrap.login .login_form select,
.sub_wrap.login .login_form button {
  display: block;
  padding: 0.5em;
  width: 100%;
  font-size: 1rem;
  border: 1px solid #dcdcdc;
  border-radius: 0.25em;
}
.sub_wrap.login .login_form .input_area {
  margin-bottom: 0.5em;
}
.sub_wrap.login .login_form button {
  margin-top: 2em;
  padding: 1em;
  color: #fff;
  background-color: #8c7eb8;
  border-color: transparent;
}

.sub_wrap.login .idpw_find {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1em auto 0;
  width: 100%;
  max-width: 50em;
}

.sub_wrap.login .idpw_find a {
  position: relative;
  display: block;
  padding: 0 1em;
  color: #646464;
}
.sub_wrap.login .idpw_find a:first-child:before {
  position: absolute;
  content: "";
  top: 0.4em;
  right: 0;
  width: 1px;
  height: 0.8em;
  background-color: #969696;
}

.sub_wrap.login .login_form .login_inp.email1,
.sub_wrap.login .login_form .login_inp.email2 {
  margin-right: 0.5em;
  width: calc((100% - 14.5em) / 2);
}
.sub_wrap.login .login_form .login_inp.phone {
  margin-right: 0.5em;
  width: calc(100% - 11.5em);
}
.sub_wrap.login .login_form span.email {
  display: block;
  margin-right: 0.5em;
  width: 2em;
  text-align: center;
}
.sub_wrap.login .login_form .login_slt.email {
  width: 11em;
}
.sub_wrap.login .login_form button.phone {
  margin-top: 0;
  padding: 0.5em 1em;
  width: 11em;
}

.sub_wrap.login .login_form input[type="checkbox"],
.sub_wrap.login .login_form input[type="radio"] {
  margin-right: 0.5em;
  width: auto;
}
.sub_wrap.login .login_form label {
  margin-right: 1em;
}
.sub_wrap.login .login_form ul {
  padding-bottom: 1em;
  margin-bottom: 1.5em;
  border-bottom: 1px solid #969696;
}
.sub_wrap.login .login_form ul li {
  margin-bottom: 1em;
}
.sub_wrap.login .login_form ul li:last-child {
  margin: 0;
}
.sub_wrap.login .login_form ul li span {
  display: block;
  margin-bottom: 0.5em;
  font-weight: 600;
}
.sub_wrap.login .login_form ul li span b {
  color: red;
}

.sub_wrap.login .login_form .input_area.terms {
}
.sub_wrap.login .login_form input.inp-cbx {
  margin-right: 0.5em;
  width: 1em;
}
.sub_wrap.login .login_form label.cbx {
  width: calc(100% - 11.5em);
}
.sub_wrap.login .login_form button.termsPopup1,
.sub_wrap.login .login_form button.termsPopup2 {
  margin-top: 0;
  padding: 0.25em 1em;
  width: 11em;
  background-color: #969696;
}

/* introduce */
.sub_wrap.introduce {
  padding: 0;
  max-width: 100%;
}
.sub_wrap.introduce .intro_top {
  position: relative;
  padding: 8em 0 0;
  background: url(/src/asset/img/sub/bg_intro01.png) no-repeat center -20em;
  background-size: cover;
}
.sub_wrap.introduce .intro_top > span {
  display: block;
  margin: 0 auto 3em;
  padding: 0 0.75em;
  width: 100%;
  max-width: 35.9em;
}
.sub_wrap.introduce .intro_top .text_area {
  position: relative;
  margin: 0 auto;
  padding: 2em;
  width: 100%;
  max-width: calc(64em - 1.5em);
  border-radius: 1em;
  background-color: #f7f7f7;
  box-shadow: 0 0.5em 1em rgba(0, 0, 0, 0.1);
  z-index: 2;
}
.sub_wrap.introduce .intro_top .text_area > * {
  display: block;
  text-align: center;
}
.sub_wrap.introduce > div > div > span {
  display: block;
  font-family: "SCDream-Bold";
  font-size: 1.25em;
  text-align: center;
  color: #001650;
}

.sub_wrap.introduce .intro_top .text_area ul {
  margin-top: 2em;
}
.sub_wrap.introduce .intro_top .text_area ul li {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 1em;
}
.sub_wrap.introduce .intro_top .text_area ul li:last-child {
  margin: 0;
}
.sub_wrap.introduce .intro_top .text_area ul li span {
  display: block;
  margin-right: 1em;
  width: 6em;
  padding: 0.25em;
  font-family: "SCDream-Bold";
  text-align: center;
  color: #fff;
  background-color: #001650;
  border-radius: 5em;
}
.sub_wrap.introduce .intro_top .text_area ul li .text {
  padding-top: 0.25em;
  width: calc(100% - 7em);
}
.sub_wrap.introduce .intro_top .text_area ul li p {
  display: block;
  font-family: "SCDream-Medium";
  text-align: left;
}
p.alert {
  font-size: 0.9em;
  color: #d90000;
}
p.asterisk {
  position: relative;
  padding-left: 1em;
}
p.asterisk::before {
  position: absolute;
  content: "※";
  top: 0.2em;
  left: 0;
  font-size: 0.8em;
}

.sub_wrap.introduce .intro_section {
  position: relative;
  padding: 3em 0 1em;
}
.sub_wrap.introduce .intro_section .section_table {
  margin: 0 auto;
  padding: 0 0.75em;
  width: 100%;
  max-width: 64em;
}
.sub_wrap.introduce .intro_section span.is_tit {
  display: table;
  margin: 0 auto 0.5em;
  font-family: "SCDream-Medium";
  font-size: 1.5em;
  color: #646464;
}
.sub_wrap.introduce .intro_section span.table_title {
  display: table;
  margin: 2em 0 0;
  font-family: "SCDream-Bold";
  font-size: 0.8em;
  color: #001650;
}
.sub_wrap.introduce .intro_section span.title {
  display: table;
  margin: 0 auto;
  font-family: "SCDream-Bold";
  font-size: 2em;
  text-align: center;
  line-height: 1.3em;
}
.sub_wrap.introduce .intro_section span.title b {
  color: #24cfe6;
}
.sub_wrap.introduce .intro_section span.slogan {
  display: table;
  margin: 0 auto 2em;
  font-family: "SCDream-Bold";
  font-size: 1.75em;
  text-align: center;
  line-height: 1.3em;
}
.sub_wrap.introduce .intro_section img.table {
  margin-bottom: 1em;
}
.sub_wrap.introduce .intro_section > ul {
  display: table;
  margin: 1em auto 0;
  width: auto;
}
.sub_wrap.introduce .intro_section > ul.table {
  margin-bottom: 1em;
  width: 100%;
  max-width: 68em;
}
.sub_wrap.introduce .intro_section > ul li {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 2em;
}
.sub_wrap.introduce .intro_section > ul li:last-child {
  margin: 0;
}
.sub_wrap.introduce .intro_section > ul li img {
  display: block;
  margin-right: 2em;
  width: 11em;
}
.sub_wrap.introduce .intro_section > ul li .text_area > * {
  display: block;
}
.sub_wrap.introduce .intro_section > ul li .text_area span {
  font-size: 1.5em;
  font-weight: 600;
  color: #d8b6a5;
}
.sub_wrap.introduce .intro_section > ul li:first-child .text_area span {
  color: #7db3bb;
}
.sub_wrap.introduce .intro_section > ul li:last-child .text_area span {
  color: #dc6f99;
}
.sub_wrap.introduce .intro_section > ul li .text_area p {
  margin-top: 0.5em;
  font-size: 1.1em;
}
.sub_wrap.introduce .intro_section > ul.table li {
  align-items: stretch;
  margin-bottom: 0;
  width: 100%;
}
.sub_wrap.introduce .intro_section > ul.table li.th {
  background: rgb(150, 130, 235);
  background: -moz-linear-gradient(
    90deg,
    rgba(150, 130, 235, 1) 0%,
    rgba(41, 206, 230, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(150, 130, 235, 1) 0%,
    rgba(41, 206, 230, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(150, 130, 235, 1) 0%,
    rgba(41, 206, 230, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#9682eb",endColorstr="#29cee6",GradientType=1);
}
.sub_wrap.introduce .intro_section > ul.table li > span {
  display: block;
  padding: 0.5em;
  font-weight: 600;
  text-align: center;
  color: #fff;
  border-right: 1px solid #fff;
  border-bottom: 1px solid #fff;
}
.sub_wrap.introduce .intro_section > ul.table li .td {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border-right: 1px solid #fff;
}
.sub_wrap.introduce .intro_section > ul.table li > span:first-child,
.sub_wrap.introduce .intro_section > ul.table li .td:first-child {
  width: 5.88%;
}
.sub_wrap.introduce .intro_section > ul.table li > span:nth-child(2),
.sub_wrap.introduce .intro_section > ul.table li .td:nth-child(2) {
  width: 17.64%;
}
.sub_wrap.introduce .intro_section > ul.table li > span:nth-child(3),
.sub_wrap.introduce .intro_section > ul.table li .td:nth-child(3) {
  width: 40%;
}
.sub_wrap.introduce .intro_section > ul.table li > span:nth-child(4),
.sub_wrap.introduce .intro_section > ul.table li .td:nth-child(4) {
  width: 48.51%;
}
.sub_wrap.introduce .intro_section > ul.table li > span:last-child,
.sub_wrap.introduce .intro_section > ul.table li .td:last-child {
  border-right: none;
  width: 7.35%;
}
.sub_wrap.introduce .intro_section > ul.table li:last-child .td {
  border-bottom: none;
}

.sub_wrap.introduce .intro_section > ul.table li .td p {
  display: block;
  padding: 0.5em;
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #fff;
}
.sub_wrap.introduce .intro_section > ul.table li .td.left p {
  text-align: left;
}
.sub_wrap.introduce .intro_section > ul.table li .td p:last-child {
  border: none;
}
.sub_wrap.introduce .intro_section > ul.table li .td p small {
  display: inline-block;
  padding: 0.1em;
  width: 100%;
  color: #fff;
}
.sub_wrap.introduce .intro_section > ul.table li .td p span {
  display: table;
  margin-bottom: 0.25em;
  padding: 0.25em 0.75em;
  color: #fff;
  background-color: #13778e;
  border-radius: 0.2em;
}
.sub_wrap.introduce .intro_section > ul.table li .td p small.blue {
  background-color: #24cfe6;
}
.sub_wrap.introduce .intro_section > ul.table li .td p small.red {
  background-color: red;
}
.sub_wrap.introduce .intro_section > ul.table.tbl02 li .td:last-child p {
  height: 25%;
}

.sub_wrap.introduce .intro_section > ul.table.tbl01 li > span.rowspan2 {
  width: 30%;
}
.sub_wrap.introduce .intro_section > ul.table.tbl01 li > span:nth-child(2) {
  width: 40%;
}
.sub_wrap.introduce .intro_section > ul.table.tbl01 li .td {
  background-color: #e8e6f9;
}
.sub_wrap.introduce .intro_section > ul.table.tbl01 li .td:first-child {
  background-color: #d2cef4;
}
.sub_wrap.introduce .intro_section > ul.table.tbl01 li > span:last-child,
.sub_wrap.introduce .intro_section > ul.table.tbl01 li .td:last-child {
  width: 30%;
}
.sub_wrap.introduce .intro_section > ul.table.tbl01 li .td:first-child,
.sub_wrap.introduce .intro_section > ul.table.tbl01 li .td:nth-child(2) {
  width: calc(30% / 2);
}
.sub_wrap.introduce .intro_section > ul.table.tbl02 li .td:nth-child(3) {
  width: 40%;
}

.sub_wrap.introduce .intro_section > ul.table.tbl02 li > span.rowspan2 {
  width: 30%;
}
.sub_wrap.introduce .intro_section > ul.table.tbl02 li > span:nth-child(2) {
  width: 40%;
}
.sub_wrap.introduce .intro_section > ul.table.tbl02 li .td {
  background-color: #d4eff5;
}
.sub_wrap.introduce .intro_section > ul.table.tbl02 li .td:first-child {
  background-color: #a9dfec;
}
.sub_wrap.introduce .intro_section > ul.table.tbl02 li > span:last-child,
.sub_wrap.introduce .intro_section > ul.table.tbl02 li .td:last-child {
  width: 30%;
}
.sub_wrap.introduce .intro_section > ul.table.tbl02 li .td:first-child,
.sub_wrap.introduce .intro_section > ul.table.tbl02 li .td:nth-child(2) {
  width: calc(30% / 2);
}
.sub_wrap.introduce .intro_section > ul.table.tbl02 li .td:nth-child(3) {
  width: 40%;
}

.sub_wrap.introduce .intro_section p.caution {
  position: relative;
  margin: 0 auto;
  padding-left: 1.25em;
  width: 100%;
  max-width: 68em;
}
.sub_wrap.introduce .intro_section p.caution:before {
  position: absolute;
  content: "※";
  top: 0;
  left: 0;
}

.sub_wrap.introduce .intro_section .table {
  margin: 0.5em auto 0;
  width: 100%;
}
.sub_wrap.introduce .intro_section .table.tbl01 {
  margin-bottom: 4em;
}

.sub_wrap.introduce .intro_section .table thead tr {
  background: rgb(226, 130, 155);
  background: -moz-linear-gradient(
    90deg,
    rgba(226, 130, 155, 0.5) 0%,
    rgba(132, 126, 186, 0.5) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(226, 130, 155, 0.5) 0%,
    rgba(132, 126, 186, 0.5) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(226, 130, 155, 0.5) 0%,
    rgba(132, 126, 186, 0.5) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#E2829B",endColorstr="#847EBA",GradientType=1);
}

.sub_wrap.introduce .intro_section .table th,
.sub_wrap.introduce .intro_section .table td {
  padding: 0.5em;
  font-size: 1em;
  text-align: center;
}
.sub_wrap.introduce .intro_section .table th {
  color: #fff;
  border-right: 1px solid #fff;
}
/* program */
.sub_wrap.introduce .intro_section .section_table.mobile > ul {
  margin: 0.5em 0;
}
.sub_wrap.introduce .intro_section .section_table.mobile > span.pLocation {
  display: block;
  font-size: 1rem;
  font-weight: bold;
}
.sub_wrap.introduce .intro_section .section_table.mobile > label.programTitle {
  font-size: 1.1rem;
  font-weight: 500;
  color: #051650;
}
.sub_wrap.introduce .intro_section .section_table.mobile ul li span.pTime {
  font-size: 0.9rem;
  font-weight: bold;
}
.sub_wrap.introduce .intro_section .section_table.mobile ul li p {
  margin-left: 1em;
  font-size: 1rem;
}
.sub_wrap.introduce .intro_section .table td {
  /* font-size: 0.8em; */
  font-size: 1.3rem;
  border-right: 1px solid #fff;
  border-bottom: 1px solid #fff;
}
.sub_wrap.introduce .intro_section .table th:last-child {
  border-right: none;
}
.sub_wrap.introduce .intro_section .table td.left {
  text-align: left;
}
.sub_wrap.introduce .intro_section .table.tbl01 td {
  /* background-color: #eee; */
  background-color: #f0f0f0;
}
.sub_wrap.introduce .intro_section .table.tbl01 td > div.subSection {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 1em;
}
.sub_wrap.introduce
  .intro_section
  .table.tbl01
  td
  > div.subSection
  > .subSectionTitle {
  margin-top: 0.2em;
  padding: 0.1em 1em;
  border: 1px solid #061650;
  border-radius: 5em;
}
.sub_wrap.introduce
  .intro_section
  .table.tbl01
  td
  > div.subSection
  > div.subSectionInfo {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  margin-left: 0.5em;
}
.sub_wrap.introduce
  .intro_section
  .table.tbl01
  td
  > div.subSection
  > div.subSectionInfo
  .panelWrap {
  margin-left: 0.8em;
  padding-left: 0.2em;
  margin-top: 1em;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: rgba(8, 18, 65, 0.1);
  width: 80%;
}
.sub_wrap.introduce
  .intro_section
  .table.tbl01
  td
  > div.subSection
  > div.subSectionInfo
  .panelWrap
  > label {
  padding: 0.8em 0.5em;
  margin-top: 0.2em;
  margin-bottom: 0.2em;
  background-color: #051241;
  color: white;
}
.sub_wrap.introduce
  .intro_section
  .table.tbl01
  td
  > div.subSection
  > div.subSectionInfo
  .panelWrap
  > div.panelList {
  display: flex;
  flex-wrap: wrap;
  margin-left: 0.5em;
}
.sub_wrap.introduce
  .intro_section
  .table.tbl01
  td
  > div.subSection
  > div.subSectionInfo
  .panelWrap
  > div.panelList
  > span {
  font-size: 0.8em;
  width: 50%;
}

.sub_wrap.introduce
  .intro_section
  .table.tbl01
  td
  > div.subSection
  > div.subSectionInfo
  p
  span.lTitle {
  font-weight: bold;
  color: #001650;
}
.sub_wrap.introduce
  .intro_section
  .table.tbl01
  td
  > div.subSection
  > div.subSectionInfo
  p
  span.lWho {
  font-size: 0.8em;
}
.sub_wrap.introduce
  .intro_section
  .table.tbl01
  td
  > div.subSection
  > div.subSectionInfo
  p.helpText {
  margin-left: 1em;
  font-size: 0.75em;
}

.sub_wrap.introduce .intro_section .table.tbl01 td.time {
  font-weight: bold;
  color: #051650;
}
.sub_wrap.introduce .intro_section .table.tbl01.introTable td > span.who {
  font-size: 1rem;
  margin-left: 0.5em;
}

.sub_wrap.introduce .intro_section .table.tbl02 td {
  background-color: #d4eff5;
}
.sub_wrap.introduce .intro_section .table.tbl02 tr:first-child td:first-child {
  background-color: #a9dfec;
}
.sub_wrap.introduce .intro_section .table.tbl03 td {
  background-color: #e8e6f9;
}
.sub_wrap.introduce .intro_section .table.tbl03 tr:first-child td:first-child {
  background-color: #d2cef4;
}
.sub_wrap.introduce .intro_section .table.tbl03 tr:nth-child(5) td:first-child {
  background-color: #a9dfec;
}
.sub_wrap.introduce .intro_section .table.tbl03 tr:nth-child(5) td,
.sub_wrap.introduce .intro_section .table.tbl03 tr:nth-child(6) td,
.sub_wrap.introduce .intro_section .table.tbl03 tr:nth-child(7) td,
.sub_wrap.introduce .intro_section .table.tbl03 tr:nth-child(8) td,
.sub_wrap.introduce .intro_section .table.tbl03 tr:nth-child(9) td {
  background-color: #d4eff5;
}
.sub_wrap.introduce .intro_section .table.tbl04 td {
  background-color: #f0f0f0;
}
.sub_wrap.introduce .intro_section .table.tbl04 td.title {
  font-weight: 600;
}
.sub_wrap.introduce .intro_section .table.tbl04 tr.total td {
  color: #323232;
  font-weight: bold;
  background-color: #e0e0e0;

  /* background-color: rgba(140, 126, 184, 0.7); */
}

.sub_wrap.introduce .intro_section .table td .ss2 span {
  display: table-cell;
  padding: 0.25em 0.75em;
  color: #fff;
  background-color: #13778e;
  border-radius: 0.2em;
}

/* excellent_work */
.subVisual.univ01 {
  background: url("/src/asset/img/sub/bg_univ01.jpg") no-repeat center;
  background-size: cover;
}
.subVisual .univ_info {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}
.subVisual .bg_area {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 30em;
  z-index: 1;
  overflow: hidden;
}
.subVisual .bg_area img {
  position: absolute;
  top: 0;
  left: 0;
  width: auto;
  min-width: 100%;
  height: 100%;
  object-fit: cover;
}
.subVisual .univ_info .ci_area {
  position: relative;
  margin: 0 auto -1em;
  width: 8.5em;
  height: 8.5em;
  overflow: hidden;
  border-radius: 50%;
  z-index: 2;
}
.subVisual .univ_info .text_area {
  padding: 2em;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1;
}
.subVisual .univ_info .text_area.entrance {
  background: none;
}
.subVisual .univ_info .text_area * {
  display: block;
  text-align: center;
  color: #fff;
}
.subVisual .univ_info .text_area span {
  font-family: "SCDream-Medium";
  font-size: 1.75em;
}
.subVisual .univ_info .text_area span.univ_tit {
  display: table;
  margin: 0.5em auto 0;
  padding: 0.25em 2em;
  font-size: 1rem;
  color: #fff;
  background-color: #7e17fd;
  border-radius: 5em;
}
.subVisual .univ_info .text_area p {
  margin-top: 0.5em;
  padding-top: 1em;
  font-family: "SCDream-Regular";
  font-size: 0.8em;
  letter-spacing: 0.5em;
  color: rgba(255, 255, 255, 0.75);
  border-top: 1px solid #fff;
}

.wrap.exhibition .excellentWork {
  padding: 3em 5em 5em;
}
.wrap.exhibition .ewArea {
  background: url(/src/asset/img/sub/bg_ew.png) no-repeat center;
  background-size: cover;
  background-attachment: fixed;
}
.wrap.exhibition .ew_list ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
}
.wrap.exhibition .ew_list .guide_area {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  margin-bottom: 2em;
  padding: 1em;
  border: 1px solid #dcdcdc;
}
.wrap.exhibition .ew_list .guide_area.watch {
  padding: 2em 1em;
  background-color: #f5f5f5;
  border-color: #f5f5f5;
  border-radius: 0.5em;
}

.wrap.exhibition .ew_list .guide_area ul {
  display: block;
  margin: 0;
  padding: 0;
  width: auto;
}
.wrap.exhibition .ew_list .guide_area.watch ul {
  width: 100%;
}

.wrap.exhibition .ew_list .guide_area ul li {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 0.5em;
  border: none;
  width: 100%;
  border-radius: 0;
}
.wrap.exhibition .ew_list .guide_area.watch ul li {
  align-items: center;
}

.wrap.exhibition .ew_list .guide_area ul li:hover {
  transform: none;
  box-shadow: none;
}
.wrap.exhibition .ew_list .guide_area ul li:first-child {
  border-bottom: 1px solid #969696;
}
.wrap.exhibition .ew_list .guide_area.watch ul li:first-child {
  border: none;
}

.wrap.exhibition .ew_list .guide_area ul li:last-child {
  margin-bottom: 0;
}
.wrap.exhibition .ew_list .guide_area ul li > * {
  position: relative;
  display: table;
  font-size: 0.8em;
}
.wrap.exhibition .ew_list .guide_area ul li span {
  width: 6.5em;
  padding-left: 1.25em;
  font-weight: 600;
}
.wrap.exhibition .ew_list .guide_area.watch ul li span {
  padding: 0.25em;
  width: 7.5em;
  text-align: center;
  color: #fff;
  background-color: #001650;
  border-radius: 5em;
}

.wrap.exhibition .ew_list .guide_area ul li span::before {
  position: absolute;
  content: "▶";
  top: 0.2em;
  left: 0;
  font-size: 0.8em;
}
.wrap.exhibition .ew_list .guide_area.watch ul li span::before {
  display: none;
}

.wrap.exhibition .ew_list .guide_area ul li span.guide_title {
  display: block;
  margin: 0;
  padding: 0 0 0.5em;
  width: 100%;
}
.wrap.exhibition .ew_list .guide_area ul li span.guide_title::before {
  display: none;
}
.wrap.exhibition .ew_list .guide_area ul li p {
  width: calc(100% - 6.5em);
  padding-left: 0.75em;
}
.wrap.exhibition .ew_list .guide_area.watch.watchInfo > ul {
  font-size: 1.1em;
}
.wrap.exhibition .ew_list .guide_area.watch ul li p {
  width: calc(100% - 7.5em);
}

.wrap.exhibition .ew_list .guide_area ul li p.asterisk {
  padding-left: 1em;
  color: #d90000;
}

.wrap.exhibition .ew_list .guide_area ul li p::before {
  position: absolute;
  content: "-";
  top: 0;
  left: 0;
}
.wrap.exhibition .ew_list .guide_area.watch ul li p::before {
  display: none;
}

.wrap.exhibition .ew_list .guide_area ul li p.asterisk::before {
  position: absolute;
  content: "※";
  top: 0.25em;
  left: 0;
}

.wrap.exhibition .ew_list.work .page_title {
  margin-bottom: 1em;
}

.wrap.exhibition .ew_list .counting_area {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1em 2em;
  width: auto;
  background-color: #051e48;
}
.reg_area .table {
  margin: 0.5em auto 0;
  width: 100%;
}

.reg_area .table thead tr {
  background: rgb(226, 130, 155);
  background: -moz-linear-gradient(
    90deg,
    rgba(226, 130, 155, 0.5) 0%,
    rgba(132, 126, 186, 0.5) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(226, 130, 155, 0.5) 0%,
    rgba(132, 126, 186, 0.5) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(226, 130, 155, 0.5) 0%,
    rgba(132, 126, 186, 0.5) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#E2829B",endColorstr="#847EBA",GradientType=1);
}

.reg_area .table th,
.reg_area .table td {
  padding: 0.5em;
  font-size: 1em;
  text-align: center;
}
.reg_area .table th {
  color: #fff;
  border-right: 1px solid #fff;
}
.reg_area .table td {
  font-size: 0.8em;
  border-right: 1px solid #dcdcdc;
  border-bottom: 1px solid #dcdcdc;
}
.reg_area .table th:last-child,
.reg_area .table td:last-child {
  border-right: none;
}
.reg_area .table td.left {
  text-align: left;
}
.reg_area .table.tbl04 td.title {
  font-weight: 600;
}
.reg_area .table.tbl04 tr.total td {
  color: #fff;
  background-color: #8c7eb8;
}
.reg_area .table td .ss2 span {
  display: table-cell;
  padding: 0.25em 0.75em;
  color: #fff;
  background-color: #13778e;
  border-radius: 0.2em;
}
.reg_area img {
  margin: 0 auto;
  width: 100%;
  max-width: 63em;
}
.wrap.exhibition .ew_list .counting_area * {
  display: table;
  margin: 0 auto;
  color: #fff;
}
.wrap.exhibition .ew_list .counting_area span {
  font-family: "SCDream-Bold";
  font-size: 1.2em;
}
.wrap.exhibition .ew_list .btn_reg {
  margin: 1em auto 0;
  padding-top: 1em;
  width: 100%;
}
.wrap.exhibition .ew_list .btn_reg a {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 1em;
  width: 100%;
  max-width: 10em;
  height: 100%;
  text-align: center;
  color: #fff;
  background-color: #8c7eb8;
  border-radius: 0.5em;
}
.tabs {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 3em auto 0;
}
.tabs .tab_button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.75em;
  padding: 0.75em;
  width: calc((100% - 0.75em) / 2);
  background: #f5f5f5;
  border: none;
  border-radius: 0.5em 0.5em 0 0;
}
.tabs .tab_button.active {
  font-weight: 600;
  color: #fff;
  background: rgb(228, 130, 155);
  background: -moz-linear-gradient(
    111deg,
    rgba(228, 130, 155, 1) 0%,
    rgba(130, 126, 187, 1) 100%
  );
  background: -webkit-linear-gradient(
    111deg,
    rgba(228, 130, 155, 1) 0%,
    rgba(130, 126, 187, 1) 100%
  );
  background: linear-gradient(
    111deg,
    rgba(228, 130, 155, 1) 0%,
    rgba(130, 126, 187, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#e4829b",endColorstr="#827ebb",GradientType=1);
}
.tabs .tab_button:last-child {
  margin-right: 0;
}
.wrap.exhibition .booth_area {
  border: 1px solid #dcdcdc;
}

.wrap.exhibition .booth_list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin-top: 1em;
}
.wrap.exhibition .booth_list > div {
  width: 100%;
  margin-bottom: 2em;
}
/* .wrap.exhibition .booth_list > div:nth-child(4),
.wrap.exhibition .booth_list > div:last-child {
  margin: 0 1em 1em 0;
  width: calc((100% - 1em) / 2);
} */
.wrap.exhibition .booth_list > div:last-child {
  margin-right: 0;
}
.wrap.exhibition .booth_list > div .bl_tit {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5em 1em;
  width: auto;
}
.wrap.exhibition .booth_list > div .bl_tit * {
  position: relative;
  display: table;
  color: #fff;
}
.wrap.exhibition .booth_list .bl01 .bl_tit {
  background-color: #d8468f;
}
.wrap.exhibition .booth_list .bl02 .bl_tit {
  background-color: #f08420;
}
.wrap.exhibition .booth_list .bl03 .bl_tit {
  background-color: #78bc41;
}
.wrap.exhibition .booth_list .bl04 .bl_tit {
  background-color: #2a6ba1;
}
.wrap.exhibition .booth_list .bl05 .bl_tit {
  background-color: #724598;
}

.wrap.exhibition .booth_list > div .bl_tit span {
  font-size: 1.1em;
  font-weight: 600;
}
.wrap.exhibition .booth_list > div .bl_tit p {
  margin-left: 0.5em;
  padding-left: 0.5em;
}
.wrap.exhibition .booth_list > div .bl_tit p::before {
  content: "";
  position: absolute;
  top: calc(50% - 0.4em);
  left: 0;
  width: 1px;
  height: 0.8em;
  background-color: #fff;
}

.wrap.exhibition .booth_list > div ul {
  margin-top: 1em;
}
.wrap.exhibition .booth_list > div ul li {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5em 1em;
  width: calc((100% - 4em) / 5);
  border-radius: 0.5em;
}
.wrap.exhibition .booth_list > div.bl03 ul li {
  width: calc((100% - 4em) / 3);
}
.wrap.exhibition .booth_list > div:nth-child(4) ul li,
.wrap.exhibition .booth_list > div:last-child ul li {
  width: auto;
}

.wrap.exhibition .booth_list > div ul li:nth-child(4n) {
  margin-right: 1em;
}
.wrap.exhibition .booth_list > div ul li:nth-child(5n) {
  margin-right: 0em;
}
.wrap.exhibition .booth_list > div.bl05 ul li {
  width: calc((100% - 4em) / 5);
}
.wrap.exhibition .booth_list > div.bl03 ul li:nth-child(5n) {
  margin-right: 1em;
}
.wrap.exhibition .booth_list > div.bl05 ul li:nth-child(5n) {
}
.wrap.exhibition .booth_list > div ul li * {
  display: table;
  font-size: 0.9em;
}
.wrap.exhibition .booth_list > div ul li span {
  margin-right: 0.5em;
  font-weight: 600;
}
.wrap.exhibition .booth_list .bl01 ul li span {
  color: #d8468f;
}
.wrap.exhibition .booth_list .bl02 ul li span {
  color: #f08420;
}
.wrap.exhibition .booth_list .bl03 ul li span {
  color: #78bc41;
}
.wrap.exhibition .booth_list .bl04 ul li span {
  color: #2a6ba1;
}
.wrap.exhibition .booth_list .bl05 ul li span {
  color: #724598;
}

.sections .content {
  display: none;
}
.sections .content.active {
  display: block;
}
.watch_map {
  padding: 1em 0;
  border-top: 3px solid #323232;
}
.watch_map .map_info {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 2em;
}
.watch_map .map_info > div {
  width: 50%;
}

.watch_map .map_info .logo img {
  width: 100%;
  max-width: 16em;
}

.watch_map .map_info .info ul {
  display: block;
}
.watch_map .map_info .info ul li {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 0 0.5em !important;
  width: 100% !important;
  border: none !important;
  overflow: visible !important;
}
.watch_map .map_info .info ul li:last-child {
  margin: 0 !important;
}
.watch_map .map_info .info ul li * {
  display: table;
  font-size: 0.9em;
}
.watch_map .map_info .info ul li span {
  position: relative;
  padding-left: 1.5em;
  width: 7em;
  font-family: "SCDream-Bold";
}
.watch_map .map_info .info ul li span::before {
  content: "";
  position: absolute;
  top: 0.2em;
  left: 0;
  width: 1em;
  height: 1em;
}
.watch_map .map_info .info ul li:first-child span::before {
  background: url(/src/asset/img/sub/ico_mphone.png) no-repeat center;
  background-size: cover;
}
.watch_map .map_info .info ul li:nth-child(2) span::before {
  background: url(/src/asset/img/sub/ico_mfax.png) no-repeat center;
  background-size: cover;
}
.watch_map .map_info .info ul li:nth-child(3) span::before {
  top: 0.3em;
  background: url(/src/asset/img/sub/ico_mpin.png) no-repeat center;
  background-size: contain;
}
.wrap.exhibition .watch_map .directions {
  margin: 0 auto 2em;
  padding: 2em 0.75em;
  width: 100%;
  border: 1px solid #323232;
  border-radius: 1em;
}
.wrap.exhibition .watch_map .directions ul {
  display: block;
  width: 100%;
}
.wrap.exhibition .watch_map .directions ul li {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1em;
  padding-bottom: 1em;
  width: 100%;
  border: none;
  border-bottom: 1px solid #dcdcdc;
  border-radius: 0;
}
.wrap.exhibition .watch_map .directions ul li:last-child {
  margin: 0;
  padding: 0;
  border: none;
}
.wrap.exhibition .watch_map .directions ul li .tit {
  margin-right: 1em;
  width: 10em;
}
.wrap.exhibition .watch_map .directions ul li .tit img {
  margin: 0 auto 0.5em;
  width: 100%;
  max-width: 6em;
}
.wrap.exhibition .watch_map .directions ul li .tit span {
  display: table;
  margin: 0 auto;
  font-family: "SCDream-Bold";
}
.wrap.exhibition .watch_map .directions ul li .text {
  width: calc(100% - 11em);
}
.wrap.exhibition .watch_map .directions ul li .text * {
  display: block;
}
.wrap.exhibition .watch_map .directions ul li .text span {
  margin-bottom: 0.5em;
  font-weight: 600;
}
.wrap.exhibition .watch_map .directions ul li .text span:last-child {
  margin: 0;
}

.tbl_top {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 1em;
  padding: 1em 0;
  border-top: 1px solid #dcdcdc;
  border-bottom: 1px solid #dcdcdc;
}
.tbl_top .sorting_area {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.tbl_top .sorting_area > p {
  font-size: 0.9em;
}
.tbl_top .sorting_area .input_area input[type="checkbox"] {
  display: none;
}
.tbl_top .sorting_area label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 0.5em;
  padding: 0.5em 1em;
  font-weight: 600;
  border: 1px solid #969696;
  border-radius: 5em;
  transition: all ease-in-out 0.3s;
  box-sizing: border-box;
}
.tbl_top .sorting_area label span {
  position: relative;
  display: table;
  margin-right: 0.5em;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  border: 1px solid #969696;
  transition: all ease-in-out 0.3s;
}
.tbl_top .sorting_area .input_area input[type="checkbox"]:checked + label {
  padding: calc(0.5em - 2px) calc(1em - 2px);
  border-width: 3px;
  border-color: #24cfe6;
}
.tbl_top .sorting_area .input_area input[type="checkbox"]:checked + label span {
  background-color: #24cfe6;
  border-color: #24cfe6;
}
.tbl_top a:not(.openBlank) {
  display: table;
  padding: 0.5em 1em;
  color: #fff;
  border: none;
  background-color: #24cfe6;
}

.wrap.exhibition .ew_list ul li {
  margin: 0 1em 1em 0;
  width: calc((100% - 3em) / 4);
  border: 1px solid #dcdcdc;
  border-radius: 1em;
  overflow: hidden;
  transition: all ease-in-out 0.3s;
}
.wrap.exhibition .ew_list ul li a {
  display: block;
}
.wrap.exhibition .ew_list ul li a span {
  display: block;
  text-align: center;
}
.wrap.exhibition .ew_list ul li:nth-child(4n) {
  margin-right: 0;
}
.wrap.exhibition .ew_list ul li:hover {
  box-shadow: 0 0.3em 0.75em rgba(0, 0, 0, 0.075);
}
.wrap.exhibition .ew_list ul li .bg_list {
  position: relative;
  display: block;
  padding-top: 60.1275%;
  width: 100%;
  transition: background-size 0.3s ease-in-out;
  -moz-transition: background-size 0.3s ease-in-out;
  -webkit-transition: background-size 0.3s ease-in-out;
  overflow: hidden;
}

.wrap.exhibition .ew_list ul li .bg_list:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  transition: all ease-in-out 0.3s;
}
.wrap.exhibition .ew_list ul li:hover .bg_list:before {
  background-color: rgba(0, 0, 0, 0);
  transition: all ease-in-out 0.3s;
}
.wrap.exhibition .ew_list ul li .bg_list > img.bg {
  position: absolute;
  top: 0;
  left: 50%;
  width: auto;
  height: 100%;
  transform: translateX(-50%);
  object-fit: cover;
  transition: all ease-in-out 0.3s;
}
.wrap.exhibition .ew_list ul li .bg_list:hover > img.bg {
  transform: translateX(-50%) scale(1.1);
}
.wrap.exhibition .ew_list.work ul li .bg_list[data-bg="1"] {
  background: url(/src/asset/img/sub/1.jpg) no-repeat center;
  background-size: auto 100%;
}
.wrap.exhibition .ew_list.work ul li .bg_list[data-bg="2"] {
  background: url(/src/asset/img/sub/2.jpg) no-repeat center;
  background-size: auto 100%;
}
.wrap.exhibition .ew_list.work ul li .bg_list[data-bg="3"] {
  background: url(/src/asset/img/sub/3.jpg) no-repeat center;
  background-size: auto 100%;
}
.wrap.exhibition .ew_list.work ul li .bg_list[data-bg="4"] {
  background: url(/src/asset/img/sub/4.jpg) no-repeat center;
  background-size: auto 100%;
}
.wrap.exhibition .ew_list.work ul li .bg_list[data-bg="5"] {
  background: url(/src/asset/img/sub/5.jpg) no-repeat center;
  background-size: auto 100%;
}
.wrap.exhibition .ew_list.work ul li .bg_list[data-bg="6"] {
  background: url(/src/asset/img/sub/6.jpg) no-repeat center;
  background-size: auto 100%;
}
.wrap.exhibition .ew_list.work ul li .bg_list[data-bg="7"] {
  background: url(/src/asset/img/sub/7.jpg) no-repeat center;
  background-size: auto 100%;
}
.wrap.exhibition .ew_list.work ul li .bg_list[data-bg="8"] {
  background: url(/src/asset/img/sub/8.jpg) no-repeat center;
  background-size: auto 100%;
}
.wrap.exhibition .ew_list.work ul li .bg_list[data-bg="9"] {
  background: url(/src/asset/img/sub/9.jpg) no-repeat center;
  background-size: auto 100%;
}
.wrap.exhibition .ew_list.work ul li .bg_list[data-bg="10"] {
  background: url(/src/asset/img/sub/10.jpg) no-repeat center;
  background-size: auto 100%;
}
.wrap.exhibition .ew_list.work ul li .bg_list[data-bg="11"] {
  background: url(/src/asset/img/sub/11.jpg) no-repeat center;
  background-size: auto 100%;
}
.wrap.exhibition .ew_list.work ul li .bg_list[data-bg="12"] {
  background: url(/src/asset/img/sub/12.jpg) no-repeat center;
  background-size: auto 100%;
}
.wrap.exhibition .ew_list.work ul li .bg_list[data-bg="13"] {
  background: url(/src/asset/img/sub/13.jpg) no-repeat center;
  background-size: auto 100%;
}
.wrap.exhibition .ew_list.work ul li .bg_list[data-bg="14"] {
  background: url(/src/asset/img/sub/14.jpg) no-repeat center;
  background-size: auto 100%;
}
.wrap.exhibition .ew_list.work ul li .bg_list[data-bg="15"] {
  background: url(/src/asset/img/sub/15.jpg) no-repeat center;
  background-size: auto 100%;
}
.wrap.exhibition .ew_list.work ul li .bg_list[data-bg="16"] {
  background: url(/src/asset/img/sub/16.jpg) no-repeat center;
  background-size: auto 100%;
}
.wrap.exhibition .ew_list.work ul li .bg_list[data-bg="17"] {
  background: url(/src/asset/img/sub/17.jpg) no-repeat center;
  background-size: auto 100%;
}
.wrap.exhibition .ew_list.work ul li .bg_list[data-bg="18"] {
  background: url(/src/asset/img/sub/18.jpg) no-repeat center;
  background-size: auto 100%;
}
.wrap.exhibition .ew_list.work ul li .bg_list[data-bg="19"] {
  background: url(/src/asset/img/sub/19.jpg) no-repeat center;
  background-size: auto 100%;
}
.wrap.exhibition .ew_list.work ul li .bg_list[data-bg="20"] {
  background: url(/src/asset/img/sub/20.jpg) no-repeat center;
  background-size: auto 100%;
}
.wrap.exhibition .ew_list.work ul li .bg_list[data-bg="21"] {
  background: url(/src/asset/img/sub/21.jpg) no-repeat center;
  background-size: auto 100%;
}
.wrap.exhibition .ew_list.work ul li .bg_list[data-bg="22"] {
  background: url(/src/asset/img/sub/22.jpg) no-repeat center;
  background-size: auto 100%;
}
.wrap.exhibition .ew_list.work ul li .bg_list[data-bg="23"] {
  background: url(/src/asset/img/sub/23.jpg) no-repeat center;
  background-size: auto 100%;
}
.wrap.exhibition .ew_list.work ul li .bg_list[data-bg="24"] {
  background: url(/src/asset/img/sub/24.jpg) no-repeat center;
  background-size: auto 100%;
}
.wrap.exhibition .ew_list.work ul li .bg_list[data-bg="25"] {
  background: url(/src/asset/img/sub/25.jpg) no-repeat center;
  background-size: auto 100%;
}
.wrap.exhibition .ew_list.work ul li .bg_list[data-bg="26"] {
  background: url(/src/asset/img/sub/26.jpg) no-repeat center;
  background-size: auto 100%;
}
.wrap.exhibition .ew_list.work ul li .bg_list[data-bg="27"] {
  background: url(/src/asset/img/sub/27.jpg) no-repeat center;
  background-size: auto 100%;
}
.wrap.exhibition .ew_list.work ul li .bg_list[data-bg="28"] {
  background: url(/src/asset/img/sub/28.jpg) no-repeat center;
  background-size: auto 100%;
}
.wrap.exhibition .ew_list.work ul li .bg_list[data-bg="29"] {
  background: url(/src/asset/img/sub/29.jpg) no-repeat center;
  background-size: auto 100%;
}
.wrap.exhibition .ew_list.work ul li .bg_list[data-bg="30"] {
  background: url(/src/asset/img/sub/30.jpg) no-repeat center;
  background-size: auto 100%;
}
.wrap.exhibition .ew_list.work ul li .bg_list[data-bg="31"] {
  background: url(/src/asset/img/sub/31.jpg) no-repeat center;
  background-size: auto 100%;
}
.wrap.exhibition .ew_list.work ul li .bg_list[data-bg="32"] {
  background: url(/src/asset/img/sub/32.jpg) no-repeat center;
  background-size: auto 100%;
}
.wrap.exhibition .ew_list.work ul li .bg_list[data-bg="33"] {
  background: url(/src/asset/img/sub/33.jpg) no-repeat center;
  background-size: auto 100%;
}
.wrap.exhibition .ew_list.work ul li .bg_list[data-bg="34"] {
  background: url(/src/asset/img/sub/34.jpg) no-repeat center;
  background-size: auto 100%;
}
.wrap.exhibition .ew_list.work ul li .bg_list[data-bg="35"] {
  background: url(/src/asset/img/sub/35.jpg) no-repeat center;
  background-size: auto 100%;
}
.wrap.exhibition .ew_list.work ul li .bg_list[data-bg="36"] {
  background: url(/src/asset/img/sub/36.jpg) no-repeat center;
  background-size: auto 100%;
}
.wrap.exhibition .ew_list.work ul li .bg_list[data-bg="37"] {
  background: url(/src/asset/img/sub/37.jpg) no-repeat center;
  background-size: auto 100%;
}
.wrap.exhibition .ew_list.work ul li .bg_list[data-bg="38"] {
  background: url(/src/asset/img/sub/38.jpg) no-repeat center;
  background-size: auto 100%;
}
.wrap.exhibition .ew_list.work ul li .bg_list[data-bg="39"] {
  background: url(/src/asset/img/sub/39.jpg) no-repeat center;
  background-size: auto 100%;
}
.wrap.exhibition .ew_list.work ul li .bg_list[data-bg="40"] {
  background: url(/src/asset/img/sub/40.jpg) no-repeat center;
  background-size: auto 100%;
}
.wrap.exhibition .ew_list.work ul li .bg_list[data-bg="41"] {
  background: url(/src/asset/img/sub/41.jpg) no-repeat center;
  background-size: auto 100%;
}
.wrap.exhibition .ew_list ul li .bg_list:hover,
.wrap.exhibition .ew_list.work ul li .bg_list:hover {
  background-size: auto 110%;
}
.wrap.exhibition .ew_list.work ul li .bg_list[data-bg="42"] {
  background: url(/src/asset/img/sub/42.jpg) no-repeat center;
  background-size: auto 100%;
}
.wrap.exhibition .ew_list.work ul li .bg_list[data-bg="43"] {
  background: url(/src/asset/img/sub/43.jpg) no-repeat center;
  background-size: auto 100%;
}
.wrap.exhibition .ew_list.work ul li .bg_list[data-bg="44"] {
  background: url(/src/asset/img/sub/44.jpg) no-repeat center;
  background-size: auto 100%;
}

.wrap.exhibition .ew_list ul li .bg_list a {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 3em;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.wrap.exhibition .ew_list ul li .text_area {
  padding-top: 0.75em;
}
.wrap.exhibition .ew_list ul li .text_area .univ_area {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.wrap.exhibition .ew_list ul li .text_area .year {
  display: table;
  margin: 0.5em auto 0;
  padding: 0.25em 1em;
  font-size: 0.9em;
  color: #fff;
  background-color: #8c7eb8;
  border-radius: 0.5em;
}
.wrap.exhibition .ew_list ul li .text_area .univ_area {
  margin-top: 0.5em;
}
.wrap.exhibition .ew_list.entrance ul li .text_area .univ_area {
  margin-top: 0;
}
.wrap.exhibition .ew_list ul li .text_area .univ_area * {
  display: table;
}
.wrap.exhibition .ew_list ul li .text_area .univ_area img {
  width: 60%;
}
.wrap.exhibition .ew_list ul li .text_area .univ_area .univ_text {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  width: 40%;
}
.wrap.exhibition .ew_list ul li .text_area .univ_area .univ_name {
  margin-right: 0.25em;
  font-size: 0.8em;
  font-weight: 600;
}
.wrap.exhibition .ew_list ul li .text_area .univ_area .area {
  font-size: 0.9rem;
}
.wrap.exhibition .ew_list ul li .text_area .work_area {
  margin-top: 0.5em;
  padding: 1em;
  border-top: 1px solid #dcdcdc;
  border-bottom: 1px solid #dcdcdc;
}
.wrap.exhibition .ew_list ul li .text_area .work_area > div {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 0.5em;
}
.wrap.exhibition .ew_list ul li .text_area .work_area > div * {
  display: table;
  font-size: 0.8em;
}
.wrap.exhibition .ew_list ul li .text_area .work_area > div p {
  width: 4em;
  font-weight: 600;
}
.wrap.exhibition .ew_list ul li .text_area .work_area > div:first-child p {
  letter-spacing: 0.01em;
}
.wrap.exhibition .ew_list ul li .text_area .work_area > div span {
  display: block;
  font-size: 0.8em;
}
.wrap.exhibition .ew_list ul li .text_area .work_area .work {
  width: calc(100% - 4em);
  line-height: 1.5em;
  display: -webkit-box;
  word-wrap: break-word;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 4.5em;
}

.wrap.exhibition .ew_list ul li .text_area .work_area .keyword {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 1em;
}
.wrap.exhibition .ew_list ul li .text_area .work_area .keyword p {
  display: table;
  margin: 0 0.25em 0.5em;
  padding: 0.25em 1em;
  font-size: 0.8rem;
  color: #fff;
  background-color: #969696;
  border-radius: 5em;
}
.wrap.exhibition .ew_list ul li .text_area .btn_area {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.wrap.exhibition .ew_list ul li .text_area .btn_area.el_btn {
  margin-top: 0.25em;
  padding: 0.75em 1em;
  border: 1px solid #dcdcdc;
}

.wrap.exhibition .ew_list ul li .text_area .btn_area > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.25em 0;
}
.wrap.exhibition .ew_list ul li .text_area .btn_area > div:last-child {
  border: none;
}

.wrap.exhibition .ew_list ul li .text_area .btn_area > div > p {
  width: calc(100% - 8em);
  font-size: 0.9em;
}
.wrap.exhibition .ew_list ul li .text_area .btn_area > div > p.phone {
  width: 8em;
  font-weight: 600;
  text-align: center;
}

.wrap.exhibition .ew_list ul li .text_area .btn_area .btn {
  width: 100%;
  margin: 0;
  padding: 0.75em;
  font-size: 0.8em;
  font-weight: 500;
  color: #323232;
  background: none;
  border: none;
  border-right: 1px solid #dcdcdc;
  border-radius: 0;
  transition: all ease-in-out 0.3s;
}
.wrap.exhibition .ew_list ul li .text_area .btn_area .btn:hover {
  color: #fff;
  background-color: #04bad2;
  border-color: #04bad2;
}
.wrap.exhibition .ew_list ul li .text_area .btn_area .btn:last-child {
  border: none;
}
.wrap.exhibition .ew_list ul li .text_area .btn_area.el_btn .btn {
  margin-right: 1em;
  padding: 0.25em 1em;
  width: 8em;
  font-size: 0.9em;
}
.wrap.exhibition .ew_list ul li .text_area .btn_area.el_btn .btn:last-child {
  margin-right: 0;
}
.wrap.exhibition .excellentWork .ex_section {
  position: relative;
  margin-bottom: 4em;
}
.wrap.exhibition .excellentWork .ex_section span.guide_vote {
  position: absolute;
  top: 1.75em;
  right: 0;
}
.wrap.exhibition .excellentWork .ex_section .btn_area {
  margin-top: 4em;
}
.wrap.exhibition .excellentWork .ex_section .btn_area .btn {
  display: flex;
  justify-content: center;
  align-items: center;
}
.wrap.exhibition .excellentWork .ex_section .btn_area .btn img {
  margin-left: 0.5em;
  width: 1em;
}
.wrap.exhibition .excellentWork .ex_section .btn_area .btn.end {
  background-color: #969696;
}
.wrap.exhibition .excellentWork .ex_section .btn_area .btn.end img {
  display: none;
}
.wrap.exhibition .excellentWork .ex_section .si_title {
  margin-bottom: 3em;
  padding: 1.5em 0 0.4em 1em;
  background: url(/src/asset/img/sub/bg_siTit.png) no-repeat left;
  background-size: contain;
}
.wrap.exhibition .excellentWork .ex_section .es_title {
  margin-bottom: 3em;
  padding: 1.75em 0 0.15em 2.75em;
  background: url(/src/asset/img/sub/bg_ewTit.png) no-repeat left;
  background-size: contain;
}
.wrap.exhibition .excellentWork .ex_section .ent_title {
  margin-bottom: 2em;
  padding: 1.5em 0 0.4em 1em;
  background: url(/src/asset/img/sub/bg_entTit.png) no-repeat left;
  background-size: contain;
}
.wrap.exhibition .excellentWork .ex_section .si_title span,
.wrap.exhibition .excellentWork .ex_section .es_title span,
.wrap.exhibition .excellentWork .ex_section .ent_title span {
  display: block;
  font-family: "SCDream-Medium";
  color: #fff;
}

.wrap.exhibition .excellentWork .ex_section .video_area {
  position: relative;
  padding-top: 56%;
  width: 100%;
  height: 0;
  background-color: #323232;
}
.wrap.exhibition .excellentWork .ex_section .video_area iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.wrap.exhibition .excellentWork .ex_section .img_area img {
  display: block;
  width: 100%;
}

.wrap.exhibition .excellentWork .ex_section .slider_area {
  position: relative;
}
.wrap.exhibition .excellentWork .ex_section .slider_area canvas {
  width: 100%;
}
.wrap.exhibition .excellentWork .ex_section .slider_area .slider_arrow {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  transform: translateY(-50%);
}
.wrap.exhibition .excellentWork .ex_section .slider_area .slider_arrow nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.wrap.exhibition .excellentWork .ex_section .slider_area .slider_arrow nav a {
  width: 5em;
}
.wrap.exhibition
  .excellentWork
  .ex_section
  .slider_area
  .slider_arrow
  nav
  a#prev {
  transform: translateX(-50%);
}
.wrap.exhibition
  .excellentWork
  .ex_section
  .slider_area
  .slider_arrow
  nav
  a#next {
  transform: translateX(50%);
}
.wrap.exhibition .excellentWork .ex_section .slider_area .pages {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1em;
}
.wrap.exhibition .excellentWork .ex_section .slider_area .pages .mr-2 {
  display: none;
}
.wrap.exhibition .excellentWork .ex_section .slider_area .pages span {
  font-size: 1.25rem;
}
.wrap.exhibition
  .excellentWork
  .ex_section
  .slider_area
  .pages
  span#currentPage {
  font-weight: 600;
}
.wrap.exhibition
  .excellentWork
  .ex_section
  .slider_area
  .pages
  span#totalPages {
  color: #969696;
}
.wrap.exhibition .excellentWork .ex_section .pdf_area {
  position: relative;
  padding-top: 56%;
  width: 100%;
  height: 0;
  background-color: #323232;
}
.wrap.exhibition .excellentWork .ex_section .pdf_area .cover {
  position: absolute;
  top: 3px;
  right: 3px;
  width: 10em;
  height: 3.25em;
  background-color: #323639;
  z-index: 10;
}

@media screen and (max-width: 575px) {
  .wrap.exhibition .excellentWork .ex_section .pdf_area .cover {
    display: none;
  }
}
.wrap.exhibition .excellentWork .ex_section iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
}

.wrap.exhibition .excellentWork .ex_section .box {
  padding: 2em;
  width: 100%;
  background-color: #f1f1f1;
}

.wrap.exhibition .excellentWork .ex_section .box .univ_name {
  display: block;
  margin: 0 auto;
  padding-bottom: 0.5em;
  width: auto;
  font-family: "SCDream-Medium";
  font-size: 2rem;
  text-align: center;
  border-bottom: 1px solid #969696;
}
.wrap.exhibition .excellentWork .ex_section .box > p {
  display: block;
  margin: 0.25em auto 0.5em;
  font-size: 1.25em;
  text-align: center;
  color: #969696;
}

.wrap.exhibition .excellentWork .ex_section .box .info_list ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding-top: 1em;
}
.wrap.exhibition .excellentWork .ex_section .box .info_list ul li {
  display: flex;
  justify-content: left;
  align-items: center;
  margin-right: 2em;
  width: auto;
}
.wrap.exhibition .excellentWork .ex_section .box .info_list ul li:last-child {
  margin-right: 0;
}
.wrap.exhibition .excellentWork .ex_section .box .info_list ul li img {
  margin-right: 0.5em;
  width: 2.4375em;
}
.wrap.exhibition .excellentWork .ex_section .box .info_list ul li p,
.wrap.exhibition .excellentWork .ex_section .box .info_list ul li a {
  font-family: "SCDream-Regular";
  font-size: 1.25rem;
}

.wrap.exhibition .excellentWork .ex_section .ent_info ul li {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0.5em;
}
.wrap.exhibition .excellentWork .ex_section .ent_info ul li * {
  display: table;
}
.wrap.exhibition .excellentWork .ex_section .ent_info ul li span {
  width: 8em;
  font-weight: 600;
}

.wrap.exhibition .excellentWork .btn_area.ent a {
  background-color: #7e17fd;
}
.wrap.exhibition .excellentWork .btn_area.ent a i {
  margin-left: 0.5em;
}

/* evaluate */
.evaluate_area {
  margin: 0 auto;
  padding: 3em 2em 5em;
  width: 100%;
  max-width: 90em;
}

.evaluate_area .user_info * {
  display: table;
  margin: 0 auto;
}
.evaluate_area .user_info img {
  margin-bottom: 1em;
  width: 100%;
  max-width: 10em;
}
.evaluate_area .user_info span {
  font-size: 1.5em;
  font-weight: 600;
}
.evaluate_area .user_info p {
  margin-top: 0.5em;
  color: #646464;
}

.evaluate_area .ea_section {
  margin-top: 3em;
}
.evaluate_area .ea_section .ea_title {
  position: relative;
  display: block;
  padding: 0 0 0.25em 1.25em;
  font-family: "SCDream-Bold";
  font-size: 1.25em;
  border-bottom: 1px solid #000;
}
.evaluate_area .ea_section .ea_title:before {
  position: absolute;
  content: "";
  top: 50%;
  left: 0;
  width: 1em;
  height: 1em;
  background: url(/src/asset/img/sub/ico_check.png) no-repeat;
  background-size: cover;
  transform: translateY(-60%);
}
.evaluate_area .ea_section .ea_content {
  margin-top: 1em;
}
.evaluate_area .ea_section .ea_content ul.info li {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.75em 0;
  border-bottom: 1px solid #dcdcdc;
}
.evaluate_area .ea_section .ea_content ul.info li span {
  margin-right: 1em;
  width: 5em;
  font-weight: 600;
  color: #24cfe6;
}
.evaluate_area .ea_section .ea_content ul.info li p {
  width: calc(100% - 6em);
}

.evaluate_area .ea_section .ea_content .ea_contWrap {
  margin-bottom: 3em;
}
.evaluate_area .ea_section .ea_content .ea_contWrap > span {
  display: table;
  padding-bottom: 0.25em;
  font-family: "SCDream-Bold";
}
.evaluate_area .ea_section .ea_content .ea_contWrap > ul li {
  position: relative;
  padding-left: 1em;
}
.evaluate_area .ea_section .ea_content .ea_contWrap > ul li:before {
  position: absolute;
  content: "";
  top: 0.6em;
  left: 0;
  width: 0.4em;
  height: 0.4em;
  background-color: #000;
  border-radius: 50%;
}
.evaluate_area .ea_section .ea_content .ea_contWrap > ul li span b {
  color: red;
}

.evaluate_area .ea_section .ea_content .ea_contWrap table {
  margin-top: 2em;
  width: 100%;
  border-top: 3px solid #000;
}
.evaluate_area .ea_section .ea_content .ea_contWrap table th,
.evaluate_area .ea_section .ea_content .ea_contWrap table td {
  padding: 0.5em;
  text-align: center;
  border-bottom: 1px solid #dcdcdc;
}
.evaluate_area .ea_section .ea_content .ea_contWrap table th {
  border-right: 1px solid #dcdcdc;
  background-color: #f5f5f5;
}
.evaluate_area .ea_section .ea_content .ea_contWrap table th:last-child {
  border-right: none;
}
.evaluate_area
  .ea_section
  .ea_content
  .ea_contWrap
  table.score_guide
  th:nth-child(even) {
  background-color: #ebebeb;
}
.evaluate_area .ea_section .ea_content .ea_contWrap table td {
  border-right: 1px solid #dcdcdc;
}
.evaluate_area .ea_section .ea_content .ea_contWrap table td:last-child {
  border-right: none;
}
.evaluate_area .ea_section .ea_content .ea_contWrap table tr:last-child td {
  border-bottom: 1px solid #000;
}
.evaluate_area .ea_section .ea_content .ea_contWrap table td.left {
  text-align: left;
}

.evaluate_area .ea_section .ea_content .ea_contWrap table td span {
  font-weight: 600;
}

.evaluate_area .ea_section .ea_content .ea_contWrap table td .input_area input {
  display: block;
  width: 100%;
  text-align: center;
  border: 1px solid #e5e5e5;
}
.evaluate_area .ea_section .ea_content .ea_contWrap table td a.link {
  display: inline-block;
  margin: 0.25em auto 0;
  padding: 0.25em 1em;
  color: #fff;
  background-color: #646464;
  border-radius: 5em;
}

.evaluate_area .ea_section .ea_content > .input_area textarea {
  margin-bottom: 2em;
  width: 100%;
}

/* greeting */
.greeting_area {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  margin: 0 auto;
  padding: 0 0.5em 15em;
  width: 100%;
  max-width: 90em;
}
.greeting_area span.psub_title {
  display: table;
  width: 7.5em;
  font-size: 1.2em;
  font-weight: 600;
}
.greeting_area .text {
  margin-right: 1em;
  width: calc(100% - 25em);
}
.greeting_area .text p {
  font-size: 0.8em;
  line-height: 1.3em;
  color: #4b4b4b;
}
.greeting_area .text span.sign {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  font-size: 0.8em;
  color: #323232;
}
.greeting_area .text span.sign img {
  display: table;
  margin-left: 0.5em;
}
.greeting_area .img_greeting img {
  width: auto;
  max-width: 100%;
  height: auto;
}

.wrap.greeting footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

/* introduce */
.introduce_area {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 2em 0.5em 15em;
  width: 100%;
  max-width: 90em;
}
.introduce_area .img_area {
  width: calc(100% - 27em);
}
.introduce_area .text {
  margin-left: 3em;
  width: 24em;
}
.introduce_area .text .jalnan {
  display: block;
  margin-bottom: 0.75em;
  font-size: 1.5em;
}
.introduce_area .text .slg1 {
  color: #051e48;
}
.introduce_area .text .slg2 {
  color: #ec6701;
}
.introduce_area .text p {
  display: block;
  margin: 2em 0 1em;
  padding-top: 2em;
  font-size: 0.8em;
  font-weight: 500;
  border-top: 1px solid #dcdcdc;
}
.introduce_area .text a.btn {
  display: table;
  margin-top: 2em;
  padding: 0.5em 2em;
  font-size: 0.8em;
  font-weight: 500;
  color: #fff;
  background-color: #051e48;
  border-radius: 5em;
}

/* last event */
.last_event .event_list {
  margin-top: 3em;
}
.last_event .event_list > ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}
.last_event .event_list > ul li {
  margin-right: 0.75em;
  width: calc((100% - 3em) / 5);
}
.last_event .event_list > ul li:last-child {
  margin: 0;
}

.last_event .event_list > ul li * {
  display: table;
}
.last_event .event_list > ul li img {
  margin-bottom: 1em;
  border: 1px solid #dcdcdc;
}
.last_event .event_list > ul li .year {
  margin: 0 auto 1em;
  padding: 0.25em 1em;
  font-family: "SCDream-Bold";
  color: #fff;
  background-color: #8c7eb8;
  border-radius: 0.25em;
}
.last_event .event_list > ul li a {
  margin: 0 auto;
  padding: 0.5em 2em;
  font-weight: 600;
  border: 1px solid #dcdcdc;
  border-radius: 5em;
}

.popup .modal-cont.le {
  padding: 2em 1em;
  height: auto;
  border-radius: 0.5em;
}
.popup .modal-cont .event_detail {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}
.popup .modal-cont .event_detail .img_area {
  margin-right: 1em;
  width: 11.9em;
}
.popup .modal-cont .event_detail .text_area {
  width: calc(100% - 12.9em);
}
.popup .modal-cont .event_detail .text_area * {
  display: table;
}
.popup .modal-cont .event_detail .text_area .year {
  margin: 0 0 0.5em;
  padding: 0.25em 1em;
  font-family: "SCDream-Bold";
  color: #fff;
  background-color: #8c7eb8;
  border-radius: 0.25em;
}
.popup .modal-cont .event_detail .text_area .title {
  margin-bottom: 1em;
  font-family: "SCDream-Bold";
}
.popup .modal-cont .event_detail .text_area p {
  margin-bottom: 0.25em;
  font-size: 0.85em;
}

/* 참여대학 리스트 */
.univ_list.section06 {
  padding: 0 0;
  background: none;
}
.univ_list.section06 .part_list li {
  border: 1px solid #dcdcdc;
}

/* 사업소개 */
.sub_wrap.introduce .intro_top.univ {
  background: none;
}
.sub_wrap.introduce .intro_top.univ .text_area {
  padding: 0 0.75em;
  background: none;
  box-shadow: none;
}
.sub_wrap.introduce .intro_top.univ .text_area span small {
  position: relative;
  display: block;
  padding-top: 1.75em;
  color: #323232;
}
.sub_wrap.introduce .intro_top.univ .text_area span small::before {
  position: absolute;
  content: "";
  top: 0.35em;
  left: calc(50% - 1px);
  width: 2px;
  height: 1em;
  background-color: #001650;
}

.sub_wrap.introduce .intro_top.univ .text_area p {
  margin-top: 1em;
}

.sub_wrap.introduce .intro_top.univ > ul {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 3em auto 0;
  padding: 0 0.75em;
  width: 100%;
  max-width: 64em;
}
.sub_wrap.introduce .intro_top.univ > ul::after {
  position: absolute;
  content: "";
  top: calc(50% - 1em);
  left: 1em;
  width: calc(100% - 2em);
  height: 2em;
  background-color: #e6e6e6;
}
.sub_wrap.introduce .intro_top.univ > ul li {
  position: relative;
  margin: 0 1em 0 0;
  width: calc((100% - 4em) / 5);
  z-index: 1;
}
.sub_wrap.introduce .intro_top.univ > ul li:last-child {
  margin: 0;
}

.sub_wrap.introduce .intro_top.univ img.introduce {
  margin: 0 auto;
  padding: 0 0.75em;
  width: 100%;
  max-width: 64em;
}

.sub_wrap.introduce .intro_content.univ {
  margin: 4em auto 0;
  padding: 0 0.75em;
  width: 100%;
  max-width: 64em;
  background-color: #f7f7f7;
}
.sub_wrap.introduce .intro_content.univ.scd {
  margin-bottom: 8em;
  background-color: #fff;
}
.sub_wrap.introduce .intro_content.univ .is_list .text_area {
  margin-bottom: 3em;
}
.sub_wrap.introduce .intro_content.univ .is_list .text_area > span {
  display: table;
  margin: 0 auto 1em;
  padding: 0.25em 2em;
  font-weight: 600;
  color: #fff;
  background-color: #001650;
  border-radius: 5em;
}
.sub_wrap.introduce .intro_content.univ .is_list .text_area > p {
  display: table;
  margin: 0 auto;

  text-align: center;
}
.sub_wrap.introduce .intro_content.univ .is_list .text_area > p b {
  display: block;
  margin-bottom: 0.25em;
}
.sub_wrap.introduce .intro_content.univ .is_list .text_area img.history {
  margin: 2em auto 0;
  width: 100%;
  max-width: 56.15em;
}

.sub_wrap.introduce .intro_content.univ.scd > ul li {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  margin-bottom: 1em;
}
.sub_wrap.introduce .intro_content.univ.scd > ul li:last-child {
  margin: 0;
}
.sub_wrap.introduce .intro_content.univ.scd > ul li > div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.sub_wrap.introduce .intro_content.univ.scd > ul li .title_area {
  margin: 0;
  padding: 1.5em 0.75em;
  width: 14em;
  background-color: #001650;
  border-radius: 0.5em 0 0 0.5em;
}
.sub_wrap.introduce .intro_content.univ.scd > ul li .title_area img {
  margin: 0 auto 1em;
}
.sub_wrap.introduce .intro_content.univ.scd > ul li .title_area span {
  display: block;
  width: 100%;
  font-size: 1em;
  color: #fff;
}
.sub_wrap.introduce .intro_content.univ.scd > ul li .text_area {
  padding: 1em;
  width: calc(100% - 14em);
  border-radius: 0 0.5em 0.5em 0;
  background-color: #f7f7f7;
}
.sub_wrap.introduce .intro_content.univ.scd > ul li .text_area p {
  position: relative;
  margin-bottom: 0.5em;
  padding-left: 0.75em;
  font-size: 0.85em;
}
.sub_wrap.introduce .intro_content.univ.scd > ul li .text_area p::before {
  position: absolute;
  content: "-";
  top: 0;
  left: 0;
}
.sub_wrap.introduce .intro_content.univ.scd > ul li .text_area p:last-child {
  margin-bottom: 0;
}

/* 토크콘서트 */
.sub_wrap.additional .tc_top {
  width: 100%;
}
.sub_wrap.additional .tc_top > img {
  margin: 0 auto 2em;
  width: 100%;
  max-width: 28.25em;
}
.sub_wrap.additional .tc_top .video_area {
  position: relative;
  margin-bottom: 3em;
  padding-top: 56%;
  width: 100%;
  height: 0;
  background-color: #323232;
}
.sub_wrap.additional .tc_top .video_area iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.sub_wrap.additional .tc_top .video_area img {
  position: absolute;
  top: 0;
  left: 0;
  width: auto;
  height: 100%;
}

.sub_wrap.additional .tc_cont {
  border-top: 3px solid #dcdcdc;
}

.sub_wrap.additional .tc_cont .about_tc {
  background-color: #f5f5f5;
}
.sub_wrap.additional .tc_cont.sl .about_tc {
  margin-bottom: 4em;
  padding-bottom: 0;
  background: none;
}
.sub_wrap.additional .tc_cont .about_tc .at_tit {
  padding: 0.75em 0;
  border-bottom: 2px solid #282828;
}
.sub_wrap.additional .tc_cont .about_tc .at_tit span {
  font-family: "SCDream-Medium";
  font-size: 1.1em;
}
.sub_wrap.additional .tc_cont .about_tc .at_tit p {
  display: block;
  margin-top: 0.25em;
  font-family: "SCDream-Bold";
  transform: skew(-15deg);
}
.sub_wrap.additional .tc_cont .about_tc .at_cont {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 2em;
}
.sub_wrap.additional .tc_cont .about_tc .at_cont > img {
  margin-right: 2em;
  width: 11em;
  height: 11em;
  object-fit: cover;
  border-radius: 50%;
}
.sub_wrap.additional .tc_cont .about_tc .at_cont .text_area {
  width: calc(100% - 13.875em);
}
.sub_wrap.additional .tc_cont .about_tc .at_cont .text_area span {
  display: block;
  font-size: 1.2em;
  font-weight: 400;
}
.sub_wrap.additional .tc_cont .about_tc .at_cont .text_area span small {
  font-size: 0.7em;
  font-weight: 600;
  color: #7565a5;
}
.sub_wrap.additional .tc_cont .about_tc .at_cont .text_area p {
  display: block;
  padding: 2em 0 1em;
}
.sub_wrap.additional .tc_cont .about_tc .at_cont .text_area ul {
  margin-top: 0.75em;
}
.sub_wrap.additional .tc_cont .about_tc .at_cont .text_area ul li {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 0.25em;
}
.sub_wrap.additional .tc_cont .about_tc .at_cont .text_area ul li p {
  padding: 0;
  font-size: 0.85em;
}
.sub_wrap.additional
  .tc_cont
  .about_tc
  .at_cont
  .text_area
  ul
  li
  p:first-child {
  margin-right: 0.25em;
}
.sub_wrap.additional .tc_cont ul.tc_list {
  margin-top: 1em;
  width: 100%;
}
.sub_wrap.additional .tc_cont ul.tc_list > li {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 1em;
  padding-bottom: 2em;
  border-bottom: 1px solid #646464;
}
.sub_wrap.additional .tc_cont ul.tc_list > li .tit_area {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-bottom: 1em;
  padding-bottom: 1em;
  border-bottom: 2px solid #646464;
}
.sub_wrap.additional .tc_cont ul.tc_list > li .tit_area span {
  font-family: "SCDream-Medium";
  font-size: 1.1em;
}
.sub_wrap.additional .tc_cont ul.tc_list > li .at_cont {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 2em;
  padding: 2em;
  width: 100%;
  border-bottom: 1px solid #969696;
}

.sub_wrap.additional .tc_cont ul.tc_list > li .at_cont > img {
  margin-right: 2em;
  width: 11em;
}
.sub_wrap.additional .tc_cont ul.tc_list > li .at_cont .text_area {
  width: calc(100% - 13.875em);
}
.sub_wrap.additional .tc_cont ul.tc_list > li .at_cont .text_area span {
  display: block;
  font-size: 1.2em;
  font-weight: 400;
}
.sub_wrap.additional .tc_cont ul.tc_list > li .at_cont .text_area span small {
  font-size: 0.7em;
  font-weight: 600;
  color: #7565a5;
}
.sub_wrap.additional .tc_cont ul.tc_list > li .at_cont .text_area p {
  display: block;
  padding: 2em 0 1em;
}

.sub_wrap.additional .tc_cont ul.tc_list > li .at_cont .text_area ul {
  margin-top: 0.75em;
}
.sub_wrap.additional .tc_cont ul.tc_list > li .at_cont .text_area ul li {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 0.25em;
}
.sub_wrap.additional .tc_cont ul.tc_list > li .at_cont .text_area ul li p {
  padding: 0;
  font-size: 0.85em;
}
.sub_wrap.additional
  .tc_cont
  ul.tc_list
  > li
  .at_cont
  .text_area
  ul
  li
  p:first-child {
  margin-right: 0.25em;
}
.sub_wrap.additional .tc_cont ul.tc_list > li > p {
  font-size: 1.1rem;
  line-height: 2em;
}

.sub_wrap.additional .tc_cont ul.tc_list > li ul {
  margin-top: 0.5em;
}
.sub_wrap.additional .tc_cont ul.tc_list > li ul li {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 0.75em;
}
.sub_wrap.additional .tc_cont ul.tc_list > li ul li * {
  display: table;
}
.sub_wrap.additional .tc_cont ul.tc_list > li ul li span {
  width: 2.5em;
  font-size: 1.1em;
  font-weight: 600;
}
.sub_wrap.additional .tc_cont ul.tc_list > li ul li p {
  font-size: 0.9em;
}
.sub_wrap.additional .tc_cont ul.tc_list > li ul li p small {
  font-weight: 500;
}

.sub_wrap.additional .tc_cont ul.tc_list > li .btn_area {
  position: absolute;
  top: 0;
  right: 0;
}
.sub_wrap.additional .tc_cont ul.tc_list > li .btn_area a {
  padding: 0.25em 1em;
  font-size: 1em;
}
.sub_wrap.additional .tc_cont ul.tc_list > li .text ul.panel {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  padding-top: 1em;
  border-top: 1px solid #dcdcdc;
}
.sub_wrap.additional .tc_cont ul.tc_list > li .text ul.panel li {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 1em 1em 0;
  padding: 0.75em;
  width: calc((100% - 1em) / 2);
  border: 1px solid #dcdcdc;
}
.sub_wrap.additional .tc_cont ul.tc_list > li .text ul.panel li:nth-child(2n) {
  margin-right: 0;
}
.sub_wrap.additional .tc_cont ul.tc_list > li .text ul.panel li .img_area {
  margin-right: 0.75em;
  width: 7em;
}
.sub_wrap.additional .tc_cont ul.tc_list > li .text ul.panel li .text_area {
  width: calc(100% - 7.75em);
}
.sub_wrap.additional
  .tc_cont
  ul.tc_list
  > li
  .text
  ul.panel
  li
  .text_area
  span {
  width: 100%;
  font-size: 0.9em;
}
.sub_wrap.additional .tc_cont ul.tc_list > li .text ul.panel li .text_area p {
  font-size: 0.8em;
}
/* univ lecture */
.sub_wrap.additional .ul_list li {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 2em;
  padding: 0;
  background-color: #f5f5f5;
}
.sub_wrap.additional .ul_list li .text_area .tit_area {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-bottom: 1em;
  padding-bottom: 1em;
  border-bottom: 2px solid #646464;
}
.sub_wrap.additional .ul_list li .text_area .tit_area span {
  font-family: "SCDream-Medium";
  font-size: 1.1em;
}
.sub_wrap.additional .ul_list li .text_area {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  padding: 2em;
  width: 100%;
}
.sub_wrap.additional .ul_list li .text_area > * {
  display: table;
}
.sub_wrap.additional .ul_list li .text_area .img_area {
  margin-right: 1.5em;
  width: 11.5em;
}
.sub_wrap.additional .ul_list li .text_area .type {
  margin-bottom: 0.5em;
  padding: 0.25em 1em;
  font-size: 1.1rem;
  color: #04bad2;
  border: 1px solid #04bad2;
  border-radius: 5em;
}
.sub_wrap.additional .ul_list li .text_area .ul_title {
  font-size: 1.25em;
  font-weight: 600;
}
.sub_wrap.additional .ul_list li .text_area .info {
  width: calc(100% - 13em);
}
.sub_wrap.additional .ul_list li .text_area .info p {
  font-size: 0.85em;
}
.sub_wrap.additional .ul_list li .text_area .info p:first-child {
  margin-bottom: 0.5em;
}
.sub_wrap.additional .ul_list li .text_area .info p b {
  font-size: 1.2em;
  letter-spacing: 0.25em;
}
.sub_wrap.additional .ul_list li .text_area .info p.mail {
  margin-top: 1em;
}
.sub_wrap.additional .ul_list li .video_area {
  position: relative;
  padding-top: 22%;
  width: 45%;
  height: 100%;
  background-color: #323232;
}
.sub_wrap.additional .ul_list li .video_area img,
.sub_wrap.additional .ul_list li .video_area iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* univ current */
.map_tit {
  display: table;
  margin: 4em auto 2em;
  font-family: "SCDream-Bold";
  text-align: center;
  font-size: 1.5em;
  color: #646464;
}
.map_area {
  position: relative;
  padding: 2em 0;
  background-color: #f7f7f7;
}
.map_area img {
  margin: 0 auto;
  padding: 0 0.75em;
  width: 100%;
  max-width: 60.35em;
}

.univ_tableArea .map_tit {
  margin-bottom: 1em;
}
.map_tit small {
  display: block;
  font-family: "SCDream-Regular";
  font-size: 0.65em;
}
.mapList_area {
  margin-bottom: 5em;
}
.mapList_area .tabs_area {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.mapList_area .tabs_area .map_tabs {
  position: relative;
  margin-right: 2em;
  width: 35.625em;
  height: 33.25em;
  background: url("/src/asset/img/sub/bg_map.png") no-repeat center;
  background-size: cover;
}
.mapList_area .tabs_area .map_tabs li {
  position: absolute;
  opacity: 0;
  transition: all ease-in-out 0.3s;
}
.mapList_area .tabs_area .map_tabs li.location1 {
  top: 0;
  left: 0;
  width: 18.3125em;
  z-index: 7;
}
.mapList_area .tabs_area .map_tabs li.location2 {
  top: 1.1%;
  right: 16.9%;
  width: 16.4375em;
  z-index: 6;
}
.mapList_area .tabs_area .map_tabs li.location3 {
  top: 41%;
  left: 4.8%;
  width: 16em;
}
.mapList_area .tabs_area .map_tabs li.location4 {
  top: 39.1%;
  right: 0;
  width: 19.5em;
  z-index: 3;
}
.mapList_area .tabs_area .map_tabs li.location5 {
  bottom: 6.1%;
  left: 2.1%;
  width: 17.5625em;
  z-index: 4;
}
.mapList_area .tabs_area .map_tabs li.location6 {
  bottom: 17.35%;
  right: 4.9%;
  width: 22.5625em;
  z-index: 2;
}
.mapList_area .tabs_area .map_tabs li.location7 {
  bottom: 0;
  left: 42.1%;
  width: 9.5em;
}
.mapList_area .tabs_area .map_tabs li.current {
  opacity: 1;
}

.mapList_area .tabs_area .tab-container {
  width: calc(100% - 37.625em);
}
.mapList_area .tabs_area .tab-container .text_tabs {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid #969696;
}
.mapList_area .tabs_area .tab-container .text_tabs li {
  margin-bottom: -1px;
  width: calc(100% / 7);
  border-radius: 0.5em 0.5em 0 0;
}
.mapList_area .tabs_area .tab-container .text_tabs li span {
  display: block;
  padding: 0.25em;
  font-size: 1.1rem;
  text-align: center;
}
.mapList_area .tabs_area .tab-container .text_tabs li.current span {
  color: #fff;
}
.mapList_area .tabs_area .tab-container .text_tabs li.location1.current {
  background-color: #e6cca9;
}
.mapList_area .tabs_area .tab-container .text_tabs li.location2.current {
  background-color: #533535;
}
.mapList_area .tabs_area .tab-container .text_tabs li.location3.current {
  background-color: #ae4ccf;
}
.mapList_area .tabs_area .tab-container .text_tabs li.location4.current {
  background-color: #ff6d6d;
}
.mapList_area .tabs_area .tab-container .text_tabs li.location5.current {
  background-color: #142f43;
}
.mapList_area .tabs_area .tab-container .text_tabs li.location6.current {
  background-color: #ffab4c;
}
.mapList_area .tabs_area .tab-container .text_tabs li.location7.current {
  background-color: #ff5f7e;
}

.mapList_area .tabs_area .tab-container .center_list {
  display: none;
  margin-top: 2em;
}
.mapList_area .tabs_area .tab-container .center_list.current {
  display: block;
}
.mapList_area .tabs_area .tab-container .center_list li {
  margin-bottom: 1em;
}
.mapList_area .tabs_area .tab-container .center_list li * {
  display: block;
}
.mapList_area .tabs_area .tab-container .center_list li span {
  font-family: "SCDream-Medium";
  font-size: 1.75em;
}
.mapList_area .tabs_area .tab-container .center_list#tab-1 li span {
  color: #e6cca9;
}
.mapList_area .tabs_area .tab-container .center_list#tab-2 li span {
  color: #533535;
}
.mapList_area .tabs_area .tab-container .center_list#tab-3 li span {
  color: #ae4ccf;
}
.mapList_area .tabs_area .tab-container .center_list#tab-4 li span {
  color: #ff6d6d;
}
.mapList_area .tabs_area .tab-container .center_list#tab-5 li span {
  color: #142f43;
}
.mapList_area .tabs_area .tab-container .center_list#tab-6 li span {
  color: #ffab4c;
}
.mapList_area .tabs_area .tab-container .center_list#tab-7 li span {
  color: #ff5f7e;
}
.mapList_area .tabs_area .tab-container .center_list li p {
  font-family: "SCDream-Medium";
  font-size: 1.2em;
}

.graph_area ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}
.graph_area ul li {
  margin-right: 2em;
  width: calc((100% - 2em) / 2);
}
.graph_area ul li:last-child {
  margin-right: 0;
}

.univ_current {
  margin-bottom: 4em;
}
.univ_tableArea {
  padding: 0 0 4em;
  border-top: 1px solid #dcdcdc;
}

.univ_tableArea table {
  margin: 0 auto;
  padding: 0 0.75em;
  width: 100%;
  max-width: 64em;
}

.univ_tableArea table .thead {
  background-color: #001650;
}

.univ_tableArea table th,
.univ_tableArea table td {
  padding: 0.5em;
  font-size: 1em;
  text-align: center;
}
.univ_tableArea table td {
  border-right: 1px solid #dcdcdc;
  border-bottom: 1px solid #dcdcdc;
}
.univ_tableArea table td:last-child {
  border-right: none;
}
.univ_tableArea table td.udb {
  border-bottom: 1px solid #001650;
}

.univ_tableArea table .thead td {
  color: #fff;
  border: none;
}
.univ_tableArea table.new {
  background-color: #fff;
}
.tbl_caption {
  display: block;
  margin: 0.25em auto 4em;
  padding: 0 0.75em;
  width: 100%;
  max-width: 64em;
  text-align: right;
}

/* participation */
.participation_box {
  margin: 0 auto;
  padding: 3em;
  width: 100%;
  max-width: 1180px;
}

.participation_box.announce {
  padding: 0;
  background: none;
  box-shadow: none;
}
.participation_box.announce .box_area > ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
}
.participation_box.announce .box_area > ul > li {
  margin: 0 1em 1em 0;
  width: calc((100% - 1em) / 2);
}
.participation_box.announce .box_area > ul > li:nth-child(2n) {
  margin-right: 0;
}
.participation_box.announce .box_area > ul > li .box {
  padding: 1.5em 1em;
  width: 100%;
  background-color: #fff;
  border-radius: 0.4em;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}
.participation_box.announce .box_area > ul > li.single .box {
  height: 100%;
}
.participation_box.announce .box_area > ul > li.multi .box {
  margin-bottom: 1em;
}
.participation_box.announce .box_area > ul > li.multi .box:last-child {
  margin-bottom: 0;
}
.participation_box.announce .box_area > ul > li .box .box_title {
  position: relative;
  display: block;
  margin-bottom: 0.5em;
  padding-top: 0.25em;
  font-weight: 600;
}
.participation_box.announce .box_area > ul > li .box .box_title:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 1em;
  height: 2px;
  background-color: #ec6803;
}
.participation_box.announce .box_area > ul > li .box .box_content p,
.participation_box.announce .box_area > ul > li .box .box_content span {
  font-size: 0.8em;
  letter-spacing: -0.05em;
}
.participation_box.announce .box_area > ul > li .box .box_content .sns_list {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 0.25em;
}
.participation_box.announce
  .box_area
  > ul
  > li
  .box
  .box_content
  .sns_list
  li
  img,
.participation_box.announce
  .box_area
  > ul
  > li
  .box
  .box_content
  .sns_list
  li
  span {
  display: block;
  margin: 0 auto;
  text-align: center;
}
.participation_box.announce
  .box_area
  > ul
  > li
  .box
  .box_content
  .sns_list
  li
  img {
  margin-bottom: 0.25em;
}
.participation_box.announce
  .box_area
  > ul
  > li
  .box
  .box_content
  .announce_list
  li {
  position: relative;
  padding-left: 1.25em;
  font-size: 0.75em;
  letter-spacing: -0.05em;
}
.participation_box.announce
  .box_area
  > ul
  > li
  .box
  .box_content
  .announce_list
  li.dot:before {
  position: absolute;
  content: "·";
  top: 0;
  left: 0.35em;
}
.participation_box.announce
  .box_area
  > ul
  > li
  .box
  .box_content
  .announce_list
  li.asterisk {
  color: #ec6803;
}
.participation_box.announce
  .box_area
  > ul
  > li
  .box
  .box_content
  .announce_list
  li.asterisk:before {
  position: absolute;
  content: "※";
  top: 0;
  left: 0;
}
.participation_box.announce
  .box_area
  > ul
  > li
  .box
  .box_content
  .announce_list
  li.hashtag {
  color: #d3171f;
}

.participation_box.announce .box_area > ul > li .box .box_content table {
  margin-bottom: 0.5em;
  width: 100%;
}
.participation_box.announce
  .box_area
  > ul
  > li
  .box
  .box_content
  table.type
  col.type {
  width: 20%;
}
.participation_box.announce
  .box_area
  > ul
  > li
  .box
  .box_content
  table.type
  col.image {
  width: 40%;
}
.participation_box.announce
  .box_area
  > ul
  > li
  .box
  .box_content
  table.type
  col.video {
  width: 40%;
}
.participation_box.announce .box_area > ul > li .box .box_content table th,
.participation_box.announce .box_area > ul > li .box .box_content table td {
  padding: 1em 0.5em 0.75em;
  font-size: 0.7em;
  text-align: center;
}
.participation_box.announce
  .box_area
  > ul
  > li
  .box
  .box_content
  table
  thead
  th {
  color: #fff;
  background-color: #051e48;
  border-right: 1px solid #051e48;
  border-bottom: 1px solid #051e48;
}
.participation_box.announce
  .box_area
  > ul
  > li
  .box
  .box_content
  table
  thead
  th:last-child {
  border-right: none;
}
.participation_box.announce
  .box_area
  > ul
  > li
  .box
  .box_content
  table
  thead
  td {
  font-weight: 600;
  background-color: rgba(98, 33, 114, 0.1);
  border-right: 1px solid #051e48;
  border-bottom: 1px solid #051e48;
}
.participation_box.announce
  .box_area
  > ul
  > li
  .box
  .box_content
  table
  thead
  td:last-child {
  border-right: none;
}
.participation_box.announce
  .box_area
  > ul
  > li
  .box
  .box_content
  table.award
  col.type {
  width: 20%;
}
.participation_box.announce
  .box_area
  > ul
  > li
  .box
  .box_content
  table
  tbody
  td {
  border-bottom: 1px solid #051e48;
}
.participation_box.announce
  .box_area
  > ul
  > li
  .box
  .box_content
  table.award
  tbody
  td {
  border-right: 1px solid #051e48;
}
.participation_box.announce
  .box_area
  > ul
  > li
  .box
  .box_content
  table.award
  tbody
  td:last-child {
  border-right: none;
}
.participation_box.announce
  .box_area
  > ul
  > li
  .box
  .box_content
  table.type
  tbody
  td
  img {
  display: block;
  margin: 0 auto 0.25em;
  max-width: 4em;
}
.participation_box.announce
  .box_area
  > ul
  > li
  .box
  .box_content
  table.type
  tbody
  td
  span {
  font-size: 1em;
}

.participation_box.announce .box_area > ul > li .box .box_content nav .tabs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.75em;
}
.participation_box.announce
  .box_area
  > ul
  > li.caution
  .box
  .box_content
  nav
  .tabs {
  justify-content: flex-start;
}
.participation_box.announce .box_area > ul > li .box .box_content nav .tabs li {
  margin-right: 0.5em;
  width: calc((100% - 1.5em) / 4);
}
.participation_box.announce
  .box_area
  > ul
  > li.caution
  .box
  .box_content
  nav
  .tabs
  li {
  width: auto;
}
.participation_box.announce
  .box_area
  > ul
  > li
  .box
  .box_content
  nav
  .tabs
  li:last-child {
  margin-right: 0;
}
.participation_box.announce
  .box_area
  > ul
  > li
  .box
  .box_content
  nav
  .tabs
  li
  a {
  display: block;
  padding: 0.25em;
  font-size: 0.7em;
  font-weight: 600;
  text-align: center;
  color: #051e48;
  border-radius: 5em;
  border: 1px solid #051e48;
}
.participation_box.announce
  .box_area
  > ul
  > li.caution
  .box
  .box_content
  nav
  .tabs
  li
  a {
  padding: 0.25em 1em;
}
.participation_box.announce
  .box_area
  > ul
  > li
  .box
  .box_content
  nav
  .tabs
  li.tab-current
  a {
  color: #fff;
  background-color: #051e48;
}

.participation_box.announce
  .box_area
  > ul
  > li
  .box
  .box_content
  .award_area
  .content
  > section {
  display: none;
}
.participation_box.announce
  .box_area
  > ul
  > li
  .box
  .box_content
  .award_area
  .content
  > section.content-current {
  display: block;
}

.participation_box .terms_area {
  padding: 1em;
  width: 100%;
  border: 1px solid #051e48;
}
.participation_box .terms_area ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}
.participation_box .terms_area ul li {
  margin-right: 1em;
  width: calc((100% - 1em) / 2);
}
.participation_box .terms_area ul li:nth-child(2n),
.participation_box .terms_area ul li:last-child {
  margin-right: 0;
}
.input_area {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}
.input_area input[type="radio"],
.input_area input[type="checkbox"] {
  width: 1em;
  height: 1em;
}
.input_area.terms label {
  display: block;
  margin-left: 0.5em;
}
.input_area.terms a.btn {
  display: table;
  margin-left: 1em;
  padding: 0.25em 0.75em;
  font-size: 0.65em;
  color: #fff;
  background-color: #969696;
  border-radius: 5em;
}

.participate_area {
  margin-top: 4em;
  width: 100%;
}
.participate_area.result {
  margin-top: 0;
}
.participate_area .part_title {
  position: relative;
  width: 100%;
  padding-bottom: 0.5em;
}
.participate_area .part_title span {
  font-size: 1.2em;
  font-weight: 600;
  color: #051e48;
}
.participate_area .part_title p.caution {
  position: absolute;
  right: 0;
  bottom: 0.75em;
  font-size: 0.8em;
  color: #051e48;
}
.participate_area ul.input_list {
  border-top: 2px solid #051e48;
}
.participate_area ul.input_list li {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  padding: 2.5em 0;
  border-bottom: 1px solid #dcdcdc;
}
.participate_area ul.input_list li:last-child {
  border-color: #051e48;
}
.participate_area ul.input_list li > span {
  position: relative;
  display: block;
  margin-right: 1em;
  padding-top: 0.25em;
  width: 9em;
  font-weight: 600;
  color: #323232;
}
.participate_area.qna ul.input_list li > span {
  border: none;
  padding: 0.25em 0;
}
.participate_area ul.input_list li > span:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 1.25em;
  height: 2px;
  background-color: #051e48;
}
.participate_area ul.input_list li .input_area {
  width: calc(100% - 10em);
}
.participate_area ul.input_list li .input_area.member p {
  display: block;
  width: 100%;
}
.participate_area ul.input_list li .input_area p.caution {
  display: table;
  margin-top: 1em;
  font-size: 0.8em;
}
.participate_area ul.input_list li .input_area.type {
  align-items: stretch;
}
.participate_area ul.input_list li .input_area.type .type_list {
  margin-right: 1em;
  width: calc((100% - 3em) / 4);
}
.participate_area ul.input_list li .input_area.type .type_list:last-child {
  margin-right: 0;
}
.participate_area ul.input_list li .input_area.type .type_list span {
  display: block;
  margin-bottom: 0.5em;
  padding: 0.5em;
  width: 100%;
  font-size: 0.8em;
  color: #fff;
  text-align: center;
  background-color: #051e48;
}
.participate_area ul.input_list li .input_area.type .type_list label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 auto;
  width: 5em;
  font-size: 0.8em;
  line-height: 2em;
}
.participate_area ul.input_list li .input_area.type .type_list label input {
  margin-right: 0.25em;
}
.participate_area ul.input_list li .input_area input[type="text"],
.participate_area ul.input_list li .input_area input[type="password"],
.participate_area ul.input_list li .input_area input[type="file"],
.participate_area ul.input_list li .input_area select,
.participate_area ul.input_list li .input_area label,
.participate_area ul.input_list li .input_area a,
.participate_area ul.input_list li .input_area button,
.participate_area ul.input_list li .input_area textarea {
  font-size: 0.8em;
}
.participate_area ul.input_list li .input_area input[type="password"] {
  margin-bottom: 0.5em;
}
.participate_area
  ul.input_list
  li
  .input_area
  input[type="password"]:last-child {
  margin-bottom: 0;
}
.participate_area ul.input_list li .input_area .part_inp {
  padding: 0.5em;
  border: 1px solid #dcdcdc;
}
.participate_area ul.input_list li .input_area input[type="checkbox"] {
  display: table;
  margin: 0 0.5em 0 1em;
}
.participate_area ul.input_list li .input_area .part_inp#address01 {
  margin: 0.5em 0;
}
.participate_area ul.input_list li .input_area .phone {
  margin-left: 1em;
  width: 9em;
}
.participate_area ul.input_list li .input_area .phone:first-child {
  margin-left: 0;
}
.participate_area ul.input_list li .input_area .gender {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 9em;
}
.participate_area ul.input_list li .input_area label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 1.5em;
}
.participate_area ul.input_list li .input_area label:last-child {
  margin-right: 0;
}
.participate_area ul.input_list li .input_area label input {
  margin-right: 0.25em;
}
.participate_area ul.input_list li .input_area .btn_part {
  margin-left: 1.25em !important;
  color: #fff;
  background-color: #051e48;
  border: none;
}

.participate_area ul.input_list li .input_area .period {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
}
.participate_area ul.input_list li .input_area .period label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 1em;
}
.participate_area ul.input_list li .input_area .period label.calendar {
  margin-right: 0.5em;
  padding: 0 0.75em 0 0;
  background-color: #646464;
}
.participate_area
  ul.input_list
  li
  .input_area
  .period
  label.calendar:last-child {
  margin-right: 0;
}
.participate_area ul.input_list li .input_area .period label.calendar input {
  margin-right: 0;
  padding: 0.75em 0.5em;
  height: 100%;
}
.participate_area ul.input_list li .input_area .period label.calendar img {
  margin-left: 0.75em;
}
.participate_area ul.input_list li .input_area .period > span {
  display: table;
  margin-right: 0.5em;
  font-size: 0.8em;
  line-height: 2.8em;
}

.input_fields_wrap .etc {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin-top: 0.5em;
}
.input_fields_wrap .etc input {
  width: calc(100% - 3.1em) !important;
}
.input_fields_wrap .etc:first-child {
  margin: 0;
}
.input_fields_wrap .etc:first-child input {
  width: 100% !important;
}

.input_fields_wrap button {
  position: relative;
  margin-left: 0.5em;
  width: 2.6em;
  height: 2.6em;
  border: 1px solid #dcdcdc;
  background: none;
}
.input_fields_wrap button span {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1em;
  height: 1px;
  transform: translate(-50%, -50%);
}
.input_fields_wrap button.add_field span {
  background-color: #1c92eb;
}
.input_fields_wrap button.add_field span:nth-child(2) {
  position: absolute;
  width: 1px;
  height: 1em;
}
.input_fields_wrap button.remove_field span {
  width: 1em;
  height: 1px;
  background-color: #ff7800;
}

.participate_area ul.input_list li ul.etc {
  margin-top: 1em;
}
.participate_area ul.input_list li ul.etc li {
  display: block;
  padding: 0.25em 0;
  font-size: 0.683em;
  border: none;
}
.participate_area ul.input_list li ul.etc li span {
  display: block;
  padding: 0 0 0 1.25em;
  width: 100%;
}
.participate_area ul.input_list li ul.etc li span:before {
  position: absolute;
  content: "※";
  top: 50%;
  left: 0;
  width: auto;
  height: auto;
  background: none;
  transform: translateY(-40%);
}
.participate_area ul.input_list li ul.etc li p {
  padding-left: 1.25em;
}

.participate_area#member_list1,
.participate_area#member_list2 {
  display: none;
}

.caution_area {
  margin-top: 2em;
}
.caution_area span {
  display: block;
  margin-bottom: 0.5em;
  padding: 0.25em 0.5em;
  font-size: 1em;
  color: #fff;
  background-color: #051e48;
}
.caution_area p {
  display: block;
  padding: 0.2em 0.5em;
  font-size: 0.8em;
}

.container.participation > .btn_area {
  margin-top: 1em;
}
.container.participation > .btn_area .btn {
  display: table;
  margin: 0 0.5em;
  padding: 0.5em 3em;
  text-align: center;
  border-radius: 5em;
  border: 1px solid #051e48;
}
.container.participation > .btn_area .btn.apply {
  color: #fff;
  background-color: #051e48;
}

.container.participation .guide {
  margin-top: 1em;
  padding: 1em;
  width: 100%;
  border: 1px solid #051e48;
  background-color: #f5f5f5;
}
.container.participation .guide p {
  display: block;
  font-size: 0.8em;
  text-align: center;
}
.container.participation .guide span {
  display: block;
  font-size: 0.8em;
  text-align: center;
  font-weight: 600;
  color: #051e48;
}

.participation_box .apply_complete span,
.participation_box .apply_complete img,
.participation_box .apply_complete p {
  display: block;
  margin: 0 auto;
  text-align: center;
}
.participation_box .apply_complete span.hgnrB {
  margin-bottom: 1em;
  font-size: 1.35em;
  color: #051e48;
}
.participation_box .apply_complete span.success {
  margin-bottom: 1em;
  font-size: 1.25em;
  font-weight: 600;
}
.participation_box .apply_complete img {
  margin-bottom: 2em;
}
.participation_box .apply_complete .cscenter {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 2em auto 0;
  padding: 1em;
  width: 100%;
  max-width: 39em;
  border: 1px solid #051e48;
  border-radius: 5px;
}
.participation_box .apply_complete .cscenter span {
  margin-bottom: 0.5em;
  padding-bottom: 0.5em;
  width: 100%;
  font-weight: 600;
  color: #051e48;
  border-bottom: 1px solid #051e48;
}
.participation_box .apply_complete .cscenter p {
  display: table;
  margin: 0 1em;
  width: auto;
  font-size: 0.8em;
}

.participate_area.result .reception_area {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em 0;
  width: 100%;
  border-top: 1px solid #051e48;
  border-bottom: 1px solid #051e48;
}
.participate_area.result .part_title {
  margin-top: 1.5em;
}
.participate_area.result .reception_area span,
.participate_area.result .reception_area a {
  font-size: 0.75em;
}
.participate_area.result .reception_area a {
  display: table;
  padding: 0.25em 1em 0.35em;
  color: #fff;
  background-color: #051e48;
  border-radius: 5em;
}
.participate_area.result ul.input_list li {
  padding: 1.5em 0;
}

/* about */
.about_area {
  margin: 0 auto;
  width: 100%;
}

.about_area .about_top,
.about_area .about_mid {
  position: relative;
  padding: 3em 0 5em;
  width: 100%;
}
.about_area .about_top ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  margin: 0 auto;
  padding: 0 0.5em;
  width: 100%;
  max-width: 90em;
}
.about_area .about_top ul li {
  position: relative;
  width: calc((100% / 3) + 2em);
}
.about_area .about_top ul li:nth-child(2),
.about_area .about_top ul li:last-child {
  margin-left: -3em;
}
.about_area .about_top ul li * {
  display: block;
  margin: 0 auto;
  text-align: center;
}
.about_area .about_top ul li img {
  width: 100%;
  max-width: 7em;
}
.about_area .about_top ul li span {
  margin: 1em auto;
  padding: 0.75em;
  width: 100%;
  font-size: 1.2em;
  font-weight: 600;
  color: #fff;
  border-radius: 5em;
}
.about_area .about_top ul li:first-child span {
  background-color: rgba(28, 146, 235, 0.8);
}
.about_area .about_top ul li:nth-child(2) span {
  background-color: rgba(28, 168, 209, 0.8);
}
.about_area .about_top ul li:last-child span {
  background-color: rgba(28, 185, 188, 0.8);
}
.about_area .about_top ul li p {
  padding: 0 1em;
  font-size: 0.8em;
  letter-spacing: -0.03em;
}
.about_area .about_mid {
  background-color: #f5f5f5;
}

.about_area .about_mid .flowchart {
  margin: 0 auto;
  padding: 0 0.5em;
  width: 100%;
  max-width: 90em;
}
.about_area .about_mid .flowchart > div {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  margin-bottom: 1em;
}
.about_area .about_mid .flowchart .fc_title {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1em;
  width: 4em;
  color: #fff;
  background-color: #1c92eb;
  border-radius: 0 1em 0 1em;
}
.about_area .about_mid .flowchart .fc_cont {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: calc(100% - 5em);
}
.about_area .about_mid .flowchart .fc_cont .fc_tit01 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1em;
  padding: 0.75em;
  width: 100%;
  color: #fff;
  background-color: #323232;
  border-radius: 0 1em 0 1em;
}
.about_area .about_mid .flowchart .fc_cont > ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  width: 100%;
}

.about_area .about_mid .flowchart .fc01 .fc_cont > ul > li {
  position: relative;
  margin-right: 1em;
  padding: 4em 1em 1em;
  width: calc((100% - 2em) / 4);
  background-color: #fff;
  border-radius: 0 1em 0 1em;
  box-shadow: 0.1em 0.1em 0.3em rgba(0, 0, 0, 0.1);
}
.about_area .about_mid .flowchart .fc01 .fc_cont ul li:last-child {
  margin-right: 0;
}
.about_area .about_mid .flowchart .fc01 .fc_cont ul li > span {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1em;
  padding: 0.75em;
  width: 100%;
  color: #fff;
  border-radius: 0 1em 0 1em;
}
.about_area .about_mid .flowchart .fc01 .fc_cont ul li.box01 > span {
  background-color: #1c98e3;
}
.about_area .about_mid .flowchart .fc01 .fc_cont ul li.box02 > span {
  background-color: #1ca9cf;
}
.about_area .about_mid .flowchart .fc01 .fc_cont ul li.box03 > span {
  background-color: #1cb7be;
}
.about_area .about_mid .flowchart .fc01 .fc_cont ul li.box01 > div {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em;
  border-bottom: 1px solid #dcdcdc;
}
.about_area .about_mid .flowchart .fc01 .fc_cont ul li.box01 > div:last-child {
  border: none;
}
.about_area .about_mid .flowchart .fc01 .fc_cont ul li.box01 > div p {
  margin-left: 1em;
  font-size: 0.9em;
}
.about_area .about_mid .flowchart .fc01 .fc_cont ul li.box02 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: calc((100% - 2em) / 4 * 2);
}
.about_area .about_mid .flowchart .fc01 .fc_cont ul li.box02 > div {
  width: calc(100% / 3);
}
.about_area .about_mid .flowchart .fc01 .fc_cont ul li.box02 > div img,
.about_area .about_mid .flowchart .fc01 .fc_cont ul li.box02 > div p {
  display: block;
  margin: 0 auto;
}
.about_area .about_mid .flowchart .fc01 .fc_cont ul li.box02 > div p {
  font-size: 0.9em;
  text-align: center;
}
.about_area .about_mid .flowchart .fc01 .fc_cont ul li.box03 .box_cont span {
  position: relative;
  display: block;
  padding: 0.5em;
  font-size: 0.9em;
  text-align: center;
  color: #fff;
}
.about_area
  .about_mid
  .flowchart
  .fc01
  .fc_cont
  ul
  li.box03
  .box_cont
  span.bt01 {
  line-height: 1.3em;
  background-color: #1c92eb;
}
.about_area
  .about_mid
  .flowchart
  .fc01
  .fc_cont
  ul
  li.box03
  .box_cont
  span.bt01:after {
  position: absolute;
  content: "";
  bottom: -1.667em;
  left: 50%;
  width: 2.22em;
  height: 1.667em;
  /* background: url("/src/asset/img/sub/arrow_flowchart.png") no-repeat center; */
  background-size: cover;
  transform: translateX(-50%);
}
.about_area
  .about_mid
  .flowchart
  .fc01
  .fc_cont
  ul
  li.box03
  .box_cont
  span.bt02 {
  background-color: #1cb7be;
  border-radius: 0 1em 0 1em;
}

.about_area .about_mid .flowchart .fc01 .fc_cont ul li.box03 .box_cont ul {
  margin: 1em 0;
  padding: 1em;
  background-color: #dcdcdc;
}
.about_area .about_mid .flowchart .fc01 .fc_cont ul li.box03 .box_cont ul li {
  display: block;
  margin-bottom: 0.25em;
  padding: 0.25em;
  text-align: center;
  font-size: 0.8em;
  background-color: #f5f5f5;
}
.about_area
  .about_mid
  .flowchart
  .fc01
  .fc_cont
  ul
  li.box03
  .box_cont
  ul
  li:last-child {
  margin-bottom: 0;
}

.about_area .about_mid .flowchart .fc02 .fc_cont > ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}
.about_area .about_mid .flowchart .fc02 .fc_cont > ul > li {
  margin-right: 1em;
  width: calc((100% - 1em) / 2);
}
.about_area .about_mid .flowchart .fc02 .fc_cont > ul > li:last-child {
  margin-right: 0;
}
.about_area .about_mid .flowchart .fc02 .fc_cont > ul > li .fc_list {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  margin-bottom: 1em;
  background-color: #fff;
  border-radius: 0 1em 0 1em;
  border: 1px solid;
}
.about_area
  .about_mid
  .flowchart
  .fc02
  .fc_cont
  > ul
  > li:first-child
  .fc_list {
  border-color: #1c98e3;
}
.about_area .about_mid .flowchart .fc02 .fc_cont > ul > li:last-child .fc_list {
  border-color: #1cb7be;
}
.about_area .about_mid .flowchart .fc02 .fc_cont > ul > li .fc_list p {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em;
  width: calc(100% / 4);
  font-size: 0.8em;
  text-align: center;
  border-right: 1px solid;
}
.about_area
  .about_mid
  .flowchart
  .fc02
  .fc_cont
  > ul
  > li
  .fc_list
  p:last-child {
  border: none;
}
.about_area
  .about_mid
  .flowchart
  .fc02
  .fc_cont
  > ul
  > li:first-child
  .fc_list
  p {
  border-color: #1c98e3;
}
.about_area
  .about_mid
  .flowchart
  .fc02
  .fc_cont
  > ul
  > li:last-child
  .fc_list
  p {
  border-color: #1cb7be;
}
.about_area .about_mid .flowchart .fc02 .fc_cont > ul > li span {
  position: relative;
  display: block;
  padding: 0.5em;
  font-size: 0.9em;
  text-align: center;
  color: #fff;
  border-radius: 0 1em 0 1em;
}
.about_area .about_mid .flowchart .fc02 .fc_cont > ul > li:first-child span {
  background-color: #1c98e3;
}
.about_area .about_mid .flowchart .fc02 .fc_cont > ul > li:last-child span {
  background-color: #1cb7be;
}

/* guide */
.guide_area {
  position: relative;
  width: 100%;
}
.guide_area ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  margin: 0 auto;
  padding: 0 0.5em;
  width: 100%;
  max-width: 90em;
}
.guide_area ul li {
  margin: 0;
  width: calc((100% - 14em) / 5);
}
.guide_area ul li.arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3.5em;
}
.guide_area ul li span.gl_tit {
  display: table;
  margin: 0 auto;
  padding: 0.5em 2em;
  font-size: 0.8em;
  color: #fff;
  border-radius: 0 1em 0 1em;
}
.guide_area ul li:first-child span.gl_tit {
  background-color: #1c92eb;
}
.guide_area ul li:nth-child(3) span.gl_tit {
  background-color: #1c9cdf;
}
.guide_area ul li:nth-child(5) span.gl_tit {
  background-color: #1ca5d3;
}
.guide_area ul li:nth-child(7) span.gl_tit {
  background-color: #1cafc8;
}
.guide_area ul li:last-child span.gl_tit {
  background-color: #1cb9bc;
}
.guide_area ul li .gl_cont {
  margin-top: -1.1em;
  padding: 2.1em 1em 1em;
  border-radius: 0 1em 0 1em;
  border: 1px dashed;
}
.guide_area ul li:first-child .gl_cont {
  border-color: #1c92eb;
}
.guide_area ul li:nth-child(3) .gl_cont {
  border-color: #1c9cdf;
}
.guide_area ul li:nth-child(5) .gl_cont {
  border-color: #1ca5d3;
}
.guide_area ul li:nth-child(7) .gl_cont {
  border-color: #1cafc8;
}
.guide_area ul li:last-child .gl_cont {
  border-color: #1cb9bc;
}
.guide_area ul li .gl_cont * {
  display: table;
  margin: 0 auto;
  text-align: center;
  letter-spacing: -0.05em;
}
.guide_area ul li .gl_cont img {
  margin-bottom: 1em;
  width: 4em;
}
.guide_area ul li .gl_cont span {
  font-size: 0.85em;
}
.guide_area ul li .gl_cont p {
  margin-bottom: 0.175em;
  font-size: 0.75em;
}
.guide_area ul li a {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.5em auto 0;
  padding: 0.5em 2em;
  width: 100%;
  font-size: 0.8em;
  text-align: center;
  color: #fff;
  border-radius: 0 1em 0 1em;
}
.guide_area ul li a:before {
  position: absolute;
  content: "";
  top: -0.5em;
  left: 50%;
  width: 1px;
  height: 0.5em;
  border-left: 1px dashed;
}
.guide_area ul li:nth-child(3) a:before {
  border-color: #1c9cdf;
}
.guide_area ul li:last-child a:before {
  border-color: #1cb9bc;
}
.guide_area ul li a img {
  margin-left: 0.5em;
}
.guide_area ul li:nth-child(3) a {
  background-color: #1c9cdf;
}
.guide_area ul li:last-child a {
  background-color: #1cb9bc;
}

/* event */
.event_area {
  margin-top: 2em;
}

.event_area img {
  margin: 0 auto;
  width: 100%;
  max-width: 43.75em;
  height: auto;
}

/* search */
.search_area {
  margin: 2em auto;
  padding: 0;
  width: 100%;
  max-width: 90em;
}
.search_area.booth {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1em;
}
.search_area.booth .boothSortWrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.search_area.booth .boothSortWrap .boothSort {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.search_area.booth .boothSortWrap .boothSort .input_area input[type="radio"] {
  display: none;
}
.search_area.booth .boothSortWrap .boothSort .input_area label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0.5em;
  padding: 0.5em 1em;
  font-weight: 600;
  border: 1px solid #969696;
  border-radius: 5em;
  transition: all ease-in-out 0.3s;
  box-sizing: border-box;
}
.search_area .boothSortWrap .boothSort .input_area label span {
  position: relative;
  display: table;
  margin-right: 0.5em;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  border: 1px solid #969696;
  transition: all ease-in-out 0.3s;
}
.search_area
  .boothSortWrap
  .boothSort
  .input_area
  input[type="radio"]:checked
  + label
  span {
  background-color: #24cfe6;
  border-color: #24cfe6;
}
.search_area.booth
  .boothSortWrap
  .boothSort
  .input_area
  input[type="radio"]:checked
  + label {
  padding: calc(0.5em - 2px) calc(1em - 2px);
  border-width: 3px;
  border-color: #24cfe6;
}

.search_area .input_area {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: stretch;
}
.search_area .input_area > div {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  width: 85%;
}
.search_area .input_area .s1 {
  margin-bottom: 0.5em;
}
.search_area .input_area .period {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
}
.search_area .input_area label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 1em;
}
.search_area .input_area label.calendar {
  margin-right: 0.5em;
  padding: 0 0.75em 0 0;
  background-color: #646464;
}
.search_area .input_area label.calendar:last-child {
  margin-right: 0;
}
.search_area .input_area label.calendar input {
  height: 100%;
}
.search_area .input_area label.calendar img {
  margin-left: 0.75em;
}
.search_area .input_area span {
  display: table;
  margin-right: 0.5em;
  font-size: 0.8em;
}
.search_area .input_area .period > span {
  line-height: 2.8em;
}
.search_area .input_area input,
.search_area .input_area select {
  margin-right: 1em;
  padding: 0.5em;
  min-width: 7em;
  font-size: 0.9rem;
  border: 1px solid #dcdcdc;
  border-radius: 0.5em;
}
.search_area.inquiry .input_area input {
  width: auto;
}
.search_area .input_area button {
  padding: 0.5em 2em;
  color: #fff;
  border: none;
  background-color: #8c7eb8;
  border-radius: 0.5em;
}

/* board */
.board_wrap,
.board_area {
  margin: 0 auto;
  width: 100%;
}
.board_area > ul {
  width: 100%;
  border-top: 2px solid #323232;
}
.board_area > ul > li {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  padding: 1.5em 0;
  border-bottom: 1px solid #dcdcdc;
}
.board_area > ul > li.faq {
  padding: 1.5em 0.75em;
}
.board_area > ul > li.thead,
.board_area > ul > li:last-child {
  border-color: #323232;
}
.board_area ul li a {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
.board_area ul li .number,
.board_area ul li .view {
  width: 10%;
  text-align: center;
}
.board_area ul li .title {
  width: 65%;
  text-align: center;
}
.board_area ul li .td.title span {
  display: block;
  width: 100%;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.board_area ul li .date {
  width: 15%;
  text-align: center;
}
.board_area ul li a .brd_title {
  font-weight: 600;
}
.board_area.product ul li a .brd_title {
  margin-bottom: 0.5em;
  width: 100%;
}
.board_area.product ul li a .img_area {
  margin-right: 2em;
  width: 14em;
}
.board_area.product ul li a .text_area {
  width: calc(100% - 16em);
}
.board_area.product ul li a .text_area ul li,
.board_area.view.product ul li .product_detail {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  margin-bottom: 0.5em;
}
.board_area.view.product ul li .product_detail {
  margin: 0;
}
.board_area.product ul li a .text_area ul li:last-child {
  margin-bottom: 0;
}

.board_area.product ul li a .text_area ul li span {
  position: relative;
  width: 9em;
  font-size: 0.8em;
  font-weight: 600;
}
.board_area.product ul li a .text_area ul li span:before {
  position: relative;
  content: "-";
  margin-right: 0.5em;
}
.board_area.product ul li a .text_area ul li .text_list {
  width: calc(100% - 9em);
}
.board_area.product ul li a .text_area ul li .text_list p {
  font-size: 0.8em;
}
.board_area ul li a .brd_title span {
  display: block;
  width: 100%;
  font-size: 1.1em;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.board_area ul li a .brd_title span.contents_lock {
  width: 4.5em;
  color: #051e48;
}
.board_area ul li a .brd_info {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: 1em;
  width: 20em;
}
.board_area ul li a .brd_info p {
  display: block;
  width: 6em;
  font-size: 0.8em;
  font-weight: 300;
  margin-right: 1em;
  text-align: center;
}
.board_area ul li a .brd_info p.date {
  margin-right: 0;
}
.board_area ul li ul.submenu {
  display: none;
  margin-top: 1em;
  width: 100%;
}
.board_area ul li ul.submenu li {
  padding: 1.75em 1em;
  background-color: #f5f5f5;
  border-bottom: 1px solid #dcdcdc;
}
.board_area ul li ul.submenu li p {
  font-size: 0.75em;
}

.board_area > ul > li.th span,
.board_area > ul > li.td span {
  font-size: 0.8em;
}
.board_area > ul > li.th span.center,
.board_area > ul > li.td span.center {
  text-align: center;
}
.board_area > ul > li.th span {
  font-weight: 600;
}
.board_area > ul > li.td .title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: 500;
  font-size: 0.8em;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.board_area > ul > li.td .title img,
.board_area > ul > li.td .title span.cate {
  display: block;
  margin-right: 0.5em;
}
.board_area > ul > li.td .title span.cate {
  font-size: 1em;
  color: #1c92eb;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 2em;
  width: 100%;
}

.pagination a {
  margin: 0 0.5em;
  width: 2em;
  line-height: 2em;
  color: #969696;
  text-align: center;
  text-decoration: none;
  border-radius: 50%;
}

.pagination a.active {
  background-color: #707070;
  color: white;
}

.pagination a:hover:not(.active) {
  color: #323232;
  background-color: #f5f5f5;
}
.thumb_list ul {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin-top: 2em;
  width: 100%;
}

.thumb_list ul li {
  margin-right: 0.75em;
  width: calc((100% - 0.75em) / 2);
}
.thumb_list ul li:last-child {
  margin-right: 0;
}

/* board view */
.board_area.view li {
  border-bottom: 1px solid #323232;
}
.board_area.view li.view_top P.title {
  display: block;
  width: 100%;
  font-size: 1.2rem;
  text-align: left;
  font-weight: 600;
}
.board_area.view li.view_top P.title span.number {
  margin-right: 0.25em;
}
.board_area.view li.view_top .type,
.board_area.view li.view_top .date,
.board_area.view li.view_top .view {
  position: relative;
  margin: 1em 1em 0 0;
  padding-right: 1em;
  width: auto;
  font-size: 0.9rem;
}
.board_area.view li.view_top .type:after,
.board_area.view li.view_top .date:after {
  position: absolute;
  content: "";
  top: 50%;
  right: 0;
  width: 1px;
  height: 0.8em;
  background-color: #969696;
  transform: translateY(-40%);
}

.board_area.view li.view_cont {
  padding: 1em 0;
}

.board_area.view li.view_cont p {
  font-size: 0.8em;
}

.board_area.view .view_file {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1em;
  width: 100%;
}

.board_area.view .view_file * {
  display: table;
  width: auto;
}
.board_area.view .view_file span {
  width: 5em;
  font-weight: 600;
}
.board_area.view .view_file a {
  position: relative;
  padding: 1em 1em 1em 2.5em;
  width: 100%;
  font-size: 0.8em;
  background-color: #f5f5f5;
  border-radius: 0.5em;
}
.board_area.view .view_file a:before {
  position: absolute;
  content: "";
  top: calc(50% - 0.4em);
  left: 1em;
  width: 0.8em;
  height: 0.8em;
  background: url(/src/asset/img/sub/ico_file.png) no-repeat center;
  background-size: cover;
}

.board_area.view.product ul li .product_detail .img_area {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1em;
  width: 23em;
}
.board_area.view.product ul li .product_detail .text_area {
  width: calc(100% - 24em);
}

.board_area.view.product ul li .product_detail .text_area ul li {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  margin-bottom: 0.5em;
  border: none;
}
.board_area.view.product ul li .product_detail .text_area ul li:last-child {
  margin-bottom: 0;
}

.board_area.view.product ul li .product_detail .text_area ul li span {
  position: relative;
  width: 9em;
  font-size: 0.8em;
  font-weight: 600;
}
.board_area.view.product ul li .product_detail .text_area ul li span:before {
  position: relative;
  content: "-";
  margin-right: 0.5em;
}
.board_area.view.product ul li .product_detail .text_area ul li .text_list {
  width: calc(100% - 9em);
}
.board_area.view.product ul li .product_detail .text_area ul li .text_list p {
  font-size: 0.8em;
}
.board_area.view.product
  ul
  li
  .product_detail
  .text_area
  ul
  li
  .text_list
  table {
  width: 100%;
  border: 1px solid #dcdcdc;
}
.board_area.view.product
  ul
  li
  .product_detail
  .text_area
  ul
  li
  .text_list
  table
  th,
.board_area.view.product
  ul
  li
  .product_detail
  .text_area
  ul
  li
  .text_list
  table
  td {
  padding: 0.5em;
  font-size: 0.8em;
  border-right: 1px solid #dcdcdc;
  border-bottom: 1px solid #dcdcdc;
  text-align: center;
}
.board_area.view.product
  ul
  li
  .product_detail
  .text_area
  ul
  li
  .text_list
  table
  th.left,
.board_area.view.product
  ul
  li
  .product_detail
  .text_area
  ul
  li
  .text_list
  table
  td.left {
  text-align: left;
}
.board_area.view.product
  ul
  li
  .product_detail
  .text_area
  ul
  li
  .text_list
  table
  th {
  background-color: #f5f5f5;
}

.board_area.view li.td.answer .answer_title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.1em;
  font-weight: 600;
}
.board_area.view li.td.answer .answer_title img {
  margin-right: 0.5em;
}
.board_area.view li.td.answer .answer_area {
  margin-top: 0.5em;
  padding: 1em;
  background-color: #f5f5f5;
}

.board_area .btn_area {
  margin-top: 1.5em;
}
.board_area .btn_area.top {
  justify-content: flex-end;
  margin-top: 0;
  margin-bottom: 0.5em;
}
.board_area .btn_area .btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 1em;
  padding: 0.5em 3em;
  font-size: 0.9rem;
  font-weight: 500;
  text-align: center;
  color: #fff;
  background-color: #646464;
  border: 1px solid #646464;
  border-radius: 0.5em;
  transition: all ease-in-out 0.3s;
}
.board_area .btn_area .btn:first-child {
  margin: 0;
}
.board_area .btn_area .btn:hover {
  color: #fff;
  background-color: #24cfe6;
  border-color: #24cfe6;
}
.board_area .btn_area .btn.delete:hover {
  background-color: #d6793c;
  border-color: #d6793c;
}
.board_area .btn_area .btn.edit:hover {
  background-color: #5f9442;
  border-color: #5f9442;
}
.board_area .btn_area .btn.file:hover,
.board_area .btn_area .btn.answer:hover {
  background-color: #1c92eb;
  border-color: #1c92eb;
}
.board_area .btn_area .btn.file {
  position: absolute;
  top: 0;
  left: 0;
}
.board_area .btn_area .btn.list {
  background-color: #8c7eb8;
}

.board_area .btn_area .btn.apply {
  color: #fff;
  background-color: #051e48;
}

.participate_area.board {
  margin: 0 auto;
  padding: 0 0.5em;
  width: 100%;
  max-width: 1200px;
}

/* login */
.login_area {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  background-color: #fff;
}

.login_area form {
  width: 100%;
}

.login_area .login_box {
  margin: 0 auto;
  padding: 0 0.5em;
  width: 100%;
  max-width: 40em;
}

.login_area .login_box h1 {
  font-size: 1em;
}
.login_area .login_box .logo {
  margin-bottom: 2.2em;
}

.login_area .login_box .logo img {
  display: block;
}
.login_area .login_box .logo img.ls {
  margin: 0 auto 2em;
  width: 10.4em;
}
.login_area .login_box .logo img.lt {
  margin: 0 auto;
  width: 34%;
  max-width: 490px;
}

.login_area .login_box .input_area {
  position: relative;
  margin: 0 auto;
}

.login_area .login_box .input_area input[type="text"],
.login_area .login_box .input_area input[type="password"] {
  display: block;
  margin-bottom: 1em;
  padding: 1em;
  width: 100%;
  font-size: 1em;
  border: 0.1em solid #eaeaea;
}
.login_area .login_box .input_area input[type="text"]:focus,
.login_area .login_box .input_area input[type="password"]:focus {
  border-color: #1c92eb;
}

.login_area .login_box .input_area .pwToggle {
  position: absolute;
  top: calc(50% - 23px);
  right: 1.1em;
  width: 60px;
  height: 46px;
  /* background: url("../img/login/btn_show_default.jpg") no-repeat; */
  background-size: 100%;
}

.login_area .login_box .input_area .pwToggle.view {
  /* background: url("../img/login/btn_show_pressed.png") no-repeat; */
  background-size: 100%;
}

.login_area .login_box .input_area label.cbx {
  display: block;
  margin-bottom: 2em;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
}

.login_area .login_box .input_area label.cbx span {
  display: inline-block;
  vertical-align: middle;
  transform: translate3d(0, 0, 0);
}
.login_area .login_box .input_area label.cbx span:first-child {
  position: relative;
  width: 2em;
  height: 2em;
  border-radius: 0.15em;
  transform: scale(1);
  vertical-align: middle;
  border: 1px solid #c0c0c0;
  transition: all 0.2s ease;
}
.login_area .login_box .input_area label.cbx span:first-child svg {
  position: absolute;
  top: 0;
  left: 0;
  fill: none;
  width: 100%;
  height: 100%;
  stroke: #ffffff;
  stroke-width: 4;
  stroke-linecap: round;
  stroke-linejoin: round;
  transition: all 0.3s ease;
  transition-delay: 0.1s;
  transform: translate3d(0, 0, 0);
}
.login_area .login_box .input_area label.cbx span:first-child:before {
  content: "";
  width: 100%;
  height: 100%;
  background: #1c92eb;
  display: block;
  transform: scale(0);
  opacity: 1;
  border-radius: 50%;
}
.login_area .login_box .input_area label.cbx span:last-child {
  padding-left: 0.5em;
}
.login_area .login_box .input_area label.cbx:hover span:first-child {
  border-color: #1c92eb;
}

.login_area .login_box .input_area .inp-cbx {
  display: none;
}

.login_area .login_box .input_area .inp-cbx:checked + .cbx span:first-child {
  border-color: #1c92eb;
  animation: wave 0.4s ease;
}
.login_area
  .login_box
  .input_area
  .inp-cbx:checked
  + .cbx
  span:first-child
  svg {
  stroke-dashoffset: 0;
  stroke: #1c92eb;
}
.login_area
  .login_box
  .input_area
  .inp-cbx:checked
  + .cbx
  span:first-child:before {
  transform: scale(3.5);
  opacity: 0;
  transition: all 0.6s ease;
}

@keyframes wave {
  50% {
    transform: scale(0.9);
  }
}

.login_area .login_box .link_area {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2.5em auto 0;
  width: 68%;
  max-width: 980px;
}

.login_area .login_box .link_area a {
  display: block;
  width: 33.3%;
  color: #787878;
  font-size: 0.8em;
  text-align: center;
  box-sizing: border-box;
  border-right: 0.1em solid #e6e6e6;
}

.login_area .login_box .link_area a:last-child {
  margin-right: 0;
  border-right: none;
}

.login_area .input_area.btn_area button {
  display: block;
  padding: 1.5em;
  width: 100%;
  font-size: 1.2em;
  text-align: center;
  border: none;
  border-radius: 0;
  transition: all ease-in-out 0.3s;
}
.login_area .input_area.btn_area button:hover {
  color: #fff;
  border-radius: 0 1em 0 1em;
  background-color: #1c92eb;
}

.login_area .copyright {
  position: absolute;
  width: 100%;
  bottom: 1em;
  text-align: center;
}
.login_area .copyright p {
  display: block;
  margin: 0 auto;
  color: #232323;
  font-size: 0.825em;
}

@media screen and (max-width: 1260px) {
  .wrap.exhibition .ew_list ul li {
    width: calc((100% - 2em) / 3);
  }
  .wrap.exhibition .ew_list ul li:nth-child(4n) {
    margin-right: 1em;
  }
  .wrap.exhibition .ew_list ul li:nth-child(3n) {
    margin-right: 0;
  }
  .wrap.exhibition .booth_list > div ul li {
    width: calc((100% - 3em) / 4);
  }
  .wrap.exhibition .booth_list > div ul li:nth-child(3n) {
    margin-right: 1em;
  }
  .wrap.exhibition .booth_list > div ul li:nth-child(5n) {
    margin-right: 1em;
  }
  .wrap.exhibition .booth_list > div ul li:nth-child(4n) {
    margin-right: 0;
  }
  .wrap.exhibition .booth_list > div.bl03 ul li:nth-child(3n) {
    margin-right: 0em;
  }
  .wrap.exhibition .booth_list > div.bl03 ul li:nth-child(4n) {
    margin-right: 1em;
  }
  .wrap.exhibition .booth_list > div.bl05 ul li {
    width: calc((100% - 3em) / 4);
  }

  .last_event .event .le_cont {
    justify-content: center;
  }
  .last_event .event .le_cont .text_area {
    margin-right: 0;
  }
  .last_event .event .le_cont > img {
    width: 60%;
    margin: 0 0 3em;
  }
  .sub_wrap.additional .ul_list li .text_area {
    margin-right: 0;
    width: 100%;
  }
  .sub_wrap.additional .ul_list li .video_area {
    padding-top: 50%;
    width: 100%;
  }

  .mapList_area .tabs_area .map_tabs {
    margin: 0 auto 3em;
  }
  .mapList_area .tabs_area .tab-container {
    margin: 0 auto;
    padding: 1.35em 1em;
    width: 80%;
    border: 1px solid #969696;
  }
  .mapList_area .tabs_area .tab-container .center_list li * {
    text-align: center;
  }
  .sub_wrap.additional .tc_cont ul.tc_list > li .text ul.panel li {
    margin: 0 0 1em 0;
    width: 100%;
  }
}

@media screen and (max-width: 1024px) {
  body,
  th,
  td,
  input,
  select,
  textarea,
  button {
    font-size: 14px;
  }
  .about_area .about_mid .flowchart .fc01 .fc_cont > ul > li,
  .about_area .about_mid .flowchart .fc01 .fc_cont ul li.box02 {
    margin: 0 0 1em;
    width: 100%;
  }
  .about_area .about_mid .flowchart .fc01 .fc_cont > ul > li:last-child {
    margin-bottom: 0;
  }
  .about_area .about_mid .flowchart .fc01 .fc_cont > ul > li.box01 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  .about_area .about_mid .flowchart .fc01 .fc_cont ul li.box01 > div {
    display: block;
    border-right: 1px solid #dcdcdc;
    border-bottom: none;
    width: calc(100% / 3);
  }
  .about_area .about_mid .flowchart .fc01 .fc_cont ul li.box01 > div * {
    margin: 0 auto;
  }
  .about_area .about_mid .flowchart .fc01 .fc_cont ul li.box01 > div p {
    margin: 1em 0 0;
    text-align: center;
  }
  .about_area .about_mid .flowchart .fc01 .fc_cont ul li.box02 > div {
    padding: 1em 0;
  }

  .participation_box.announce .box_area > ul > li {
    margin: 0 0 1em;
    width: 100%;
  }

  .participate_area ul.input_list li .input_area .gender {
    width: 7.5em;
  }
  .participate_area ul.input_list li .input_area .phone {
    margin-left: 1em;
    width: 7.5em;
  }

  .board_area.view.product ul li .product_detail .text_area ul li {
    display: block;
  }
  .board_area.view.product ul li .product_detail .text_area ul li span,
  .board_area.view.product ul li .product_detail .text_area ul li .text_list {
    width: 100%;
  }
  .board_area.view.product ul li .product_detail .text_area ul li span {
    display: block;
    margin: 0 0 0.25em;
  }
  .board_area.view.product ul li .product_detail .text_area ul li .text_list {
    padding-left: 0.75em;
  }

  .wrap.exhibition .ew_list ul li {
    width: calc((100% - 1em) / 2);
  }
  .wrap.exhibition .ew_list ul li:nth-child(3n) {
    margin-right: 1em;
  }
  .wrap.exhibition .ew_list ul li:nth-child(2n) {
    margin-right: 0;
  }
  .wrap.exhibition .booth_list > div ul li:nth-child(2n) {
    margin-right: 1em;
  }
  .wrap.exhibition .booth_list > div ul li:nth-child(4n) {
    margin-right: 0;
  }
  .sub_wrap.introduce .intro_top,
  .sub_wrap.introduce .intro_section {
    padding: 2em 0;
  }
}

@media screen and (max-width: 768px) {
  body,
  th,
  td,
  input,
  select,
  textarea,
  button {
    font-size: 12px;
  }

  .subVisual {
    align-items: center;
    height: auto;
  }

  .subVisual .img_area {
    position: relative;
    top: auto;
    left: auto;
    justify-content: center;
    padding-top: 3em;
    height: auto;
    transform: none;
  }
  .subVisual .img_area img.img {
    display: none;
  }

  .subVisual > .text_area {
    align-items: center;
    height: auto;
    padding: 1.5em 0.75em 3em;
  }
  .subVisual > .text_area p {
    text-align: center;
  }

  .guide_area ul li {
    margin-bottom: 1em;
    width: 80%;
  }
  .guide_area ul li.arrow {
    display: none;
  }
  .guide_area ul li span.gl_tit {
    width: 100%;
  }
  .guide_area ul li .gl_cont span {
    font-size: 1.2em;
  }
  .guide_area ul li .gl_cont p {
    font-size: 1em;
  }
  .guide_area ul li a {
    font-size: 1.2em;
  }

  .participation_box .terms_area ul li {
    margin: 0 0 1em;
    width: 100%;
  }
  .participation_box .terms_area ul li:last-child {
    margin-bottom: 0;
  }

  .participate_area .part_title p.caution {
    position: relative;
    right: auto;
    bottom: auto;
  }
  .participate_area ul.input_list li > span {
    margin: 0 0 1em;
    width: 100%;
  }
  .participate_area ul.input_list li > span br {
    display: none;
  }
  .participate_area ul.input_list li .input_area {
    width: 100%;
  }
  .participate_area ul.input_list li .input_area.type .type_list {
    margin: 0 1em 1em 0;
    width: calc((100% - 1em) / 2);
  }
  .participate_area ul.input_list li .input_area.type .type_list:nth-child(2n) {
    margin-right: 0;
  }
  .participate_area
    ul.input_list
    li
    .input_area.type
    .type_list:nth-last-child(2n),
  .participate_area ul.input_list li .input_area.type .type_list:last-child {
    margin-bottom: 0;
  }
  .participate_area ul.input_list li .input_area .phone {
    width: 10em;
  }
  .board_area.view.product ul li .product_detail .img_area {
    margin: 0 0 1em;
    width: 100%;
  }
  .board_area.view.product ul li .product_detail .img_area img {
    width: 100%;
  }
  .board_area.view.product ul li .product_detail .text_area {
    width: 100%;
  }

  .subVisual .univ_info {
    width: 90%;
  }

  .subVisual > .text_area span {
    text-align: center;
  }

  .subVisual > .text_area .pNav {
    justify-content: center;
  }

  .sub_nav > ul {
    padding: 0;
  }

  .sub_wrap.introduce.univ
    .intro_content.univ
    .intro_section
    > ul
    li
    .text_area
    span
    br {
    display: none;
  }
  .sub_wrap.introduce.univ .intro_content.univ .intro_section .is_list > img {
    margin: 0 auto 3em;
  }
  .sub_wrap.introduce.univ
    .intro_content.univ
    .intro_section
    .is_list
    .text_area {
    width: 100%;
  }
  .sub_wrap.introduce.univ
    .intro_content.univ
    .intro_section
    .is_list
    .text_area
    span {
    font-size: 1.625em;
  }
  .sub_wrap.introduce.univ
    .intro_content.univ
    .intro_section
    .is_list
    .text_area
    span.small {
    font-size: 1.3em;
  }
  .sub_wrap.introduce.univ
    .intro_content.univ
    .intro_section
    .is_list
    .text_area
    p {
    font-size: 1.2em;
  }

  .sub_wrap.additional .ul_list li .text_area .ul_title {
    font-size: 1.75em;
  }
  .sub_wrap.additional .ul_list li .text_area .info p {
    font-size: 1.2em;
  }

  .mapList_area .tabs_area .tab-container {
    width: 100%;
  }

  .sub_wrap.introduce .intro_section .table_area {
    overflow-x: auto;
  }
  .sub_wrap.introduce .intro_section .table {
    width: 64em;
  }

  .wrap.exhibition .ew_list .guide_area ul,
  .wrap.exhibition .ew_list .guide_area .counting_area {
    width: 100%;
  }
  .wrap.exhibition .ew_list .guide_area ul {
    margin-bottom: 1em;
  }
  .tbl_top .sorting_area > p {
    display: none;
  }
  .wrap.exhibition .excellentWork .ex_section span.guide_vote {
    top: 4em;
    left: 0;
    letter-spacing: -0.03em;
  }

  .sub_wrap.introduce .intro_content.univ.scd > ul li .title_area,
  .sub_wrap.introduce .intro_content.univ.scd > ul li .text_area {
    width: 100%;
  }
  .sub_wrap.introduce .intro_content.univ.scd > ul li .title_area {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 0.5em 0.5em 0 0;
  }
  .sub_wrap.introduce .intro_content.univ.scd > ul li .title_area img {
    margin: 0 1em 0 0;
  }
  .sub_wrap.introduce .intro_content.univ.scd > ul li .title_area span {
    width: auto;
    text-align: left;
  }
  .sub_wrap.introduce .intro_content.univ.scd > ul li .text_area {
    border-radius: 0 0 0.5em 0.5em;
  }
  .watch_map .map_info .logo {
    display: none;
  }
  .watch_map .map_info .info {
    width: 100%;
  }
  .watch_map .map_info .logo img {
    margin: 0 auto 1em;
  }
  .wrap.exhibition .booth_list > div ul li {
    width: calc((100% - 2em) / 3);
  }
  .wrap.exhibition .booth_list > div.bl05 ul li {
    width: calc((100% - 2em) / 3);
  }
  .wrap.exhibition .booth_list > div ul li:nth-child(2n) {
    margin-right: 1em;
  }
  .wrap.exhibition .booth_list > div ul li:nth-child(4n) {
    margin-right: 1em;
  }
  .wrap.exhibition .booth_list > div ul li:nth-child(3n) {
    margin-right: 0;
  }

  .reg_area .table_area {
    overflow-x: auto;
  }
  .reg_area .table_area .table {
    width: 64em;
  }
}

@media screen and (max-width: 575px) {
  .tbl_top .sorting_area:first-child {
    margin-bottom: 1em;
  }
  .sub_nav > ul > li {
    width: calc((100% - 4em) / 2);
  }
  .sub_nav.depth5 > ul {
    height: 5em;
  }
  .sub_wrap.login div.infoArea p {
    font-size: 0.9rem;
  }
  .sub_wrap.login .login_form .login_inp.phone {
    margin-right: 0;
    width: calc(100%);
    margin-top: 1em;
  }
  .sub_wrap.login .login_form button.phone {
    margin-top: 0;
    padding: 0.5em 1em;
    width: 100%;
  }
  .sub_wrap.login .login_form button.termsPopup1,
  .sub_wrap.login .login_form button.termsPopup2 {
    margin-top: 0.5em;
    padding: 0.25em 1em;
    width: 100%;

    background-color: #969696;
  }
  .sub_wrap.login div.infoArea p:first-child {
    margin-bottom: 1em;
  }
  .sub_wrap.login div.infoArea small {
    display: block;
    margin-left: 0.5rem;
  }
  .about_area .about_top ul li {
    margin: 0 0 1em;
    padding: 2em;
    width: 100%;
    border: 1px solid;
  }
  .about_area .about_top ul li:first-child {
    border-color: rgba(28, 146, 235, 0.8);
  }
  .about_area .about_top ul li:nth-child(2) {
    margin: 0 0 1em;
    border-color: rgba(28, 168, 209, 0.8);
  }
  .about_area .about_top ul li:last-child {
    margin: 0;
    border-color: rgba(28, 185, 188, 0.8);
  }
  .about_area .about_top ul li p {
    font-size: 1em;
  }
  .about_area .about_top ul li p br {
    display: none;
  }

  .about_area .about_mid .flowchart .fc_title {
    margin: 0 0 1em;
    padding: 0.75em;
    width: 100%;
  }
  .about_area .about_mid .flowchart .fc_title br {
    display: none;
  }
  .about_area .about_mid .flowchart .fc_cont {
    width: 100%;
  }
  .about_area .about_mid .flowchart .fc01 .fc_cont ul li.box01 > div p,
  .about_area .about_mid .flowchart .fc01 .fc_cont ul li.box02 > div p {
    font-size: 1em;
  }
  .about_area .about_mid .flowchart .fc01 .fc_cont ul li.box02 > div p {
    margin-top: 1em;
  }
  .about_area .about_mid .flowchart .fc01 .fc_cont ul li.box03 .box_cont span {
    font-size: 1em;
  }
  .about_area .about_mid .flowchart .fc02 .fc_cont > ul > li {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    margin: 0 0 1em;
    width: 100%;
  }
  .about_area .about_mid .flowchart .fc02 .fc_cont > ul > li > div,
  .about_area .about_mid .flowchart .fc02 .fc_cont > ul > li > span {
    width: 100%;
  }
  .about_area .about_mid .flowchart .fc02 .fc_cont > ul > li > span {
    border-radius: 0 1em 0 0;
  }
  .about_area .about_mid .flowchart .fc02 .fc_cont > ul > li .fc_list {
    margin: 0;
    border-radius: 0 0 0 1em;
  }

  .last_event .event_list > ul li {
    margin: 0 0 2em;
    width: 100%;
  }
  .last_event .event_list > ul li img {
    margin: 0 auto 1em;
  }

  .search_area .input_area {
    justify-content: flex-start;
    align-items: flex-end;
  }
  .search_area .input_area label,
  .search_area .input_area .period {
    display: block;
    margin-right: 1em;
    width: calc((100% - 1em) / 2);
  }
  .search_area .input_area .period label.calendar {
    display: flex;
    margin: 0 0.5em 0 0;
    width: 47.5%;
  }
  .search_area .input_area label.calendar:last-child {
    margin-right: 0;
  }
  .search_area .input_area label:nth-child(2n),
  .search_area .input_area .period {
    margin-right: 0;
  }
  .search_area .input_area label {
    margin: 0 0 1em;
    width: 100%;
  }
  .search_area .input_area .period {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  .search_area .input_area .period span:first-child {
    margin: 0 0 0.5em;
    width: 100%;
    font-size: 1.1em;
    line-height: 1.5em;
  }
  .search_area .input_area label:nth-child(1),
  .search_area .input_area label:nth-child(2) {
    margin-bottom: 1em;
  }
  .search_area .input_area label span {
    margin: 0 0 0.5em;
    font-size: 1.1em;
  }
  .search_area .input_area .period input {
    width: 100%;
  }
  .search_area .input_area input {
    margin-right: 0;
    width: calc(70% - 1em);
    height: 2.5em;
  }
  .search_area .input_area select {
    margin-right: 1em;
    width: 30%;
    display: block;
    height: 2.5em;
  }
  .search_area .input_area label.calendar input {
    height: 2.5em;
  }

  .search_area .input_area button {
    margin-top: 0.5em;
    width: 100%;
    height: 2.5em;
  }
  .search_area.booth {
    margin-bottom: 2em;
    flex-direction: column-reverse;
  }
  .search_area.booth .boothSortWrap {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 2em;
  }
  .search_area.booth .boothSortWrap .boothSort {
    margin-right: 1em;
  }

  .search_area.booth .input_area button {
    margin: 0 0 0 1em;
    width: 30%;
    font-size: 0.9rem;
  }
  .search_area .input_area > div {
    width: 100%;
  }

  .search_area .input_area input.text_inp {
    width: 100%;
  }
  .search_area.board .input_area input.text_inp {
    width: 100%;
  }
  .search_area .input_area .s2 label:nth-child(2) {
    margin-right: 0.8em;
    width: calc(100% - 4em);
  }

  .board_area.product ul li a .img_area {
    margin: 0 0 1em;
    width: 100%;
  }
  .board_area.product ul li a .img_area img {
    width: 100%;
  }
  .board_area.product ul li a .text_area {
    width: 100%;
  }

  .introduce_area .img_area {
    margin: 0 auto 2em;
    width: 100%;
    max-width: 32em;
  }
  .introduce_area .text {
    margin: 0;
    width: 100%;
  }
  .introduce_area .text span,
  .introduce_area .text p {
    text-align: center;
  }
  .introduce_area .text a.btn {
    margin: 2em auto 0;
  }

  .last_event .event .le_cont > img {
    width: 100%;
  }

  .sub_wrap.introduce .intro_section .table td {
    /* font-size: 0.8em; */
    font-size: 0.8rem;
    border-right: 1px solid #fff;
    border-bottom: 1px solid #fff;
  }

  .participate_area ul.input_list li .input_area .btn_area {
    width: 100%;
  }
  .participate_area ul.input_list li .input_area .btn_part {
    margin: 1em 0 0 !important;
    width: 100%;
  }
  .participate_area ul.input_list li .input_area .gender {
    margin-bottom: 1em;
    width: 100%;
  }
  .about_area .about_list > ul > li {
    display: block;
  }
  .about_area .about_list > ul > li .al_title {
    padding: 1em;
    width: 100%;
  }
  .about_area .about_list > ul > li .al_content {
    width: 100%;
  }

  .wrap.exhibition .ew_list ul {
    padding: 0;
  }
  .wrap.exhibition .ew_list ul li {
    margin-right: 0;
    width: 100%;
  }
  .wrap.exhibition .ew_list ul li:nth-child(3n) {
    margin-right: 0;
  }
  .wrap.exhibition .booth_list > div ul li {
    margin-right: 1em;
    width: calc((100% - 2em) / 3);
  }
  .wrap.exhibition .booth_list > div.bl03 ul li {
    width: calc((100% - 1em));
  }
  .wrap.exhibition .booth_list > div.bl05 ul li {
    width: calc((100% - 1em));
  }
  .wrap.exhibition .booth_list > div.bl03 ul li:nth-child(2n) {
    margin-right: 0;
  }
  .wrap.exhibition .booth_list > div.bl05 ul li:nth-child(2n) {
    margin-right: 0;
  }
  /* .wrap.exhibition .booth_list > div.bl03 ul li:nth-child(3n) {
    margin-right: 1em;
  } */
  /* .wrap.exhibition .booth_list > div.bl03 ul li:nth-child(5n) {
    margin-right: 1em;
  } */
  .sub_wrap.introduce.univ .intro_top ul {
    margin: 2em auto 0;
    width: 80%;
  }
  .sub_wrap.introduce.univ .intro_top ul::after {
    top: 1em;
    left: calc(50% - 1em);
    width: 2em;
    height: calc(100% - 2em);
  }
  .sub_wrap.introduce.univ .intro_top ul li {
    margin: 0 auto 2em;
    width: 80%;
  }
  .sub_wrap.introduce.univ .intro_top ul li img {
    margin: auto;
    width: 100%;
    max-width: 20em;
  }

  .sub_wrap.introduce.univ .intro_content.univ .intro_section > ul {
    margin: 0 auto;
    width: 80%;
  }

  .sub_wrap.introduce.univ .intro_content.univ .intro_section > ul li {
    margin: 0 0 2em;
    width: 100%;
  }

  .sub_wrap.additional .tc_cont .about_tc .at_cont > img,
  .sub_wrap.additional .tc_cont ul.tc_list > li .at_cont > img {
    margin: 0 0 2em;
  }
  .sub_wrap.additional .tc_cont .about_tc .at_cont .text_area,
  .sub_wrap.additional .tc_cont ul.tc_list > li .at_cont .text_area {
    width: 100%;
  }
  .sub_wrap.additional .tc_cont .about_tc .at_cont .text_area span small {
    display: block;
  }
  .sub_wrap.additional .tc_cont ul.tc_list li p {
    line-height: 1.5em;
  }

  .sub_wrap.additional .ul_list li > img {
    margin: 0 auto;
  }

  .sub_wrap.additional .ul_list li .text_area {
    /* margin-left: 2em; */
    width: 100%;
  }
  .sub_wrap.additional .ul_list.lecture li .text_area {
    /* margin-left: 2em; */
    padding: 2em 1em;
    width: 100%;
  }

  .sub_wrap.additional .ul_list li .text_area * {
    display: table;
    margin: 0 auto;
    text-align: center;
  }

  .mapList_area .tabs_area .map_tabs {
    font-size: 10px;
  }
  .mapList_area .tabs_area .tab-container .text_tabs {
    display: none;
  }
  .graph_area ul li {
    margin: 0 0 1em;
    width: 100%;
  }
  .graph_area ul li:last-child {
    margin: 0;
  }
  .wrap.exhibition .ew_list .guide_area.watch ul,
  .wrap.exhibition .ew_list .btn_reg {
    width: 100%;
  }
  .wrap.exhibition .ew_list .btn_reg {
    margin-top: 1em;
  }
  .sub_wrap.additional .ul_list li .text_area .info p:first-child {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 0.5em;
  }
  .sub_wrap.additional .ul_list li .text_area .info p b {
    margin: 0;
    display: block;
  }
  .sub_wrap.additional .ul_list li .text_area .info p {
    display: block;
    margin: 0;
    text-align: left;
  }
  .sub_wrap.login .login_form .login_inp::placeholder {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 425px) {
  .sub_wrap {
    padding: 2em 1em 2em;
  }

  .sub_wrap.introduce .intro_section span.slogan {
    line-height: 1.5em;
  }

  .search_area .input_area .s2 label:nth-child(2) {
    width: calc(100% - 4.5em);
  }

  .board_area .btn_area .btn {
    margin-left: 1em;
    width: calc((100% - 2em) / 3);
  }
  .board_area .btn_area .btn:first-child {
    margin-left: 0;
  }

  .board_area.product ul li a .text_area ul li {
    display: block;
  }
  .board_area.product ul li a .text_area ul li span,
  .board_area.product ul li a .text_area ul li .text_list {
    width: 100%;
  }
  .board_area.product ul li a .text_area ul li .text_list {
    margin-top: 0.5em;
    padding-left: 0.9em;
  }

  .board_area .btn_area .btn.file {
    position: relative;
  }

  .participate_area ul.input_list li .input_area input.email {
    width: 16em;
  }
  .participate_area ul.input_list li .input_area input.w80,
  .participate_area ul.input_list li .input_area textarea.w80,
  .participate_area ul.input_list li .input_area input.w70 {
    width: 100% !important;
  }
  .participate_area ul.input_list li .input_area input[type="checkbox"] {
    margin-left: 0;
  }
  .participate_area.result .reception_area span {
    width: 60%;
  }

  .subVisual .univ_info {
    width: 90%;
  }

  .wrap.exhibition .excellentWork {
    padding: 2em;
  }

  .wrap.exhibition .excellentWork .ex_section .es_title {
    padding: 1.5em 0 0.15em 2.75em;
    background-size: cover;
  }

  .wrap.exhibition .excellentWork .ex_section .slider_area .slider_arrow {
    transform: translateY(-90%);
  }
  .wrap.exhibition .excellentWork .ex_section .slider_area .slider_arrow nav a {
    width: 4em;
  }

  .mapList_area .tabs_area .map_tabs {
    font-size: 8px;
  }

  .sub_wrap.login .login_form .login_inp.email1,
  .sub_wrap.login .login_form .login_inp.email2 {
    width: calc((100% - 1.75em) / 2);
  }
  .sub_wrap.login .login_form .login_inp.email2 {
    margin-right: 0;
  }
  .sub_wrap.login .login_form .login_slt.email {
    margin-top: 0.5em;
    width: 100%;
  }
  .wrap.exhibition .booth_list > div ul li {
    width: calc((100% - 1em) / 2);
  }
  .wrap.exhibition .booth_list > div ul li:nth-child(3n) {
    margin-right: 1em;
  }
  .wrap.exhibition .booth_list > div ul li:nth-child(2n) {
    margin-right: 0;
  }
}
