.pf_wrap.mobile {
  width: 100%;
}
.pf_wrap.mobile .pf_body {
  width: 100%;
}
.pf_wrap.mobile .pf_body .pf_image {
  left: 0;
  right: unset;
}

.pf_image_mobile {
  position: absolute !important;
  z-index: 90;
}
.pf_image_mobile.current {
  z-index: 92;
}
.pf_image_mobile.current.back {
  z-index: 93;
}
