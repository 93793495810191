.pf_wrap {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.pf_wrap .pf_body {
  position: absolute;
  width: 800px;
  /* height: 200px; */
  z-index: 99;
}
.pf_wrap .pf_body .pf_image {
  position: absolute !important;
  width: 400px;
  /* height: 200px; */
  right: 0;
  z-index: 99;
}
.pf_wrap .pf_body .pf_image.back {
  z-index: 90;
}
.pf_wrap .pf_body .pf_image.ready {
  z-index: 97;
}
.pf_wrap .pf_body .pf_image.left {
  left: 0;
  z-index: 98;
  right: unset;
}
.pf_wrap .pf_body .pf_image.right {
  z-index: 98;
  right: 0;
  left: unset;
}
.pf_wrap .pf_body .pf_image.right.ready {
  z-index: 97;
}
/* .backgroundImg {
  height: 100%;
  width: 400px;
}

.imgCrop1 {
  position: absolute;
  clip: rect(0, 50px, 200px, 0);
}
.imgCrop2 {
  position: absolute;
  z-index: 11;
  left: -50px;
  clip: rect(0, 100px, 200px, 50px);
}
.imgCrop3 {
  position: absolute;
  z-index: 12;
  left: -100px;
  clip: rect(0, 150px, 200px, 100px);
}
.imgCrop4 {
  position: absolute;
  z-index: 13;
  left: -150px;
  clip: rect(0, 200px, 200px, 150px);
}
.imgCrop5 {
  position: absolute;
  z-index: 14;
  left: -200px;
  clip: rect(0, 250px, 200px, 200px);
}
.imgCrop6 {
  position: absolute;
  z-index: 15;
  left: -250px;
  clip: rect(0, 300px, 200px, 250px);
}
.imgCrop7 {
  position: absolute;
  z-index: 16;
  left: -300px;
  clip: rect(0, 350px, 200px, 300px);
}
.imgCrop8 {
  position: absolute;
  z-index: 17;
  left: -350px;
  clip: rect(0, 400px, 200px, 350px);
}

.imgCrop1.change {
  z-index: 17;
  transform: rotateY(-180deg);
  clip: rect(0, 400px, 200px, 350px);
}
.imgCrop2.change {
  left: -50px;
  z-index: 16;
  transform: rotateY(-180deg);
  clip: rect(0, 350px, 200px, 300px);
}
.imgCrop3.change {
  left: -100px;
  z-index: 15;
  transform: rotateY(-180deg);
  clip: rect(0, 300px, 200px, 250px);
}
.imgCrop4.change {
  left: -150px;
  z-index: 14;
  transform: rotateY(-180deg);
  clip: rect(0, 250px, 200px, 200px);
}
.imgCrop5.change {
  left: -200px;
  z-index: 13;
  transform: rotateY(-180deg);
  clip: rect(0, 200px, 200px, 150px);
}
.imgCrop6.change {
  left: -250px;
  z-index: 12;
  transform: rotateY(-180deg);
  clip: rect(0, 150px, 200px, 100px);
}
.imgCrop7.change {
  left: -300px;
  z-index: 11;
  transform: rotateY(-180deg);
  clip: rect(0, 100px, 200px, 50px);
}
.imgCrop8.change {
  left: -350px;
  transform: rotateY(-180deg);
  clip: rect(0, 50px, 200px, 0);
} */
