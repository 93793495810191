.tempArea {
  width: 800px;
  height: 300px;
  position: absolute;
}
.tempArea2 {
  width: 800px;
  height: 300px;
  z-index: 999;
  visibility: hidden;
  position: absolute;
}
.tempArea2 .active {
  visibility: visible;
}
.tempArea3 {
  width: 800px;
  height: 300px;
  position: absolute;
  z-index: 999;
  visibility: hidden;
}
.tempArea3 .backActive {
  visibility: visible;
}

.tempImage {
  position: absolute;
  width: 400px;
  height: 200px;
  right: 0;
  /* perspective: 4000px;
  perspective-origin: center 50px; */
  transform-origin: left center;
}
.tempImage.act {
  z-index: 97;
}
.tempImage.backSide {
  z-index: 90;
}
.tempImage.r_hide {
  z-index: 91;
  right: 0;
}
.tempImage.l_hide {
  z-index: 91;
  left: 0;
}
.tempImage.first {
  /* left: 0; */
}
.tempImage.second {
  right: 0;
}
.tempImage.prevPage {
  left: 0;
  z-index: 92;
}
.tempImage.nextPage {
  z-index: 92;
}

.tempImage.leftMove.next {
  animation: leftMoveBack 0.2s ease-in forwards;
}
.tempImage.rightMove.prev {
  animation: rightMoveBack 0.2s ease-in alternate-reverse forwards;
}
.tempImage.act.first {
  transform-origin: left center;
  transform: rotateY(-180deg) translateX(-400px);
  /* animation: leftMove 0.2s ease-in forwards; */
}
.tempImage.act.first.next {
  animation: leftMoveFront 0.2s ease-in forwards;
}
.tempImage.act.first.prev {
  left: 0;
  transform: rotateY(0);

  /* animation: leftMoveFront 0.2s ease-in alternate-reverse forwards; */
}
.tempImage.act.first.done {
  left: 0;
  transform: rotateY(0);
}

.tempImage.act.second.prev {
  animation: rightMoveFront 0.2s ease-in alternate-reverse forwards;
}
.tempImage.act.second.done {
  right: 0;
  transform: rotateY(0deg);
}

@keyframes leftMoveBack {
  0% {
    z-index: 100;
    transform: rotateY(0deg);
  }

  100% {
    z-index: 97;
    transform: rotateY(-180deg);
  }
}
@keyframes leftMoveFront {
  0% {
    z-index: 97;
    transform: rotateY(-180deg) translateX(-400px);
  }

  100% {
    z-index: 100;
    transform: rotateY(0) translateX(-400px);
  }
}
@keyframes rightMoveFront {
  0% {
    z-index: 100;
    transform: rotateY(0) translateX(0);
  }
  100% {
    z-index: 97;
    transform: rotateY(-180deg) translateX(0);
  }
}
@keyframes rightMoveBack {
  0% {
    z-index: 97;

    transform: rotateY(-180deg) translateX(-400px);
  }
  100% {
    z-index: 100;
    transform: rotateY(0) translateX(-400px);
  }
}

.flipBody {
  transform-style: preserve-3d;
}
.flips {
  position: absolute;
  width: 52px;
  height: 300px;
  right: 100%;
  perspective: 4200px;
  perspective-origin: center;
  transform-style: preserve-3d;
  background-color: transparent;
  transform-origin: right center;
}
.flips.sec1 {
  background-color: transparent;
  /* transform: rotateX(20deg) rotateZ(10deg); */
}
.flips.sec2 {
  background-color: transparent;
}
.flips.sec3 {
  background-color: transparent;
}
.flips.sec4 {
  background-color: transparent;
}

.flips::after {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  transform-origin: left center;
  /* border-color: black;
  border-width: 2px 2px 2px 0;
  border-style: solid; */
}
.flips.sec1 {
  right: 50%;

  border-color: black;
  border-width: 2px 2px 2px 0;
  border-style: solid;
}
.flips.sec1.active {
  animation: pageFlip 1s ease-in-out;
}
.flips.sec1.active::after {
  animation: nextFlip1 1s -0.8s initial step(1);
}
.flips:not(.sec1) {
  right: calc(100% - 2px);
  top: -2px;
  transform-origin: right;

  border-color: black;
  border-width: 2px 0px 2px 0;
  border-style: solid;
}
.flips.active:not(.sec1) {
  animation: pageFlip2 1s ease-in-out;
}

@keyframes pageFlip {
  0%,
  20% {
    transform: rotateY(0deg) translateY(0);
  }
  80%,
  100% {
    transform: rotateY(180deg) translateY(0);
  }
}
@keyframes pageFlip2 {
  0%,
  20% {
    transform: rotateY(0deg) translateY(0px) rotateZ(1deg) translateX(15px);
  }
  50% {
    transform: rotateY(-20deg) translateY(0px) rotateZ(1deg) translateX(15px);
  }
}
/* @keyframes pageFlip2 {
  0%,
  20% {
    transform: rotateY(0deg) translateY(0px);
  }
  50% {
    transform: rotateY(-15deg) translateY(0px);
  }
} */

@keyframes nextFlip1 {
  0% {
    /* background-position: -178px -2px; */
    transform: rotateY(0deg);
  }
  20% {
    /* background-position: -178px -2px; */
    transform: rotateY(0deg);
  }
  40% {
    /* background-position: -178px -2px; */
    transform: rotateY(0deg);
  }
  60% {
    /* background-position: -178px -2px; */
    transform: rotateY(0deg);
  }
  80% {
    /* background-position: -178px -2px; */
    transform: rotateY(0deg);
  }
  100% {
    /* background-position: -178px -2px; */
    transform: rotateY(0deg);
  }

  /* @for $i from 0 through 4 {
        #{$i * 20}% { background-image: nth($images, ($i + 1)); background-position: -178px -2px; transform: rotateY(0deg); }
        #{10 + ($i * 20)}% { background-image: nth($images, ($i + 2)); background-position: -210px -2px; transform: rotateY(180deg); }
    } */
}

.flipBody2 {
  transform-style: preserve-3d;
}
.flips2 {
  position: absolute;
  width: 52px;
  height: 200px;
  left: 100%;
  perspective: 4200px;
  perspective-origin: center;
  transform-style: preserve-3d;
  /* background-color: rgb(166, 164, 164); */
  background-color: white;
  transform-origin: left center;
}
.flips2.sec1 {
  background-color: white;
  /* background-color: rgb(166, 164, 164); */
  /* transform: rotateX(20deg) rotateZ(10deg); */
}
.flips2.sec2 {
  background-color: white;
  /* background-color: rgb(166, 164, 164); */
}
.flips2.sec3 {
  background-color: white;
  /* background-color: rgb(166, 164, 164); */
}
.flips2.sec4 {
  background-color: white;
  /* background-color: rgb(166, 164, 164); */
}

.flips2::after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  transform-origin: right center;
  /* border-color: black;
  border-width: 2px 2px 2px 0;
  border-style: solid; */
}
.flips2.sec1 {
  left: 50%;

  border-color: black;
  border-width: 2px 0px 2px 2px;
  border-style: solid;
}
.flip2.backActive {
}
.flips2.sec1.backActive {
  animation: pageFlipBack 0.8s ease-in-out;
}
.flips2.sec1.backActive::after {
  animation: nextFlip1Back 0.8s -0.8s initial step(1);
}
.flips2:not(.sec1) {
  left: calc(100% - 2px);
  top: -2px;
  transform-origin: left;

  border-color: black;
  border-width: 2px 0px 2px 0;
  border-style: solid;
}
.flips2.backActive:not(.sec1) {
  animation: pageFlip2Back 0.8s ease-in-out;
}

@keyframes pageFlipBack {
  0%,
  20% {
    opacity: 1;
    transform: rotateY(0deg) translateY(0);
  }
  80%,
  100% {
    opacity: 1;
    transform: rotateY(180deg) translateY(0);
  }
}
@keyframes pageFlip2Back {
  0%,
  20% {
    opacity: 1;
    transform: rotateY(0deg) translateY(0px) rotateZ(-1deg) translateX(-4px);
  }
  50% {
    opacity: 1;
    transform: rotateY(-20deg) translateY(0px) rotateZ(-1deg) translateX(-4px);
  }
}
@keyframes nextFlip1Back {
  0% {
    opacity: 1;
    /* background-position: -178px -2px; */
    transform: rotateY(0deg);
  }
  20% {
    opacity: 1;
    /* background-position: -178px -2px; */
    transform: rotateY(0deg);
  }
  40% {
    opacity: 1;
    /* background-position: -178px -2px; */
    transform: rotateY(0deg);
  }
  60% {
    opacity: 1;
    /* background-position: -178px -2px; */
    transform: rotateY(0deg);
  }
  80% {
    opacity: 1;
    /* background-position: -178px -2px; */
    transform: rotateY(0deg);
  }
  100% {
    opacity: 1;
    /* background-position: -178px -2px; */
    transform: rotateY(0deg);
  }

  /* @for $i from 0 through 4 {
        #{$i * 20}% { background-image: nth($images, ($i + 1)); background-position: -178px -2px; transform: rotateY(0deg); }
        #{10 + ($i * 20)}% { background-image: nth($images, ($i + 2)); background-position: -210px -2px; transform: rotateY(180deg); }
    } */
}
