.pdfDocumentWrap {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;

  > .pdfDocumentBody {
    position: absolute;
    width: 800px;
    display: flex;
    justify-content: center;

    .pdfPage {
      position: absolute !important;
      width: 800px;

      &.active {
        visibility: visible;
      }

      &.loading {
        visibility: hidden !important;
      }

      &.page {
        z-index: 98;

        &.current {
          z-index: 99;
          &.back {
            z-index: 100;
          }
        }
      }

      &.cover {
        z-index: 98;

        // &.current {
        //   z-index: 99;
        // }
        // &.prev {
        //   z-index: 98;
        // }
        // &.prev.current {
        //   z-index: 98;
        // }
      }
      &.first {
        z-index: 98;
      }
      &.second {
        z-index: 98;
        &.current {
          z-index: 99;
          &.delay {
            z-index: 99;
          }
        }
      }
      &.third {
        z-index: 98;
        &.current {
          z-index: 99;
        }
        &.prev {
          z-index: 99;
        }
        &.prev.current {
          z-index: 99;
        }
      }
    }

    .pageChange {
      position: absolute;
      z-index: 1000;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 40%;
        height: 100%;
      }

      &.left {
        left: -30px;
      }
      &.right {
        right: -30px;
        img {
          transform: rotateY(180deg);
        }
      }

      &.hide {
        visibility: hidden;
      }

      > button {
        height: 60px;
        width: 60px;
        background-color: #070707;
        border-radius: 50%;
        opacity: 0.4;
        border: none;
        &:hover {
          opacity: 1;
        }
        &.right {
          padding: 1em 0.5em 1em 1em;
        }
        &.left {
          padding: 1em 1em 1em 0.5em;
        }
      }
    }
  }
}
